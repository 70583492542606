const state = {


    basic: {
        search: null,
        page: 1,
        posts: [],
        only_co: true,
        total: null,
    },

    advanced: {
        search: null,
        page: 1,
        posts: [],
        only_co: true,
        total: null,
        operator: null,
        must_not: null,
        tags: [],
        tags_operator: "or",
        is_sort: false,
    },
};


const getters = {

    getBasicSearch() {
        return state.basic;
    },

    getAdvancedSearch() {
        return state.advanced;
    },
};


const actions = {

    updateBasicSearch: function({ commit }, obj) {
        commit('setBasicSearch', obj);
    },

    updateAdvancedSearch: function({ commit }, obj) {
        commit('setAdvancedSearch', obj);
    },

    resetBasicSearch: function({ commit }) {
        commit('setResetBasicSearch');
    },

    resetAdvancedSearch: function({ commit }) {
        commit('setResetAdvancedSearch');
    },

};

const mutations = {
    
    
    setBasicSearch: function(state, obj) {
        state.basic = obj;
    },

    setAdvancedSearch: function(state, obj) {
        state.advanced = obj;
    },

    setResetBasicSearch: function(state) {
        state.basic = {
            search: null,
            page: 1,
            posts: [],
            only_co: true,
            total: null,
        }
    },

    setResetAdvancedSearch: function(state) {
        state.advanced = {
            search: null,
            page: 1,
            posts: [],
            only_co: true,
            total: null,
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
