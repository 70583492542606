const state = {
    tags: null,
    tagsByCategory: null,
    search: null,
};


const getters = {
    
    getTags() {
        return state.tags;
    },

    getTagsByCategory() {
        return state.tagsByCategory;
    },

    getTagsSearch() {
        return state.search;
    },
};

const actions = {

    updateTags: function({ commit }, obj) {
        commit('setTags', obj);
    },

    updateTagsByCategory: function({ commit }, obj) {
        commit('setTagsByCategory', obj);
    },

    getTagsInCategory({ state }, cat) {
        return state.tagsByCategory[cat]
    },

    updateTagsSearch({ commit }, search) {
        commit('setTagsSearch', search);
    }
};

const mutations = {

    setTags: function(state, obj) {
        state.tags = obj;
    },

    setTagsByCategory: function(state, obj) {
        state.tagsByCategory = obj;
    },

    setTagsSearch: function(state, search) {
        state.search = search;
    },
};


export default {
    state,
    getters,
    actions,
    mutations,
};