<template>
    <div>

        <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table
            :headers="[
                { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
                { text: 'Nom', value: 'name', align:'center', class:'text-body-2' },
                { text: 'Description', value: 'description', align:'center', class:'text-body-2' },
                { text: 'Sets', value: 'sets_count', align:'center', class:'text-body-2' },
            ]"
            :items="packages"
            :loading="loading"
            @click:row="selectRow"
            :search="search"
            :footer-props="{
                itemsPerPageText:'packages par page',
                itemsPerPageOptions: [15,30,-1],
            }"
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
            
            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="accent--text">Packages<span v-if="total > 0" class="ml-2">({{total}})</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                    <!-- DESKTOP TABLE SEARCH -->
                    <v-text-field 
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))'
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>

                    <!-- NEW USER BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </template> 
                
            <!-- Sets Count -->
            <template v-slot:[`item.sets_count`]="{ item }">
               {{item.sets.length}}
            </template>

        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="red--text">API ERROR/{{ error }}</span>
    </div>
</template>

<script>
export default {

    name: 'PackagesTab',

    data: () => ({
        packages: [],
        loading: false,
        total: 0,
        options: {
            itemsPerPage: 15,
        },
        search: null,

        // objects
        countries: null,

        // api error
        error: null,
    }),

    watch: {
        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchPackages();
            }
        },
    },

    created() {
        this.fetchPackages();
    },

     methods: {

        fetchPackages: async function() {
            console.log("fetching packages")
            this.loading = true;
            try {
                let res = await this.$api.get("/packages");
                let items = [];
                for (let item of res.data) {
                    items.push(item)
                }
                this.packages = items;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // go to Package.vue
        selectRow(row) {
            this.$router.push({path: "/main/package/" + row.id})
        },

        create() {
            this.$router.push({path: "/main/package/new"})
        },

    },

}
</script>

<style>

</style>