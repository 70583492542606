<template>
    <div>
        <v-tabs v-model="tab" background-color="transparent" key="single-content-trans-tabs" class="">
            <v-tabs-slider :color="colorView"></v-tabs-slider>
            <v-tab v-for="t in single.translations" :class='tabColor' :key="t.output_language">{{t.output_language}}</v-tab>
            <v-tab  :class='tabColor'>SOURCE: {{this.single.language}}</v-tab>
        </v-tabs>
   
        <v-card tile flat outlined key="single-content-trans-tabs-items">
            <v-tabs-items v-model="tab" class="tab-items">
                <v-tab-item v-for="(t,index) in single.translations" :key="index">
                    
                    <v-card flat class="px-3" key="single-content" width="100%">
                        <v-card-title v-if="display_title" class="text-h6 font-weight-bold text-center wordbreak">{{t.title}}</v-card-title>
                        <v-card-text class="content text-body-1 pTagText--text"><span v-html="contentHtml(t.content)"></span></v-card-text>
                    </v-card>
                    
                </v-tab-item>
                <v-tab-item :key="single.language">
                    
                    <v-card flat class="px-3" key="single-content" width="100%">
                        <v-card-title v-if="display_title" class="text-h6 font-weight-bold text-center wordbreak">{{single.title}}</v-card-title>
                        <v-card-text class="content text-body-1 pTagText--text"><span v-html="contentHtml(single.content)" ></span></v-card-text>
                    </v-card>
                    
                </v-tab-item>
            </v-tabs-items>
        </v-card>  
    </div>
</template>

<script>
export default {
    name: "SingleTransTabs",

    props: ['single','colorView','display_title','no_image'],

    data: () => ({
        tab: null,
    }),

    watch: {
        tab: {
            deep: true,
            immediate: true,
            handler() {
                this.$emit("eventTransTabIndex", this.tab)
            }
        }
    },

    methods: {
        contentHtml(html) {
            if (this.no_image) {
                return html.replace(/<img\b[^>]*>/gi, "")
            } else {
                return html
            } 
        }
    },

    computed: {

        tabColor() {
            let tokens = this.colorView.trim().split(/\s+/)
            if (tokens.length > 1 ) {
                return "caption font-weight-bold " + tokens[0] + "--text text--" + tokens[1]
            } else {
                return "caption font-weight-bold " + this.colorView + "--text"
            }
        }
       
    }

    
}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>