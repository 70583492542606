<template>
    <div>

        <v-card v-if="position == 'vertical'" flat tile class="d-flex flex-column align-center justify-start pb-2" width="60" color="">
           
            <v-icon v-if="isPooled" @click="removeFromPool" color="blue lighten-2">mdi-bookmark</v-icon>
            <v-icon v-else @click="addToPool" color="blue lighten-2">mdi-bookmark-outline</v-icon>
           
            <!--<v-icon  v-if="isFoldered" @click="removeFromFolder" color="success">mdi-folder-plus</v-icon>-->
            <!--<v-icon  v-else @click="addToFolder" color="success">mdi-folder-plus-outline</v-icon>-->
        </v-card>

        <v-card v-else flat width="160" class="d-flex justify-space-around py-2" color="transparent">
            
            <v-icon v-if="isPooled" @click="removeFromPool" color="blue lighten-2">mdi-bookmark</v-icon>
            <v-icon v-else @click="addToPool" color="blue lighten-2">mdi-bookmark-outline</v-icon>
            
            <!--<v-icon  v-if="isFoldered" @click="removeFromFolder" color="success">mdi-folder-plus</v-icon>-->
            <!--<v-icon  v-else @click="addToFolder" color="success">mdi-folder-plus-outline</v-icon>-->
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'PoolWidget',

    props:['post','index','position'],

    data: () => ({
        loading: false,
        isPooled: false,
        isFoldered: false,
    }),

    watch: {

        post: {
            deep: true,
            immediate: true,
            handler() {
                this.isPooled = this.post.is_pooled;
                this.isFoldered = this.post.is_foldered
            }
        },

    },

    methods: {
        addToPool: async function() {
            this.isPooled = true;
            await this.$api.get("/profile/subscriber/pool/add/" + this.post.id);
            this.$emit("eventPostAddedInPool", this.post.id, this.index)
        },

        removeFromPool: async function() {
            this.isPooled = false;
            await this.$api.get("/profile/subscriber/pool/remove/" + this.post.id);
            this.$emit("eventPostRemoveFromPool", this.post.id, this.index)
        },

        addToFolder() {
            this.isFoldered = true;
        },

        removeFromFolder() {
            this.isFoldered = false;
        },
    }
}
</script>

<style>

</style>