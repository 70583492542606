<template>
    <div>
       
        <!-- loading -->
        <v-progress-linear
            indeterminate
            :color="tabColor"
            :active="loading">
        </v-progress-linear>

        <Header 
            :languageMain="language"
            :countryMain="country"
            @eventLanguage="eventLanguage" 
            @eventCountry="eventCountry"
            @eventReload="eventReload"
            @eventUndo="eventUndo"
            :color="tabColor"
            stream="standby"
            class="my-2"
        ></Header>

        <!-- error msg -->
        <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
        
        <!-- no post msg -->
        <v-card outlined v-if="error == 'empty' || remaining == 0" flat class="ma-2">
            <v-card-text class="text-center green--text">{{$i18n.t("noMorePost")}}</v-card-text>
        </v-card>
            
        <!-- common frame -->
        <Frame
            :tab="tab"
            :frameColor="tabColor"
            :posts="posts"
            @eventVote="eventVote"
            @eventGoTags="eventGoTags"
            @eventGoLoot="eventGoLoot"
            @eventGoCorpus="eventGoCorpus"
            @eventGoMiner="eventGoMiner"
            @eventGoSource="eventGoSource"
            :tabDialogSingle="tabDialogSingle"
            :tabDialogSingleIndex="tabDialogSingleIndex"
            :tabDialogSinglePost="tabDialogSinglePost"
            @eventOpenSingleDialog="eventOpenSingleDialog"
            @eventCloseSingleDialog="eventCloseSingleDialog"
        ></Frame>
        
    </div>
</template>

<script>
import trainer from "@/common/trainer";
import Header from '../components/HeaderLangCountry'
import Frame from './TrainerFrame'

export default {

    name: 'Standby',

    data: () => ({
        
        // setup
        loading: false,
        error: null,
        tab: "standby",
        tabColor: "green",

        // header
        language: null,
        country: null,
        remaining: 0,
        stashRemaining: 0,

        // paging
        page: 0,
        options: {
            itemsPerPage: 25,
        },

        // data
        posts: [],
        stash: [],

        // scrolling
        scrolling: 0,

        // tab single
        tabDialogSingle: false,
        tabDialogSingleIndex: 0,
        tabDialogSinglePost: null,

        // in order to fetch when changing tabs
        // only if have been created already
        hasBeenCreated: false,

        last_vote: {
            id: null,
            class: null,
            post: null,
            index: null,
        }
    }),

    components: {
        Header,
        Frame,
    },

    props: ['tabNumber'],

    created() {
        // init when back from single
        this.language = trainer.retriveStandbyLanguage();
        this.country = trainer.retriveStandbyCountry();
        this.tabDialogSingle = trainer.retriveStandbyDialog();
        this.tabDialogSingleIndex = trainer.retriveStandbyIndex();
        //  displaying the old single post before getting updates in tags/loot
        this.tabDialogSinglePost = trainer.retriveStandbySingle()
        this.scrolling = trainer.retriveStandbyScroll();
        this.fetchStandbyPosts()
        .then(() => { 
            this.tabDialogSinglePost = this.posts[this.tabDialogSingleIndex]
        })
        .then( () => {
            if (!this.tabDialogSingle) {
                window.scrollTo(0, this.scrolling);
            }
            this.hasBeenCreated = true;
        });
    },

    watch: {
        tabNumber: {
            deep: true,
            immediate: true,
            handler() {
                if (this.tabNumber == 0 && this.hasBeenCreated) {
                    this.posts=[];
                    this.fetchStandbyPosts()
                }
            }
        }
    },

    methods: {

        fetchStandbyPosts: async function() {
            this.loading = true
            this.error = null;
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + this.page + "&predicted_class=1" +
                this.languageApiFilter() + 
                this.countryApiFilter() 
            );

            try {
                let res = await this.$api.get("/profile/trainer/list" + filter);
                for (let p of res.data) {
                    this.posts.push(p);
                }
                this.remaining = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
                this.remaining = 0;
            } finally {
                this.$emit("eventStandbyRemaining", this.remaining)
                this.loading = false;              
            }
        },
        

        //* HEADER

        // Header Events
        eventLanguage(lg) {
            trainer.setStandbyLanguage(lg);
            this.language = lg;
            this.posts = [];
            this.stash = [];
            this.fetchStandbyPosts();
        },

        eventCountry(country) {
            trainer.setStandbyCountry(country);
            this.country = country;
            this.posts = [];
            this.stash = [];
            this.fetchStandbyPosts();
        },

        eventReload() {
            this.posts = [];

            this.last_vote.id = null;
            this.last_vote.class = null;
            this.last_vote.post = null;
            this.last_vote.index = null;
            
            this.fetchStandbyPosts();
        },

        // Header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "&country=" + this.country
            }
            return  ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            } 
            return ""
        },


        //* FRAME

        // Events

        // Vote
        eventVote(...[,index,stream]) {
            
            // store last vote
            this.last_vote.id = this.posts[index].id;
            this.last_vote.class = 0;
            this.last_vote.post = this.posts[index];
            this.last_vote.index = index;

            console.log(this.last_vote.id)

            this.posts.splice(index,1)
            this.remaining --;
            if (stream == 'single') {
                this.eventCloseSingleDialog();
            }
            this.$emit("eventStandbyRemaining", this.remaining)
        },
        

        eventUndo() {
            // put back post at the right place
            this.posts.splice(this.last_vote.index, 0, this.last_vote.post)
            // api
            this.reverseVote(1, this.last_vote.id, this.last_vote.post.language)
            // clean
            this.last_vote.id = null;
            this.last_vote.class = null;
            this.last_vote.post = null;
            this.last_vote.index = null;
        },

        reverseVote: async function(cl, id, lang) {
            this.loading = true
            try {
                await this.$api.get("/profile/trainer/reverse_vote/" + id +  "?class=" + cl + "&lang=" + lang);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // Go tools Tags
        eventGoTags(index) {
            trainer.setStandbyScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

        // Go tools Loot
        eventGoLoot(index) {
            trainer.setStandbyScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        // Go tools Corpus
        eventGoCorpus(index) {
            trainer.setStandbyScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_corpus_tab/" + this.posts[index].id})
        },

        // Go Miner
        eventGoMiner(index) {
            trainer.setStandbyScroll(window.scrollY);
            this.$router.push({path: "/main/miner_prompt/" + this.posts[index].id})
        },


        // Go Source
        eventGoSource(index) {
            trainer.setStandbyScroll(window.scrollY);
            this.$router.push({path: "/main/source/" + this.posts[index].source_ref})
        },

        //* SINGLE
        eventOpenSingleDialog(index) {
            trainer.setStandbyDialog(true);
            trainer.setStandbySingle(this.posts[index]);
            trainer.setStandbyIndex(index);
            trainer.setStandbyScroll(window.scrollY);
            this.scrolling = window.scrollY;
            this.tabDialogSingle = true;
            this.tabDialogSingleIndex = index;
            this.tabDialogSinglePost = this.posts[index];
        },


        eventCloseSingleDialog() {
            trainer.setStandbyDialog(false);
            trainer.setStandbySingle(null);
            trainer.setStandbyIndex(0);
            window.scrollTo(0,this.scrolling);
            this.tabDialogSingle = false;
            this.tabDialogSingleIndex = 0;
            this.tabDialogSinglePost = null;
        },

        
    },

    

}
</script>

<style>

</style>