<template>
    <div>
        <v-app-bar fixed min-height="170" color="" class="pa-0">
            <template v-slot:extension>
                <v-card flat width="100%" color="transparent" class="mt-2">
                    <v-card-actions class="py-0">
 
                        <!-- dialog -->
                        <v-btn
                            class="font-weight-bold"
                            color="blue"
                            text
                            @click="closeDialog"
                        >
                            <v-icon large :color="colorView">mdi-close-circle-outline</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>

                        <!-- TITLE -->
                        <v-card-title v-if="single.translations && single.translations[transIndex] && single.translations[transIndex] && transIndex < single.translations.length" class="text-h6 font-weight-bold text-center wordbreak py-0">{{single.translations[transIndex].title}}</v-card-title>
                        <v-card-title  v-else class="text-h6 font-weight-bold text-center wordbreak py-0">{{single.title}}</v-card-title>                    
                        <v-spacer></v-spacer>

                    </v-card-actions>

                    <v-card-actions class="py-0">
                        
                        <v-spacer></v-spacer>
                        <!-- META -->
                        <v-card-subtitle class="text-body-2 py-0">
                            <a :href="single.url">
                                <v-btn x-small rounded @click="goArticle" elevation="0" :color="colorView" class="Dialog--text font-weight-bold mx-0 px-1">
                                    <country-flag :country='single.country' size='small' :rounded="true" class=""/><span class="pt-1 pr-1">{{single.source_name}}</span>
                                </v-btn>
                        </a>
                            · {{$app.retriveCountryNameFromIso(single.country.toLowerCase())}} 
                            · {{formatPostDate}} | ES {{formatDate}} ({{sinceDate}}) · {{single.id}} · {{ wordsCount }} words
                        </v-card-subtitle>

                        <!-- TRAINER WIDGETS -->
                        <VoteWidget v-if="isUserProfileTrainer"
                            :post="single" 
                            :index="index" 
                            stream="single" 
                            :tab="tab"
                            @eventVote="eventVote" 
                        ></VoteWidget>
                        <TranslationWidget 
                            :post="single"
                            stream="single"
                            @eventTranslation="eventTranslation" 
                            class="ml-3"
                        />
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <!-- TAGS WIDGET -->
                    <v-card-actions class="py-0">
                        <v-spacer></v-spacer>
                        <TagsWidget 
                            :pid="single.id" 
                            :tags="single.tags" 
                            stream="single"
                            @eventGoTags="eventGoTags" 
                            class=""
                        ></TagsWidget>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <!-- Loot Widget -->
                    <v-card-actions class="py-2">
                        <v-spacer></v-spacer>
                        <LootWidget
                            :pid="single.id"
                            :loot="single.loot"
                            :language="single.language" 
                            @eventGoLoot="eventGoLoot"
                            stream="single" 
                            class="ml-4"
                        ></LootWidget>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-app-bar>

       

        <!-- SINGLE  -->
        <v-card flat tile outlined  class="mt-15 pt-3" color="" height="100%">
            <v-container fluid key="single-content" class="mt-15">
                <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="8">
                        
                        <SingleContentTransTabs 
                            v-if="single.translations && single.translations[0].content.length > 0" 
                            :single="single" 
                            :colorView="colorView"
                            @eventTransTabIndex="eventTransTabIndex"
                            class="mt-5"
                        ></SingleContentTransTabs>

                        <v-sheet v-else rounded outlined :color="single.true_class == 0? '': colorView" key="single-trainer" class="" width="100%">
                            <v-card flat class="px-3">
                                <v-card-title class="accent--text text-h5 font-weight-bold wordbreak">{{single.title}}</v-card-title>
                                <v-card-subtitle class="text-body-1">
                                    <v-btn x-small rounded elevation="0" @click="goArticle" color="accent" class="Dialog--text font-weight-bold ml-2">
                                        {{single.source_name}}
                                    </v-btn> 
                                    - <v-btn x-small fab color="transparent" elevation="0"><country-flag :country='single.country' size='normal' :rounded="true" class="mb-1"/></v-btn>  {{$app.retriveCountryNameFromIso(single.country)}} 
                                    · {{formatDate}} · {{single.id}}
                                </v-card-subtitle>
                        
                                <v-card-text class="content text-body-1 pTagText--text"><span v-html="single.content"></span></v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row>
            </v-container>
        </v-card> 

    </div>
</template>

<script>
import moment from "moment";
import VoteWidget from '../../Vote/VoteWidget.vue'
import TagsWidget from '../../Tags/TagsWidget.vue'
import LootWidget from '../../Loot/LootWidget.vue'
import TranslationWidget from '../../Translations/TranslationWidget.vue'
import SingleContentTransTabs from '../components/SingleContentTransTab'

export default {

    name:'DialogSingle',

    props:['single','index','tab','colorView','isUserProfileTrainer'],

    components: {
        VoteWidget,
        LootWidget,
        TagsWidget,
        SingleContentTransTabs,
        TranslationWidget,
    },

    data: ()=> ({
        // keep track of which index in single.translations is active
        transIndex: 0,
    }),

    methods: {
        goArticle() {
            window.open(this.single.url, '_blank');
        },

        closeDialog() {
            this.$emit("eventCloseSingleDialog");
        },

        eventVote(cl, index, stream) {
            this.$emit("eventVote", cl, index, stream)
        },

        eventTranslation(output_language,content,description, title) {
            this.$emit("eventTranslation", this.index, output_language, content,description, title)
        },

        eventTransTabIndex(tabIndex) {
            this.transIndex = tabIndex;
        },

        eventGoTags() {
            this.$emit("eventGoTags", this.index);
        },

        eventGoLoot() {
            this.$emit("eventGoLoot", this.index);
        },
    },

    computed: {
        sinceDate() {
            return moment(this.single.created_at).locale(this.$auth.retriveUserField("lang")).fromNow();
        },

        formatDate() {
            return moment.utc(this.single.created_at).format("DD/MM/YY@HH:MM UTC")
        },

        formatPostDate() {
            return moment(this.single.date).format("DD/MM/YY")
        },

        wordsCount() {
            let noHtml = this.single.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },
    }

}
</script>

<style>
.wordbreak {
    word-break: normal;
}

.content p {
    margin-bottom: 15px;
    margin-top: 7px;
}

.content h2,h3,h4 {
    margin-bottom: 16px;
    margin-top: 20px;
}
.cardImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-width: 500px;
}
</style>