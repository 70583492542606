var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":[
            { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
            { text: 'Nom', value: 'name', align:'center', class:'text-body-2' },
            { text: 'Description', value: 'description', align:'center', class:'text-body-2' },
            { text: 'Sets', value: 'sets_count', align:'center', class:'text-body-2' },
        ],"items":_vm.packages,"loading":_vm.loading,"search":_vm.search,"footer-props":{
            itemsPerPageText:'packages par page',
            itemsPerPageOptions: [15,30,-1],
        }},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"Dialog"}},[_c('v-toolbar-title',{staticClass:"accent--text"},[_vm._v("Packages"),(_vm.total > 0)?_c('span',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm.total)+")")]):_vm._e()]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","flat":"","rounded":"","solo":"","single-line":"","label":_vm.$tools.capitalizeWord(_vm.$i18n.t("search")),"hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","color":"accent","elevation":"0"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"color":"Dialog"}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:`item.sets_count`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.sets.length)+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"accent","indeterminate":""},slot:"progress"})],1),(_vm.error)?_c('span',{staticClass:"red--text"},[_vm._v("API ERROR/"+_vm._s(_vm.error))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }