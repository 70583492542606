<template>
    <div>

        <!-- TARGET URL -->
        <v-card flat outlined class="ma-2" key="sop-form">
            
            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="color"
                :active="loading">
            </v-progress-linear>

            <v-card-actions>
               <v-spacer></v-spacer>
                <!-- Select sop -->
                <v-select
                    v-model="sop"
                    :items="sops"
                    dense
                    outlined
                    item-text="ref"
                    label="Special Ops"
                    return-object
                    hide-details=""
                    @change="selectOp"
                    :color="color"
                ></v-select>
                
                <v-spacer></v-spacer>
                <v-btn x-small class="Dialog--text" :color="color"> {{total}} ops</v-btn>
               
                
                <!-- NEW SOP -->
                <v-spacer></v-spacer>
                <v-btn
                    x-small
                    fab
                    color="success"
                    :loading="loading"
                    :disabled="loading"
                    @click="newOp"
                    class="font-weight-bold mr-2"
                >
                    <v-icon >mdi-plus</v-icon>
                </v-btn>
               
                <v-spacer></v-spacer>
               
            </v-card-actions>

            

            <!-- FORM SOP -->
            <v-card-text>
                
                <!-- Ref -->
                <v-text-field v-model="sop.ref" dense outlined label="Ref" :color="color"></v-text-field>

                <v-card-actions>
                    <v-icon class="mr-3" :color="color" @click="goArticle">mdi-eye</v-icon>

                    <!-- Target URL -->
                    <v-text-field
                        v-model="sop.target_url"
                        dense 
                        outlined  
                        label="Target Url"
                        hide-details=""
                        :color="color"
                    ></v-text-field>
        
                </v-card-actions>

                <!-- CRUD SOP -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="isNew"
                        small
                        color="success"
                        :loading="loading"
                        :disabled="loading"
                        @click="create"
                        class="font-weight-bold mr-2 mt-5"
                    >
                        <v-icon left>mdi-plus</v-icon> Create
                    </v-btn>
                    <v-btn v-if="!isNew"
                        small
                        color="success"
                        :loading="loading"
                        :disabled="loading"
                        @click="save"
                        class="font-weight-bold white--text mr-2"
                    >
                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                    </v-btn>
                    <v-btn v-if="!isNew"
                        small
                        :loading="loading"
                        :disabled="loading"
                        color="error"
                        @click="remove"
                        class=" font-weight-bold mr-2"
                    >
                        <v-icon small>mdi-delete</v-icon>
                        Remove
                    </v-btn>
                </v-card-actions>
                
            </v-card-text>

            <!-- error msg -->
            <v-card-text v-if="error" class="red--text py-0 text-center">{{ error }}</v-card-text>
        </v-card>


        <v-card flat outlined class="" key="chromedp-form+feeds">
            <v-container fluid>
                <v-row>
                    
                    <!-- FORMS -->
                    <v-col cols="4">
                        <v-card outlined flat>
                            <v-card flat key="chromedp-form">

                                <v-card-actions class="py-0">
                                    <v-spacer></v-spacer>
                                    <v-switch v-model="sop.feeds_is_chromedp" :color="color" label="Chromedp ?" hide-details=""></v-switch>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                                
                                <v-card-text>    
                                
                                    <!-- chromedper time out -->
                                    <v-text-field v-if="sop.feeds_is_chromedp"
                                        outlined
                                        dense
                                        v-model.number="sop.feeder_chromedp_time_out"
                                        label="Time out in sec."
                                        :rules="[rules.required]"
                                        required
                                        hides-details
                                        :color="color"
                                    ></v-text-field>

                                    <!-- chromedper CSS body target -->
                                    <v-text-field v-if="sop.feeds_is_chromedp"
                                        outlined
                                        dense
                                        v-model="sop.feeder_chromedp_target"
                                        label="CSS Body target #id .class"
                                        hide-actions
                                        :color="color"
                                    ></v-text-field>

                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text class="py-0">

                                    <!-- interceptors -->
                                    <v-checkbox
                                        v-model ="sop.has_feed_interceptor"
                                        label="interceptor ?"
                                        hide-details
                                        color="warning"
                                    >
                                        <template v-slot:label>
                                            <div><strong class="warning--text">Interceptor ?</strong></div>
                                        </template>
                                    </v-checkbox>
                                    
                                    <!-- add feed host -->
                                    <v-checkbox
                                        v-model="sop.add_feed_host"
                                        label="host before url ?"
                                        hide-details
                                        :color="color"
                                    ></v-checkbox>
                            
                                    <!-- add extra slash between feed.host and feed.url -->
                                    <v-checkbox
                                        v-model="sop.feed_between_slash"
                                        label="extra slash ?"
                                        hide-details=""
                                        :color="color"
                                    ></v-checkbox>

                                    <!-- do not store description (sometimes too long !)-->
                                    <v-checkbox
                                        v-model="sop.feed_no_description"
                                        label="no description ? (sometimes too long)"
                                        :color="color"
                                    ></v-checkbox>
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-text class="py-5">

                                    <!-- HTMLER URL -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="sop.feed_url_selectors"
                                        label="URL CSS Selectors [sel1,sel2,sel3]"
                                        :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                                        required
                                        :color="color"
                                        hide-details
                                    ></v-text-field>

                                    <v-card-subtitle class="pt-0">Enter only the class inside a tag, collects directly the url</v-card-subtitle>

                                    <!-- HTMLER TITLES -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="sop.feed_title_selectors"
                                        label="TITLE CSS Selectors [sel1,sel2,sel3]"
                                        :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                                        required
                                        :color="color"
                                    ></v-text-field>

                                    <!-- HTMLER DESC -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="sop.feed_description_selectors"
                                        label="DESC CSS Selectors [sel1,sel2,sel3]"
                                        :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                                        required
                                        hide-details=""
                                        :color="color"
                                    ></v-text-field>

                                </v-card-text>

                                <!-- SAVE FEED SETUP -->
                                <v-card-actions class="py-1">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        @click="save"
                                        class=" mr-5 py-0 Dialog--text font-weight-bold"
                                        :color="color"
                                    >save</v-btn
                                ></v-card-actions>
                            </v-card>
                            <v-divider></v-divider>
                            
                            <!-- DATE SNEAK PEEK -->
                            <v-card flat>
                                <v-card-title class="my-0">Date Sneak Peek</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <!-- from post url-->
                                    <v-checkbox
                                        v-model="sop.date_from_post_url"
                                        label="From post url ?"
                                        :color="color"
                                    ></v-checkbox>
                                    <v-spacer></v-spacer>
                                    <!-- from post meta -->
                                    <v-checkbox v-if="sop.date_from_post_url"
                                        v-model="sop.date_from_post_meta"
                                        label="From post meta date ?"
                                        :color="color"
                                    ></v-checkbox>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                                <v-card-text class="py-0">
                                    
                                    <!-- selectors -->
                                    <v-text-field v-if="!sop.date_from_post_meta"
                                        outlined
                                        dense
                                        v-model="sop.date_selectors"
                                        label="DATE CSS Selectors [sel1,sel2,sel3]"
                                        :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                                        required
                                        :color="color"
                                    ></v-text-field>

                                    <!-- date pattern -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="sop.date_pattern"
                                        label="DATE Pattern"
                                        :rules="[]"
                                        required
                                        :color="color"
                                        disabled
                                    ></v-text-field>

                                    <!-- date format -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="sop.date_format"
                                        label="DATE format [Mon Jan 2 15:04:05 -0700 MST 2006]"
                                        :rules="[]"
                                        required
                                        :color="color"
                                        hide-details=""
                                        disabled
                                    ></v-text-field>
                                </v-card-text>

                                <!-- SAVE FEED SETUP -->
                                <v-card-actions class="">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        @click="save"
                                        class="mr-5 py-0 Dialog--text font-weight-bold"
                                        :color="color"
                                    >save</v-btn
                                ></v-card-actions>
                            </v-card>
                            
                            <v-divider></v-divider>
                            
                            <!-- URL FILTERS -->
                            <v-card flat>
                                <v-card-title class="my-0">Url String Filter</v-card-title>
                                <v-card-text class="py-0">
                                    <v-textarea
                                        outlined
                                        dense
                                        auto-grow
                                        v-model="sop.feed_filter_out"
                                        label="Filter out in url [/val/,/val/,...]"
                                        :rules="[noEndingComa,emptySpacesRule,noCommaAndSpaceRule,noConsecutiveCommasRule]"
                                        required
                                        hint="Note => term1,term2,term3 (no space, no coma at the end)"
                                        hide-details
                                    ></v-textarea>
                                    <v-card-subtitle class="my-0 py-0">We can use class inside a tag to select specific element even for a title.</v-card-subtitle>
                                </v-card-text>

                                <!-- SAVE FEED SETUP -->
                                <v-card-actions class="pt-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        @click="save"
                                        class="mr-5 py-0 Dialog--text font-weight-bold"
                                        :color="color"
                                    >save</v-btn
                                >
                                </v-card-actions>

                                <!-- FEED/SOURCE HOST-->
                                <v-card-actions v-if="sop.stream_type != 'chromedp'" class="py-0">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model.number="sop.feed_host"
                                        label="Feed Host"
                                        hides-details
                                        class="mr-3"
                                    ></v-text-field>
                                    
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model.number="sop.source_host"
                                        label="Source Host"
                                        hides-details
                                        class="mr-3"
                                    ></v-text-field>
                                
                                </v-card-actions>
                                <v-divider></v-divider>

                                <!-- FEED CATEGORIES FILTER OUT -->
                                <v-card-title class="my-0">Categories Filter</v-card-title>
                                <v-card-text class="py-0">
                                    <v-textarea
                                        outlined
                                        dense
                                        v-model="sop.feed_filter_out_categories"
                                        label="Categories out [sel1,sel2,sel3]"
                                        :rules="[noEndingComa,noConsecutiveCommasRule,noCommaAndSpaceRule]"
                                        required
                                        hint
                                        hide-details=""                
                                    ></v-textarea>
                                </v-card-text>
                                
                                <!-- SAVE FEED SETUP -->
                                <v-card-actions class="pb-2">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        @click="save"
                                        class="mr-5 Dialog--text font-weight-bold"
                                        :color="color"
                                    >save</v-btn
                                >
                                </v-card-actions>
                            
                            </v-card>
                        </v-card>
                    </v-col>

                    <!-- FEEDS -->
                    <v-col cols="8">
                        <FeedTest  key="feed-test"
                            :sop="sop"
                            @eventSoFeeds="eventSoFeeds"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <!-- snack bar -->
        <v-snackbar centered v-model="snackbar" timeout="500" color="success">
        <span class="font-weight-bold Dialog--text">Saved !</span>
        <template v-slot:action="{ attrs }">
            <v-btn
                small
                class="Dialog--text"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                {{ $i18n.t("close") }}
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import { Model } from "@/common/model";
import FeedTest from './SpecialOpsFeedTest.vue'

// Source CLASS
class SpecialOp extends Model {

    source_id = null;
    ref = null;
    target_url = null;
    source_name = "";
    source_ref = "";
    language = "";

    feeds_is_chromedp = false;
    feeder_chromedp_time_out = 20;
    feeder_chromedp_body_target = ""

    has_feed_interceptor= false;
    add_feed_host = false;
    feed_between_slash = false; 
    feeed_no_description = false;
    
    feed_filter_out = "";
    feed_filter_out_categories = "";
   
    feed_url_selectors = "";
    feed_title_selectors = "";
    feed_description_selectors = "";

    post_is_chromedp = false;
    //date
    date_from_post_meta = false;
    date_from_post_url = false;
    date_selectors = "";
    date_pattern = "";
    date_format = "";



    onParse() {
       
    }

    onFormat() {

    }
}

export default {

    name: 'SpecialOpsFeedsTab',

    props: ["source"],

    components: {
        FeedTest,
    },

    created() {
        this.sop = SpecialOp.init();
        if (this.source.id && this.source.id > 0)  {
            this.sop.source_id = this.source.id
            this.sop.source_name = this.source.name;
            this.sop.source_ref = this.source.ref;
            this.sop.language = this.source.lang;
            this.fetchSpecialOps(this.source.id).then(() => {
                if (this.total > 0) {
                    this.isNew = false;
                }

            })
        }
    },

    data: () => ({
        isNew : true,
        loading: false,
        error: null,
        snackbar: false,
        total: 0,
        sops: [],
        sop: null,
        color: "orange accent-2",
       
        target: "",

        rules: {
            required: (v) => !!v || "Field required",
        },
    }),

    methods: {
        
        fetchSpecialOps: async function () {
            console.log("fetch specials ops for source:", this.source.id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/special_ops/" + this.source.id);
                if (res.data) {
                    if (res.data.length > 0) {
                        for (let sop of res.data) {
                            let so = SpecialOp.init();
                            so = sop;
                            this.sops.push(so);
                        }
                        this.sop = this.sops[0];
                    }
                }
                this.total = parseInt(res.headers["x-paginated-items"]);
            }  catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* Events
        eventSoFeeds(feeds) {
            this.$emit("eventSoFeeds", feeds);
        },

        goArticle() {
            window.open(this.sop.target_url, '_blank');
        },

        //* CRUD
        newOp() {
            console.log("new")
            this.isNew = true;
            this.sop = SpecialOp.init()
            this.sop.source_id = this.source.id
            this.sop.source_name = this.source.name;
            this.sop.source_ref = this.source.ref;
            this.sop.language = this.source.lang;
            this.sop.source_id = this.source.id
        },

        selectOp() {
            this.isNew = false;
        },

        create: async function () {
            console.log("create");

            if (!this.sop.ref) {
                window.alert("No ref !");
                return
            }

            if (!this.sop.target_url) {
                window.alert("No target_url !");
                return
            }

            this.loading = true;
            this.error = null;
            try {
                await this.$api.post("/special_ops", this.sop.format());
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.sops.push(this.sop);
                this.loading = false;
            }
        },

        save: async function () {
            console.log("save");

            if (!this.sop.ref) {
                window.alert("No ref !");
                return
            }

            if (!this.sop.target_url) {
                window.alert("No target_url !");
                return
            }

            this.loading = true;
            this.error = null;
            try {
                await this.$api.patch("/special_ops/update/" + this.sop.id, this.sop);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.snackbar = true;
                this.loading = false;
            }
        },

        remove: async function () {
            console.log("remove");
            if (!confirm('Remove special op "' + this.sop.ref + '"?')) {
                return;
            }
            this.loading = true;
            this.error = null;

            try {
                await this.$api.delete("/special_ops/delete/" + this.sop.id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                let index = this.sops.indexOf(this.sop);
                if (index !== -1) {
                    this.sops.splice(index, 1); // Remove one element at the specified index
                }

                this.sop = SpecialOp.init()
                this.isNew = true;
                this.loading = false;
            }
        },
    },

    computed: {

        noEndingComa() {
            return (value) => {

                // Check if value is null or undefined
                if (value == null) {
                    return 'Input should not be null or undefined';
                }

                // Check if the value ends with a comma
                if (value.endsWith(',')) {
                    return 'Input should not end with a comma';
                }
                return true; // Validation passed
            };
        },

        emptySpacesRule() {
            return (value) => {
                if (/\s/g.test(value)) {
                    return "Empty spaces are not allowed.";
                } else {
                    return true;
                }
             };
        },

        maxTwoCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,/g) || []).length;
                if (commaCount > 2) {
                    return "Only up to two commas are allowed.";
                } else {
                    return true;
                }
            };
        },

        noConsecutiveCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,,/g) || []).length;
                if (commaCount > 0) {
                    return "Consecutive commas are not allowed.";
                } else {
                    return true;
                }
            };
        },

        noCommaAndSpaceRule() {
            return (value) => {
                const pattern = /,\s|,\s|,\s|\s,/;
                if (pattern.test(value)) {
                    return "Comma followed by an empty space or vice versa is not allowed.";
                } else {
                    return true;
                }
            };
        }
    }
}
</script>

<style>

</style>