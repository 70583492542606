// store selected situation trainer tabs
export default {
    // min tab
    tab: 0,

    setTab(value) {
        this.tab = value;
    },

    retriveTab() {
        return this.tab;
    },

    //* STANDBY
    standby: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index: 0,
        scroll: 0,
    },

    setStandbyLanguage(lang) {
        this.standby.language = lang;
    },

    setStandbyCountry(country) {
        this.standby.country = country;
    },

    setStandbyDialog(bool) {
        this.standby.dialog = bool;
    },

    setStandbySingle(obj) {
        this.standby.single = obj;
    },

    setStandbyIndex(i) {
        this.standby.index = i;
    },

    setStandbyScroll(val) {
        this.standby.scroll = val;
    },

    retriveStandbyLanguage() {
        return this.standby.language;
    },

    retriveStandbyCountry() {
        return this.standby.country;
    },

    retriveStandbyDialog() {
        return this.standby.dialog;
    },

    retriveStandbySingle() {
        return this.standby.single;
    },

    retriveStandbyIndex() {
        return this.standby.index;
    },

    retriveStandbyScroll() {
        return this.standby.scroll;
    },

    //* TRASH
    trash: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index:0,
        scroll: 0,
    },

    setTrashLanguage(lang) {
        this.trash.language = lang;
    },

    setTrashCountry(country) {
        this.trash.country = country;
    },

    setTrashDialog(bool) {
        this.trash.dialog = bool;
    },

    setTrashSingle(obj) {
        this.trash.single = obj;
    },

    setTrashIndex(i) {
        this.trash.index = i;
    },

    setTrashScroll(val) {
        this.trash.scroll = val;
    },

    retriveTrashLanguage() {
        return this.trash.language;
    },

    retriveTrashCountry() {
        return this.trash.country;
    },

    retriveTrashDialog() {
        return this.trash.dialog;
    },

    retriveTrashSingle() {
        return this.trash.single;
    },

    retriveTrashIndex() {
        return this.trash.index;
    },

    retriveTrashScroll() {
        return this.trash.scroll;
    },

    //* crimorg
    crimorg: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index:0,
        scroll: 0,
    },

    setCrimorgLanguage(lang) {
        this.crimorg.language = lang;
    },

    setCrimorgCountry(country) {
        this.crimorg.country = country;
    },

    setCrimorgDialog(bool) {
        this.crimorg.dialog = bool;
    },

    setCrimorgSingle(obj) {
        this.crimorg.single = obj;
    },

    setCrimorgIndex(i) {
        this.crimorg.index = i;
    },

    setCrimorgScroll(val) {
        this.crimorg.scroll = val;
    },

    retriveCrimorgLanguage() {
        return this.crimorg.language;
    },

    retriveCrimorgCountry() {
        return this.crimorg.country;
    },

    retriveCrimorgDialog() {
        return this.crimorg.dialog;
    },

    retriveCrimorgSingle() {
        return this.crimorg.single;
    },

    retriveCrimorgIndex() {
        return this.crimorg.index;
    },

    retriveCrimorgScroll() {
        return this.crimorg.scroll;
    },


    //* law
    law: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index:0,
        scroll: 0,
    },

    setLawLanguage(lang) {
        this.law.language = lang;
    },

    setLawCountry(country) {
        this.law.country = country;
    },

    setLawDialog(bool) {
        this.law.dialog = bool;
    },

    setLawSingle(obj) {
        this.law.single = obj;
    },

    setLawIndex(i) {
        this.law.index = i;
    },

    setLawScroll(val) {
        this.law.scroll = val;
    },

    retriveLawLanguage() {
        return this.law.language;
    },

    retriveLawCountry() {
        return this.law.country;
    },

    retriveLawDialog() {
        return this.law.dialog;
    },

    retriveLawSingle() {
        return this.law.single;
    },

    retriveLawIndex() {
        return this.law.index;
    },

    retriveLawScroll() {
        return this.law.scroll;
    },

    //* loot
    loot: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index:0,
        scroll: 0,
    },

    setLootLanguage(lang) {
        this.loot.language = lang;
    },

    setLootCountry(country) {
        this.loot.country = country;
    },

    setLootDialog(bool) {
        this.loot.dialog = bool;
    },

    setLootSingle(obj) {
        this.loot.single = obj;
    },

    setLootIndex(i) {
        this.loot.index = i;
    },

    setLootScroll(val) {
        this.loot.scroll = val;
    },

    retriveLootLanguage() {
        return this.loot.language;
    },

    retriveLootCountry() {
        return this.loot.country;
    },

    retriveLootDialog() {
        return this.loot.dialog;
    },

    retriveLootSingle() {
        return this.loot.single;
    },

    retriveLootIndex() {
        return this.loot.index;
    },

    retriveLootScroll() {
        return this.loot.scroll;
    },

    //* special ops
    special_ops: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index:0,
        scroll: 0,
    },

    setSpecialOpsLanguage(lang) {
        this.special_ops.language = lang;
    },

    setSpecialOpsCountry(country) {
        this.special_ops.country = country;
    },

    setSpecialOpsDialog(bool) {
        this.special_ops.dialog = bool;
    },

    setSpecialOpsSingle(obj) {
        this.special_ops.single = obj;
    },

    setSpecialOpsIndex(i) {
        this.special_ops.index = i;
    },

    setSpecialOpsScroll(val) {
        this.special_ops.scroll = val;
    },

    retriveSpecialOpsLanguage() {
        return this.special_ops.language;
    },

    retriveSpecialOpsCountry() {
        return this.special_ops.country;
    },

    retriveSpecialOpsDialog() {
        return this.special_ops.dialog;
    },

    retriveSpecialOpsSingle() {
        return this.special_ops.single;
    },

    retriveSpecialOpsIndex() {
        return this.special_ops.index;
    },

    retriveSpecialOpsScroll() {
        return this.special_ops.scroll;
    },


}

