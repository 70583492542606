<template>
    <div>
         <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table
            :headers="[
                { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
                { text: 'Active', value: 'active', align:'center', class:'text-body-2' },
                { text: 'Langue Article', value: 'post_language', align:'center', class:'text-body-2' },
                { text: 'Traduire en', value: 'output_languages', align:'center', class:'text-body-2' },
                { text: 'Trash Title ', value: 'do_trash_title', align:'center', class:'text-body-2 warning--text' },
                { text: 'Trash Desc ', value: 'do_trash_description', align:'center', class:'text-body-2 warning--text' },
                { text: 'Trash Content ', value: 'do_trash_content', align:'center', class:'text-body-2 warning--text' },
                { text: 'Standby Title ', value: 'do_standby_title', align:'center', class:'text-body-2 success--text' },
                { text: 'Standby Desc ', value: 'do_standby_description', align:'center', class:'text-body-2 success--text' },
                { text: 'Standby Content ', value: 'do_standby_content', align:'center', class:'text-body-2 success--text' },
               

            ]"
            :items="sets"
            :loading="loading"
            @click:row="selectRow"
            :search="search"
            :footer-props="{
                itemsPerPageText:'sets par page',
                itemsPerPageOptions: [15,30,-1],
            }"
            multi-sort
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
        
             <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="accent--text">{{$i18n.t("Drawer.trans_sets")}}<span v-if="total > 0" class="ml-2">({{total}})</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                    <!-- DESKTOP TABLE SEARCH -->
                    <v-text-field 
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeString($i18n.t("search"))'
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>

                    <!-- NEW USER BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </template>


            <!-- ACTIVE -->
            <template v-slot:[`item.active`]="{ item }">
               <v-icon left :color="item.active ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

            <template v-slot:[`item.post_language`]="{ item }">
                {{$app.fetchLanguageNameAccordingUserLangUi(item.post_language)}}
            </template>

            <template v-slot:[`item.output_languages`]="{ item }">
                {{buildOutputLanguagesInUserLang(item.output_languages)}}
            </template>

            <!-- DO TRASH TITLE -->
            <template v-slot:[`item.do_trash_title`]="{ item }">
               <v-icon left :color="item.do_trash_title ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

            <!-- DO TRASH DESCRIPTION -->
            <template v-slot:[`item.do_trash_description`]="{ item }">
               <v-icon left :color="item.do_trash_description ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

            <!-- DO TRASH CONTENT -->
            <template v-slot:[`item.do_trash_content`]="{ item }">
               <v-icon left :color="item.do_trash_content ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>


            <!-- DO STANDBY TITLE -->
            <template v-slot:[`item.do_standby_title`]="{ item }">
               <v-icon left :color="item.do_standby_title ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

            <!-- DO STANDBY DESCRIPTION -->
            <template v-slot:[`item.do_standby_description`]="{ item }">
               <v-icon left :color="item.do_standby_description ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

            <!-- DO STANDBY CONTENT -->
            <template v-slot:[`item.do_standby_content`]="{ item }">
               <v-icon left :color="item.do_standby_content ? 'success':'error'">mdi-circle-medium</v-icon>
            </template>

         
        
        </v-data-table>
    </div>
</template>

<script>
export default {

    name: 'TransSetsTab',

    data: () => ({
        sets: [],
        loading: false,
       
       
        //items 
        total: 0,
        options: {
            itemsPerPage: 15,
        },
        search: null,

        // api error
        error: null,
    }),

    watch: {
        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchSets();
            }
        },
    },

    created() {
        this.fetchSets();
    },

    methods: {

        fetchSets: async function() {
            console.log("fetching sets")
            this.loading = true;
            try {
                let res = await this.$api.get("/translation/posts_sets");
                let items = [];
                for (let item of res.data) {
                    items.push(item)
                }
                // sort countries alphabetically
                items.sort((a,b) => a.post_language.localeCompare(b.post_language));
                this.sets = items;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // go to Set.vue
        selectRow(row) {
            this.$router.push({path: "/main/post_trans_set/" + row.id})
        },

        create() {
            this.$router.push({path: "/main/post_trans_set/new"})
        },

        buildOutputLanguagesInUserLang(output_languages) {
            let names = [];
            for (let o of output_languages.split(",")) {
                names.push(this.$app.fetchLanguageNameAccordingUserLangUi(o))
            }
            return names.join(", ")
        }

    },

}
</script>

<style>

</style>