import Vue from 'vue';
import VueI18n from 'vue-i18n';
import shellMessagesLang from '@/shell/lang/en';
import messagesLang from '@/lang/en';

Vue.use(VueI18n)


const i18n = new VueI18n({
    legacy: false, // Vuetify does not support the legacy mode of vue-i18n
    locale: 'en', // set locale
    fallbackLocale: {
        'en_au': ['en'],
        'en_bz': ['en'],
        'en_ca': ['en'],
        'en_gb': ['en'],
        'en_us': ['en'],

        'fr': ['fr'],
        'fr_be': ['fr'],
        'fr_ca': ['fr'],
        'fr_ch': ['fr'],
        'fr_lu': ['fr'],

        'default': ['en']
    },
    messages:  {
        en: {
            ...shellMessagesLang,
            ...messagesLang,
        },
    } // set locale messages
})

const loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang) {
    i18n.locale = lang
    return lang
}

export function loadLanguageAsync(lang) {

    let langFallback = lang.substring(0,2)
   
    // If the same language
    if (i18n.locale === langFallback) {
        return Promise.resolve(setI18nLanguage(langFallback))
    }
   
    // If the language was already loaded
    if (loadedLanguages.includes(langFallback)) {
        return Promise.resolve(setI18nLanguage(langFallback))
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/shell/lang/${langFallback}.js`)
    .then(
        (shellMessages) => {
            i18n.setLocaleMessage(langFallback, shellMessages.default)
            loadedLanguages.push(langFallback)
            return setI18nLanguage(langFallback)
        },
    ),
    import(/* webpackChunkName: "lang-[request]" */ `@/lang/${langFallback}.js`)
    .then(
        (messages) => {
            let shellMessages = i18n.getLocaleMessage(langFallback) 
            i18n.setLocaleMessage(langFallback, {...messages.default, ...shellMessages})
            loadedLanguages.push(langFallback)
            return setI18nLanguage(langFallback)
        },
    )
}

// 👇️ default export
export default i18n;
