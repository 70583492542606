export default {
    search: null,
    scroll: 0,

    setSearch(value) {
        this.search = value;
    },
  
    retriveSearch() {
        return this.search;
    },

    setScroll(val) {
        this.scroll = val;
    },

    retriveScroll() {
        return this.scroll;
    },

    
}