<template>
    <div>
        <v-card flat outlined>
            Post TEST
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'SpecialOpsPostTest',
    
}
</script>

<style>

</style>