<template>
  <div>
    Clusters
  </div>
</template>

<script>
export default {

    name: 'ClustersTab'
    
}
</script>

<style>

</style>