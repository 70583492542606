<template>
    <div>
    
        <v-card flat color="Background" key="deck-tab" class="pt-2">


            <!-- Deck Parameters -->
            <Parameters key="deck-parameters"
                :clusters="clusters"
                :delay="delay"
                :max_posts_count="max_posts_count"
                :new_loading="new_loading"
                :last_loading="last_loading"
                @eventUpdateParameters="eventUpdateParameters"
                @eventFetchNewDeck="fetchNewDeck"
                @eventFetchLastPosts="fetchLastPosts"
                class="mx-2"
            />

            <!-- loading -->
            <v-progress-linear 
                indeterminate 
                color="success"
                :active="loading"
            ></v-progress-linear>

            <!-- error -->
            <v-card v-if="error && error != 'empty'" flat color="transparent" key="deck-error-message">
                <!-- error msg -->
                <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
            </v-card>

            <!-- empty decks message -->
            <v-card flat key="deck-empty-message">
                <v-card-text v-if="error == 'empty'" class="text-center">Deck empty. Check Standby</v-card-text>
            </v-card>

            <!-- Clusters -->
            <draggable v-if="clusters && clusters.length > 0"
                v-model="clusters"
                group="clusters"
                @end="onDragEnd"
                key="draggable-clusters"
            >
                <v-card v-for="(cluster, cl_index) in clusters" :key="'cl_' + cl_index" class="">
                    <!-- Show according to continents -->
                    <span v-if="parameters.continent == 'all' || cluster.continent_name == parameters.continent">
                        
                        <!-- Show Only Clusters with multiple posts -->    
                        <span v-if="(parameters.only_clusters && cluster.posts.length > 1) || !parameters.only_clusters">
                            
                            <!-- Cluster -->        
                            <v-sheet :key="'sheet-cluster-' + cl_index"
                                outlined rounded
                                v-if="(cluster.posts.length > 1 && cluster.is_out == parameters.see_out) || (cluster.posts.length == 1 && cluster.posts[0].is_out == parameters.see_out)"
                                :color="sheetClusterColor(cl_index)" 
                                class="ma-2"
                            >
                                <v-card
                                    flat 
                                    class="pb-1" 
                                    color=""
                                    :key="'cluster-' + cl_index"
                                >
                                    
                                    <!-- cluster header-->
                                    <ClusterHeader v-if="cluster.posts.length > 0" :key="'cluster-header-' + cl_index"
                                        :clusters="clusters"
                                        :cluster="cluster"
                                        :cl_index="cl_index"
                                        :tags_list="tags_list"
                                        @eventShowCluster="eventShowCluster"
                                        @eventSwitchDeckOut="eventSwitchDeckOut"
                                        @eventChangeContinent="eventChangeContinent"
                                        @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                                        @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                                        @eventMergeClusters="eventMergeClusters"
                                        @eventOpenPublisherDialog="eventOpenPublisherDialog"
                                        @eventOpenEventerDialog="eventOpenEventerDialog"
                                        @eventSortClusters="sortClusters"
                                    />

                                    <!-- Posts -->
                                    <draggable v-if="cluster.show" :list="cluster.posts" group="posts" @change="onDragEnd" key="draggable-cluster-posts">
                                        
                                        <div v-for="(post, p_index) in cluster.posts" :key="'cl' + cl_index + '-p_' + post.id">
                                            
                                            <!-- Post -->
                                            <v-sheet outlined class="mx-1 mt-1" 
                                                v-if="post.is_out == parameters.see_out"
                                                :color="sheetPostColor(cl_index, p_index)"
                                            >
                                                <v-card flat color="" class="d-flex" key="cluster-post-and-widget">
                                                    <!-- cluster widget -->
                                                    <ClusterPostWidget :key="'widget_' + cl_index + '-p_' + post.id"
                                                        :post="post"
                                                        :cl_index ="cl_index"
                                                        :p_index="p_index"
                                                        :cluster_posts_count="cluster.posts.length"
                                                        @eventPostInNewCluster="eventPostInNewCluster"
                                                    />

                                                    <ClusterPost :key="'post_' + cl_index + '-p_' + post.id"
                                                        :post="post"
                                                        :cl_index ="cl_index"
                                                        :p_index="p_index"
                                                        :color="sheetPostColor(cl_index, p_index)"
                                                        @eventGoPublisher="eventGoPublisher"
                                                        @eventGoLoot="eventGoLoot"
                                                        @eventGoTags="eventGoTags"
                                                        @eventVote="eventVote"
                                                        @openClusterPostsSingleDialog="openClusterPostsSingleDialog"
                                                    />
                                                </v-card>
                                            </v-sheet>
                                        </div>
                                    </draggable>
                                </v-card>
                            </v-sheet>
                       
                        </span>

                    </span>
                </v-card>
            </draggable>
        </v-card>

        <!-- CREATE CLUSTERS BTN -->
        <!--&& deckClustersCount == 0-->
        <v-card flat v-if="parameters.see_out">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                    @click="createClusters" 
                    class="Dialog--text font-weight-bold" 
                    color="grey"
                    :loading="clusters_loading"
                    :disabled="clusters_loading"
                >
                    SEND TO CLUSTERS ?
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <!-- error -->
        <v-card v-if="clusters_error" flat color="transparent" key="deck-error-message">
            <!-- error msg -->
            <v-card-text class="ml-5 mt-2 error--text">API Error: {{ clusters_error }}</v-card-text>
        </v-card>

        <!-- Dialogs -->
        <v-dialog v-model="dialogs" key="deck-dialogs" fullscreen overlay-opacity="">
            <v-card flat color="" key="deck-dialogs-card">
                
                <!-- Cluster Post Single -->
                <DialogClusterSingle
                    v-if="cluster_single_dialog"
                    :cluster_single="cluster_single"
                    @eventCloseClusterPostDialog="eventCloseClusterPostDialog"
                    @eventGoPublisher="eventGoPublisher"
                    @eventGoTags="eventGoTags"
                    @eventGoLoot="eventGoLoot"
                    @eventVote="eventVote"
                    @eventGoSource="eventGoSource"
                    :color="sheetPostColor(cluster_single.cl_index, cluster_single.p_index)"
                />

                <!-- Publisher Dialog -->
                <DialogPublisher
                    v-if="publisher_dialog"
                    :cluster="publisher.cluster"
                    :cl_index="publisher.cl_index"
                    :from_calendar="from_calendar"
                    @eventClosePublisherDialog="eventClosePublisherDialog"
                />


                <!-- Eventer Dialog-->
                <DialogEventer v-model="eventer_dialog"
                    v-if="eventer_dialog"
                    :cluster="eventer.cluster"
                    :cl_index="eventer.cl_index"
                    @eventCloseEventerDialog="eventCloseEventerDialog"
                />

                
            </v-card>
        </v-dialog>

        <!-- snackbar no last posts-->
        <v-snackbar centered v-model="last_snackbar" timeout="1000" color="blue">
            <span class="font-weight-bold Dialog--text">No Last Posts</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    small
                    class="Dialog--text"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import deck from "@/common/deck";
import Draggable from 'vuedraggable'
import Parameters from './DeckParameters.vue'
import ClusterHeader from './Cluster/DeckClusterHeader.vue'
import ClusterPostWidget from './Cluster/DeckClusterPostWidget.vue'
import ClusterPost from './Cluster/DeckClusterPost.vue'

import DialogClusterSingle from './DialogClusterSingle.vue'
import DialogPublisher from './DialogPublisher.vue'
import DialogEventer from './DialogEventer.vue'

export default {


    name: 'DeckMain',

    components: {
        Parameters,
        Draggable,
        ClusterHeader,
        ClusterPostWidget,
        ClusterPost,

        DialogClusterSingle,
        DialogPublisher,
        DialogEventer,
    },

    data: () => ({
        
        loading: false,
        error: null,

        new_loading: false,
        last_loading: false,
        last_snackbar: false,
        clusters_loading: false,
        clusters_error: null,

        // header
        language: null,
        country: null,

        // Parameters
        delay: 0,
        max_posts_count: 0,
        parameters: null,

        // paging
        scrolling: 0,

        //* Deck
        clusters: [],
        post_ids: [],

        //* Persistence
        storage_clusters : null,

        //* widgets
        //- tags widget
        tags_list:[],

        // Dialogs
        dialogs: false,

        //cluster post
        cluster_single_dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },

        // publisher
        publisher_dialog: false,
        publisher: {
            cluster: null,
            cl_index: null,
        },

        from_calendar: false,

        // eventer
        eventer_dialog: false,
        eventer: {
            cluster: null,
            cl_index: null,
        },
    }),

    created() {

        // Header init
        this.language = deck.retriveLanguage();
        this.country = deck.retriveCountry();

        // init
        this.storage_clusters = null,
        this.parameters = deck.retriveParameters();
    
        // widgets Tags needs the full list to add tags
        this.fetchTags()

        // localStorage
        this.storage_clusters = JSON.parse(localStorage.getItem('clusters'));
        
        if (!this.storage_clusters || this.storage_clusters.length == 0 ) {
            console.log("not deck in storage, fetching deck via api")
            this.fetchNewDeck()
        } else {
            setTimeout(
                () => this.patchClusters()
                .then(this.fetchUpdatedPosts())
                .then( () => window.scrollTo(0, this.scrolling)),
                1000
            )
        }

        //* Calendar
        // router navigation back from calendar
        if (this.$route.params.post_id) {
            console.log("params found pid", this.$route.params.post_id);
            this.buildClusterFromPostIds([this.$route.params.post_id]);
            this.publisher_dialog = true;
            this.dialogs = true;
            this.from_calendar = true;
        } else if (this.$route.params.post_ids) {
            console.log("params found pids", this.$route.params.post_ids);
            this.buildClusterFromPostIds(this.$route.params.post_ids);
            this.publisher_dialog = true ;
            this.dialogs = true;
            this.from_calendar = true;
        }


    },

    methods: {

        //* Dialogs

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialogs = true;
            this.cluster_single_dialog = true;
            this.cluster_single.post = this.clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
            this.scrolling = window.scrollY;
            deck.setClusterDialog(true);
            deck.setClusterSingle(this.clusters[cl_index].posts[p_index]);
            deck.setClusterIndex(cl_index);
            deck.setClusterPostIndex(p_index);
            deck.setScroll(window.scrollY);
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialogs = false;
            this.cluster_single_dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
            deck.setClusterDialog(false);
            deck.setClusterSingle(null);
            deck.setClusterIndex(0);
            deck.setClusterPostIndex(0);
            this.scrolling = window.scrollY;
        },


        eventGoPublisher(cl_index, p_index) {
            console.log("eventGoPublisher", this.clusters[cl_index].posts[p_index].id)
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/publisher/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoTags(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoLoot(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.clusters[cl_index].posts[p_index].id})
        },

        eventGoSource(cl_index, p_index) {
            deck.setScroll(window.scrollY);
            const routeData = this.$router.resolve({ path: "/main/source/" + this.clusters[cl_index].posts[p_index].source_ref });
            window.open(routeData.href, '_blank');
        },

        eventMergeClusters(from_cl_index, to_cl_index) {
            console.log("eventMergeClusters", from_cl_index, to_cl_index);
            for (let p of this.clusters[from_cl_index].posts) {
                this.clusters[to_cl_index].posts.push(p)
            }
            this.clusters.splice(from_cl_index,1);
        },


        // publisher
        eventOpenPublisherDialog(cl_index) {
            this.dialogs = true;
            this.publisher_dialog = true;
            this.publisher.cluster = this.clusters[cl_index];
            this.publisher.cl_index = cl_index;
        },

        eventClosePublisherDialog() {
            console.log("eventClosePublisherDialog")
            this.dialogs = false;
            this.publisher_dialog = false
            this.publisher.cluster = null;
            this.publisher.cl_index = null;
            this.fetchUpdatedPosts();
        },

        //* Eventer
        eventOpenEventerDialog(cl_index) {
            this.dialogs = true;
            this.eventer_dialog = true;
            this.eventer.cluster = this.clusters[cl_index];
            this.eventer.cl_index = cl_index;
        },

        eventCloseEventerDialog() {
            console.log("eventClosePublisherDialog")
            this.dialogs = false;
            this.eventer_dialog = false
            this.eventer.cluster = null;
            this.eventer.cl_index = null;
        },

        ///* API
        fetchNewDeck: async function () {
            console.log("fetch new deck");
            this.new_loading = true;
            this.error = null;
            this.delay = 0;

            this.clusters = [],
            this.post_ids =[];

            let payload =  {
                hour: this.parameters.daily_batch_hour,
                doc_epsilon:  this.parameters.doc_epsilon / 100,
                tags_loot_epsilon:  this.parameters.tags_loot_epsilon / 10,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                publisher_model_ref: this.parameters.publisher_model_ref,
                doc_max_tokens: this.parameters.doc_max_tokens,
                w2c_dimensions: this.parameters.w2c_dimensions,
                w2c_model: this.parameters.w2c_model
            }

            let filter = (
                this.languageApiFilter() +
                this.countryApiFilter()
            );

            // reset deck storage
            localStorage.removeItem('clusters');

            try {
                let res = await this.$api.post("/profile/deck/fetch" + filter, payload);
                for (let c of res.data.clusters){
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        p.publication_softmax = dp.publication_softmax;
                        p.is_w2c_clustered = dp.is_w2c_clustered;
                        p.is_loot_clustered = dp.is_loot_clustered;
                        p.vector = dp.vector;
                        p.doc_length = dp.doc_length;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    // remove deck posts
                    delete c.deck_posts;
                    this.clusters.push(c);
                }
                // delay
                this.delay = Math.round(1000 * res.data.delay);
                // Max posts count for query
                this.max_posts_count = res.data.max_posts_count

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.new_loading = false;
            }
        },


        // used when:
        // - closing publisher
        // - reloading clusters from localStorage, we want to update to the latest state
        fetchUpdatedPosts: async function() {

            console.log("fetch updated posts")

            // need to rebuild post ids
            this.buildPostIds();
            
            // reset deck storage
            localStorage.removeItem('clusters');
            
            this.loading = true;
            this.error = null;
            
            let payload =  {
                ids: this.post_ids,
            }

            try {
                let res = await this.$api.post("/profile/deck/updated", payload);
                let updated_posts_map = res.data
                let updated_clusters = []
                for (let c of this.clusters) {
                    
                    // Create a deep copy of `c` to avoid modifying the original cluster
                    let updated_cluster = JSON.parse(JSON.stringify(c));
                    // reset posts
                    updated_cluster.posts = [];
                    
                    for (let p of c.posts) {
                        let up = updated_posts_map[p.id].post;
                        up.is_out = p.is_out;
                        up.is_community = p.is_community;
                        up.is_w2c_clustered = p.is_w2c_clustered;
                        up.is_loot_clustered = p.is_loot_clustered;
                        up.vector = p.vector;
                        up.doc_length = p.doc_length;
                        updated_cluster.posts.push(up)
                    }
                    updated_clusters.push(updated_cluster)
                }
                this.clusters = updated_clusters;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.loading = false;
            }
            console.log("clusters updated")

        },

        fetchLastPosts: async function () {
            console.log("fetch last posts");
            this.last_loading = true;
            this.error = null;

            let payload =  {
                ids: this.post_ids,
                hour: this.parameters.daily_batch_hour,
                doc_epsilon:  this.parameters.doc_epsilon / 100,
                tags_loot_epsilon:  this.parameters.tags_loot_epsilon / 10,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                publisher_model_ref: this.parameters.publisher_model_ref,
                doc_max_tokens: this.parameters.doc_max_tokens,
                w2c_dimensions: this.parameters.w2c_dimensions,
                w2c_model: this.parameters.w2c_model,
            }

            let filter = (
                this.languageApiFilter() +
                this.countryApiFilter()
            );

            try {
                let res = await this.$api.post("/profile/deck/fetch" + filter, payload);
                for (let c of res.data.clusters){
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    this.clusters.push(c);
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                if (data.message == "last empty") {
                    this.last_snackbar = true;
                } else {
                    this.error = data.message;
                }
            } finally {
                this.updateClusters();
                this.last_loading = false;
            }
        },

        // * Calendar function
        // when we are building a design cluster for editing a event in calendar or publishing a post from news
        // publisher dialog would be open
        // we only look for the posts since we have already the clusters' tree
        buildClusterFromPostIds: async function(pids) {
            console.log(pids)
            
            this.loading = true;
            this.error = null ;
            let cluster = {
                posts:[],
            }
            for (let id of pids) {
                try {
                    let res = await this.$api.get("/posts/" + id);
                    // the api returns an array
                    for (let p of res.data) {
                        cluster.posts.push(p)
                    }
                    
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                }
            }

            this.loading = false;
            this.publisher.cluster = cluster;
            this.publisher.cl_index = -1;
        },

       
        //* HEADER

        // Header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "?country=" + this.country
            }
            return ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            }
            return ""
        },

        //* PARAMETERS
        eventUpdateParameters(params) {
            console.log("eventUpdateParameters", params.continent)
            this.parameters = params;
        },


        //* DRAGGABLE

        // remove clusters if only one post left
        onDragEnd() {
            // Check if the dragged cluster has no posts left
            console.log("onDragEnd")
            
            // PATCH: only option doing the job
            // difficult to achieve same results with draggable...
            // get rid of cluster with empty posts
            let clusters = []
            for (let c of this.clusters) {
                if (c.posts.length > 0) {
                    clusters.push(c)
                }
            }  
            this.clusters = clusters
            
            this.updateClusters();
        },


        //* CLUSTERS

        // needs to return a promise to be followed by then() for the scrolling
        // as a result async func
        async patchClusters() {
            this.clusters = [];
            this.clusters = this.storage_clusters;
            // console.log("patch over")
        },

        // global updating
        updateClusters() {
            
            this.buildPostIds()
            
            // order by continent
            console.log("updating clusters")
            this.sortClusters()
            
            // deck storage
            localStorage.setItem('clusters', JSON.stringify(this.clusters));
            
        },

        // posts ids
        buildPostIds() {
            this.post_ids = []
            for (let c of this.clusters) {
                for (let p of c.posts) {
                    this.post_ids.push(p.id);
                }
            }
        },

        //- order clusters by continent
        sortClusters() {

            // by continent
            this.clusters = this.clusters.sort((a, b) => a.continent_order - b.continent_order);

            // by post content no html length
            this.clusters.forEach(
                cluster => {
                    cluster.posts = cluster.posts.sort((a, b) => {
                        // Remove HTML tags and count the words for both posts
                        let wordCountA = a.content.replace(/<[^>]+>/g, "").split(" ").length;
                        let wordCountB = b.content.replace(/<[^>]+>/g, "").split(" ").length;

                        // Sort by word count in descending order
                        return wordCountB - wordCountA;
                    });
                }
            );

        },

        // new cluster form one single post
        eventPostInNewCluster(cl_index, p_index, post) {
            console.log("eventPostInNewCluster",cl_index,p_index);
            if(this.clusters[cl_index].posts.length > 1) {
                // create a cluster following the exited one
                let new_cluster = {
                    posts: [post],
                    show: true,
                    is_out: this.parameters.see_out,
                    continent_name: this.clusters[cl_index].continent_name,
                    continent_order: this.clusters[cl_index].continent_order,
                    is_w2c_clustered: false,
                    is_loot_clustered: false,
                }

                new_cluster.posts[0].is_w2c_clustered = false;
                new_cluster.posts[0].is_loot_clustered = false;

                this.clusters.splice(cl_index+1, 0, new_cluster)

                // remove post from the old cluster
                this.clusters[cl_index].posts.splice(p_index,1)

                this.updateClusters();
            }
        },

        eventShowCluster(cl_index) {
            this.clusters[cl_index].show = !this.clusters[cl_index].show;
            this.updateClusters();
        },

        eventSwitchDeckOut(cl_index) {
            this.clusters[cl_index].is_out = !this.clusters[cl_index].is_out;
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                p.is_out = this.clusters[cl_index].is_out;
                posts.push(p);
            }
            this.clusters[cl_index].posts = posts;

            this.updateClusters();
        },

        eventChangeContinent(selected_continent, order, cl_index) {
            console.log("eventChangeContinent", selected_continent, order, cl_index);
            this.clusters[cl_index].continent_name = selected_continent;
            this.clusters[cl_index].continent_order = order;
            this.updateClusters();
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            console.log("eventRemoveTagFromCluster", cl_index, ref);
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let tags = [];
                for (let t of p.tags) {
                    if (t.ref != ref) {
                        tags.push(t)
                    }
                }
                p.tags = tags
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
            this.updateClusters();
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            console.log("eventRemoveLootFromCluster", cl_index, ref);
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let loot = [];
                for (let l of p.loot) {
                    if (l.tag_ref != ref) {
                        loot.push(l)
                    }
                }
                p.loot = loot
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
        },


        //* WIDGETS
        fetchTags: async function() {
            console.log("fetching tags");
    
            this.error = null;
            this.tags_list = [];
            try {
                let res = await this.$api.get("/tags");
                this.tags_list = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            }
        },
        
        //* COLORS
        sheetClusterColor(cl_index) {
            let c = ""
            
            for (let p of this.clusters[cl_index].posts) {
                if (p.is_error) {
                    c = "orange";
                    return c
                }
                if (p.is_published) {
                    c = "blue lighten-1";
                    return c
                }
                if (p.is_scheduled) {
                    c = "lime accent-2";
                    return c
                }
            }

            if(!this.clusters[cl_index].is_out) {
                c = "success";
            } else {
                c = "amber";
            } 
            return c
        },

        sheetPostColor(cl_index, p_index) {

            if (this.clusters[cl_index].posts[p_index]) {

                let p = this.clusters[cl_index].posts[p_index];

                if (p.is_error) {
                    return "orange"   
                }

                if (p.is_published) {
                    return "blue lighten-1"
                }

                if (p.is_scheduled) {
                    return "lime accent-2"
                }
             
            }  
            
            if (this.clusters[cl_index].is_out) {
                return "amber"
            } else {
                return "success"
            }
            
        },


        eventVote(cl_index, p_index) {
            console.log("eventVote", cl_index, p_index)
            if (this.clusters[cl_index].posts.length ==  1 ) {
                this.clusters.splice(cl_index,1)
            } else {
                this.clusters[cl_index].posts.splice(p_index,1);
            }

           this.updateClusters();
           this.eventCloseClusterPostDialog();
        },



        //* CLUSTERS
        createClusters: async function() {
            console.log("creating clusters");
            this.clusters_loading = true
            this.clusters_error = null;

            if (this.clusters.length > 0) {
                let payload = {
                    clusters: this.buildClustersPayload(),
                }


                try {
                    await this.$api.post("/clusters/deck_save", payload);
                
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.clusters_error = data.message;
                } finally {
                    this.clusters_loading = false
                }
            } else {
                this.clusters_error = "clusters length == 0"
            }

        },


        buildClustersPayload() {
            
            let payload = []
            for (let cl of this.clusters) {
                
                if (cl.is_out) {

                    let payload_cluster = {
                        excerpt: cl.excerpt,
                        post_ids: [],
                        img_urls: [],
                        tags: [],
                        loot: [],
                        earliest_post_date: null,
                        word2vec_vectors: [],  // hold all cluster's posts' word2vec vectors
                    }
    
                    // posts ids
                    let earliest = null
                    for (let p of cl.posts) {
                        payload_cluster.post_ids.push(p.id);
    
                        // Capture the earliest post date
                        if (!earliest || new Date(p.date) < new Date(earliest)) {
                            earliest = p.date;
                        }

                        // vectors
                        payload_cluster.word2vec_vectors.push(p.vector);
                    }
    
                    // img ids
                    payload_cluster.img_urls = this.buildClusterImgUrls(cl);
    
                    // tags
                    payload_cluster.tags = this.builClusterTags(cl);
    
                    // loot
                    payload_cluster.loot = this.buildClusterLoot(cl);
    
                    // earliest date pot
                    payload_cluster.earliest_post_date = earliest
    
                    payload.push(JSON.stringify(payload_cluster));
                }
            }

            return payload
        },

        buildClusterImgUrls(cl) {
            let cl_img_urls = [];
            if (cl.posts.length > 0) {
                let regex = /<img[^>]*src="([^"]*)"/g;
                for (let p of cl.posts) {
                    let match;
                    while ((match = regex.exec(p.content))) {
                        cl_img_urls.push(match[1]);
                    }
    
                    // from feeder
                    if (p.img_url  && p.img_url.length > 0) {
                        cl_img_urls.push(p.img_url)
                    }
    
                    // remove duplicates
                    cl_img_urls = [...new Set(cl_img_urls)];
                }
            }
            return cl_img_urls
        },

        builClusterTags(cl) {
            let tags = []
            if (cl.posts && cl.posts.length > 0) {
                for (let p of cl.posts) {
                    for (let t of p.tags) {
                       
                        // Check if the country already exists in the tags array
                        let existingTag = tags.find(c => c.ref === t.ref);
                        if (existingTag) {
                            // If found, increment the count
                            existingTag.count += 1;
                        } else {
                            // If not found, add it to the array with count = 1
                            tags.push({
                                ref: t.ref,
                                name: t.name,
                                count: 1,
                                channel: t.channel,
                            });
                        }
                        
                    }
                }
            }

            // Sort tags first by channel alphabetically, then by count in descending order
            tags.sort((a, b) => {
                // First compare by channel alphabetically
                if (a.channel < b.channel) return -1;
                if (a.channel > b.channel) return 1;

                // If channels are the same, then compare by count in descending order
                return b.count - a.count;
            });

            
            return tags
        },


        buildClusterLoot(cl) {
            let loot = []
            
            for (let p of cl.posts) {
                if (p.loot) {
                    for (let l of p.loot) {
                        
                        // Check if the loot already exists in the loot array by matching both tag_ref and value
                        let existingLoot = loot.find(c => c.ref === l.tag_ref && c.value === l.value);
                        
                        if (existingLoot) {
                            // If found, increment the count
                            existingLoot.count += 1;
                        } else {
                            // If not found, add it to the array with count = 1
                            loot.push({
                                tag_ref: l.tag_ref,
                                tag_name: l.tag_name,
                                value: l.value,
                                category: l.category,
                            });
                        }
                    }
                }
            }
            
            // Sort loot by count in descending order
            loot.sort((a, b) => b.count - a.count);
            
            return loot
        },


    },

    computed: {
        deckClustersCount() {
            return this.clusters.filter(c => !c.is_out).length;
        },
    }

}
</script>

<style>

</style>