<template>
    <div>
        <v-card outlined flat color="" class="ma-1" key="images-publication-main-card">
            <Draggable v-model="publication_img_urls" group="imgs"
                class="d-flex overflow-y-auto transparent"
                key="draggable"
                @change="onDragEnd"
            >
                <v-card flat v-for="(iu,i_index) of publication_img_urls" :key="'img-' + i_index" color="" width="280" >
                    <v-img 
                        size="140"
                        class="ml-2"
                        rounded
                        :src="iu"
                        contain
                        min-width="250"
                        height="160"
                        min-height="140"
                    ></v-img>           
                    
                </v-card>
            </Draggable>
        </v-card>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {

    name: 'ImagesPublication',

    components: {
        Draggable,
    },

    props: ['catalogue_selected_images_urls'],

    data: () => ({
        publication_img_urls:[],
    }),

    created() {
        this.publication_img_urls = this.catalogue_selected_images_urls;
        this.$emit("eventPublicationSelectedImages", this.publication_img_urls)
    },

    watch: {
        catalogue_selected_images_urls: {
            deep: true,
            immediate: true,
            handler() {
                this.publication_img_urls = this.catalogue_selected_images_urls;
                this.$emit("eventPublicationSelectedImages", this.publication_img_urls)
            },
        },
    },

    methods: {
        onDragEnd() {
            this.$emit("eventPublicationSelectedImages", this.publication_img_urls)
        }
    },

}
</script>

<style>

</style>