<template>
  <div>

    <v-tabs v-model="tab" centered background-color="transparent" key="deck-clusters-main-tabs">
        <v-tabs-slider :color="tabSliderColor"></v-tabs-slider>
        <v-tab @click="selectTab(0)" class="font-weight-bold green--text">DECK</v-tab>
        <v-tab @click="selectTab(1)" class="font-weight-bold grey--text">CLUSTERS</v-tab>
    </v-tabs>

    <v-card flat outlined key="deck-clusters-tabs-items">
        <v-tabs-items v-model="tab" :active-class="backgroundThemeSwitcher" class="tab-items">
            <v-tab-item key="deck" class="">
               <Deck />
            </v-tab-item>
            <v-tab-item key="clusters" class="">
               <Clusters />
            </v-tab-item>
        </v-tabs-items>
    </v-card>

  </div>
</template>

<script>
import deck from "@/common/deck";
import Deck from './DeckMain.vue'
import Clusters from './ClustersTab.vue'

export default {

    name: 'DeckClustersMain',

    components: {
        Deck,
        Clusters,
    },

    data: () => ({
        tab: null,
        tabColors: ["green","grey"],
    }),

    methods: {

        selectTab(value) {
            deck.setTab(value)
        },
    },


    computed: {
        tabSliderColor() {
            return this.tabColors[this.tab];
        },

        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return "active-items-dark"
            } else {
                return "active-items-light"
            }
        }
    
    }
}
</script>

<style>

</style>