<template>
    <div>
        <!-- error msg -->
        <span v-if="error && error != 'empty'" class="ml-5 error--text">API Error: {{ error }}</span>

        <v-card flat tile>
            <v-card-actions classs="py-0">
                
                <!-- today -->
                <v-btn
                    outlined
                    class="mr-4"
                    @click="setToday"
                    color=""
                >
                    Today
                </v-btn>

                <!-- time period select -->
                <v-select
                    v-model="type"
                    :items="types"
                    dense
                    outlined
                    hide-details
                    class="ma-2"
                    label="type"
                    color=""
                ></v-select>
                <v-spacer></v-spacer>

                <!-- reload -->
                <v-btn 
                    :loading="loading"
                    :disabled="loading"
                    icon
                >
                    <v-icon @click="reload" color="">mdi-reload</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <!-- search -->
                <v-text-field 
                    dense
                    outlined
                    v-model="searchQuery" 
                    label="Search"
                    class="ma-2"
                    hide-details
                    color=""
                    clearable
                ></v-text-field>
                <v-spacer></v-spacer>

                <!-- time navigation -->
                <v-btn 
                    x-small
                    fab
                    color=""
                    @click="prev"
                >
                    <v-icon color="">mdi-chevron-left</v-icon>
                </v-btn>
            
                <v-toolbar-title v-if="$refs.calendar" class="mx-2">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>

                <v-btn
                    x-small
                    fab
                    color=""
                    @click="next"
                >
                    <v-icon color="">mdi-chevron-right</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <!-- metrics -->
                Publications: {{events.length}}  <span v-if="type =='day'" :class="tweetCountColor(totalTweetsCount) + ' mx-1'">- tweets count: {{totalTweetsCount}}/50</span>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>



        <!-- Calendar -->
        <v-card flat tile class="pa-1">
            <v-calendar
                ref="calendar"
                v-model="focus"
                :weekdays="weekday"
                :events="filteredEvents"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="getEvents"
                :interval-format="intervalFormat"
                :key="calendarKey"
             
                color="blue"
            >
                <template v-slot:day="{ date }">
                    <span v-if="isValidDate(date)">
                        <span v-for="event in filteredEvents" :key="event.title">
                            <v-btn v-if="event.date === (date instanceof Date ? date.toISOString().substr(0, 10) : '')">
                                {{ event.text }}
                            </v-btn>
                        </span>
                    </span>
                </template>
            </v-calendar>
        </v-card>

        <!-- Event -->
        <v-menu v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
            <v-card
                v-if="selectedEvent"
                flat
                max-width="700px"
            >   
                <v-card tile flat :color="selectedEvent.color">
                    <v-card-title class="wordbreak py-2 mb-2 font-weight-bold text-body-1">
                        {{selectedEvent.name}}
                    </v-card-title>
                    <v-card-actions class="py-2 mb-2 text-body-2">
                        <v-btn rounded small depressed>Scheduled: {{formatDate(selectedEvent.scheduled_at)}} </v-btn>
                        <v-spacer></v-spacer>
                        thread count: {{selectedEvent.thread_count}} - status: {{selectedEvent.status}}
                        <v-spacer></v-spacer>
                        <v-btn rounded small depressed v-if="selectedEvent.status == 'published'">P: {{formatDate(selectedEvent.published_at)}}</v-btn>
                        <v-spacer></v-spacer>
                       
                        <v-icon @click="goPublisher(selectedEvent)">mdi-pencil</v-icon>
                        <v-icon @click="selectedOpen = false" class="mx-2">mdi-close-circle</v-icon>
                
                    </v-card-actions>
                </v-card>
              
                <v-card-subtitle class="py-0"> [{{selectedEvent.targets}}]
                    <span v-if="selectedEvent.publication_id"> ·  pb id: {{selectedEvent.publication_id}}</span>
                    <span v-if="selectedEvent.post_ids"> ·  cluster posts count: {{selectedEvent.post_ids.length}}</span>
                    <span v-else> ·  postid: {{selectedEvent.post_id}}</span>
                    
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-subtitle class="py-0">CreatedAt: {{formatDate(selectedEvent.created_at)}} · UpdatedAt: {{formatDate(selectedEvent.updated_at)}} <span v-if="selectedEvent.purged_at !='0001-01-01T00:00:00Z'">· PurgedAt: {{formatDate(selectedEvent.purged_at)}} </span></v-card-subtitle>
                <v-divider class="mb-2"></v-divider>

                <span v-for="(img,index) in selectedEvent.imgs" :key="index">
                    <v-card-actions class="py-0">
                        <v-spacer></v-spacer>
                        <v-img :src="img" max-width="250" ></v-img>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </span>
                <v-card-subtitle class="font-weight-bold py-1"><span>{{selectedEvent.tags}}</span></v-card-subtitle>
                <v-card-text>
                    <span v-html="selectedEvent.text"></span>
                </v-card-text>
                
               
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Calendar',

    data: () => ({
        // calendar
        focus: '',
        type: 'week',
        types: ['month', 'week', 'day'],
        
        // in order to rerender updates
        calendarKey: 0,
        reloadTimer: null, // Variable to store the interval ID
        
        weekday: [1, 2, 3, 4, 5, 6, 0],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        updateMode: false,

        loading: false,
        error: null,
        events: [],

        totalTweetsCount: 0,

        // search
        searchQuery: "",
    }),

    created() {
        // store
        this.focus = this.focusStore;
        this.type = this.typeStore;
        //* Timer
        this.startReloadTimer();
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },

    beforeDestroy() {
       // Clear the interval and remove event listener when the component is destroyed
        this.clearReloadTimer();
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },

    watch: {
        focus: {
            handler() {
                this.updateFocus(this.focus)
            }
        },

        type: {
            handler() {
                this.updateType(this.type)
            }
        },

    },

    methods: {

        ...mapActions(['updateFocus','updateType']),

        getEvents: async function({ start, end }) {
            console.log("fetching events")
            this.error = null;
            this.loading = true;
            this.events = [];

            let payload = {
                start: start.date,
                end: end.date,
            }

            try {
                let events = [];
                this.totalTweetsCount = 0;
                let res = await this.$api.post("/publisher/events", payload);
                for (let e of res.data) {
                    e.start = new Date(e.start);
                    e.end = new Date(e.end);
                    e.color = this.getEventColor(e)
                    if (e.imgs.length > 0 ) {
                        e.imgs = e.imgs.split(",");
                    }
                    e.tags = e.tags.replaceAll(",#"," #");
                    events.push(e);
                    this.totalTweetsCount += e.thread_count;
                }
                this.events = events;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }

        },

        create() {
            console.log("new publication");
            this.$router.push({path: "/main/framer/new"})
        },

        viewDay ({ date }) {
            this.focus = date
            this.type = 'day'
        },

        setToday () {
            this.focus = ''
        },


        //* TIMER

        reload() {
            this.calendarKey ++;
        },

        startReloadTimer() {
            this.reloadTimer =  setInterval(
            () => {
                this.reload();
            },
            1 * 60 * 1000); // 1 minute in milliseconds
        },

        clearReloadTimer() {
            if (this.reloadTimer) {
                clearInterval(this.reloadTimer);
                this.reloadTimer = null;
            }
        },

        handleVisibilityChange() {
            if (document.hidden) {
                this.clearReloadTimer();
            } else {
                this.startReloadTimer();
            }
        },
      
        prev () {
            this.$refs.calendar.prev()
        },

        next () {
            this.$refs.calendar.next()
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        getEventColor(event) {
            let successArr = event.success === "" ? [] : event.success.split(",");
            let targetsArr = event.targets === "" ? [] : event.targets.split(",");
            let d = targetsArr.length - successArr.length
            switch (event.status) {
                case "published":
                    return "blue"
        
                case "scheduled":
                    return "green lighten-2"
                case "draft":
                   return "grey"
                   
                case "error":
                    switch (d) {
                        case 1:
                            return "warning"
                        case 2: 
                            return "warning darken-4"
                    }
                    return "error"
                  
                case "purged":
                    return "blue-grey"
                case "purge error":
                    return "purple accent-1"
                   
            }
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD@HH:mm");
        },

        isValidDate(date) {
            return date instanceof Date && !isNaN(date);
        },

        goPublisher(event) {
            // console.log("event pid=", event.post_id)
            this.selectedOpen = false
            this.$router.push({
                name: "deck",
                params: {
                    post_id: event.post_id,
                    post_ids: event.post_ids,
                }
            })
        },

        intervalFormat(interval) {
            return interval.time
        },

        tweetCountColor(count) {
            if (count >= 50 && this.type == "day") {
               return "error--text"
            }
            if (count > 45 && this.type == "day") {
                return "warning--text"
            }
            return ""
        },


    },  

    computed: {

        ...mapGetters({
            focusStore: 'getFocus',
            typeStore: 'getType',
        }),


        filteredEvents() {

            if (!this.searchQuery) {
                return this.events;
            }

            return this.events.filter( event => {
                // Filter based on event text containing the search query
                return event.text.toLowerCase().includes(this.searchQuery.toLowerCase());
                
            });
        }
    }

}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>