const state = {
    countries: null,
    user_pkg_countries: null,
};

const getters = {
    getCountries() {
        return state.countries;
    },

    getUserPkgCountries() {
        return state.user_pkg_countries;
    },
};

const actions = {
    updateCountries: function({ commit }, obj) {
        commit('setCountries', obj);
    },

    updateUserPkgCountries: function({ commit }, obj) {
        commit('setUserPkgCountries', obj);
    },
};

const mutations = {
    setCountries: function(state, obj) {
        state.countries = obj;
    },

    setUserPkgCountries: function(state,obj) {
        state.user_pkg_countries = obj;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
