const state = {
    trans_settings: {},
};


const getters = {
    getTransSettings() {
        return state.trans_settings;
    },
};

const actions = {
    // called in common/app
    updateTransSettings: function({ commit }, sets) {
        commit('setTransSettings', sets);
    },

    updateTranslationOn: function({ commit }, {language, output_language}) {
        commit('setTranslationOn', {language, output_language});
    },

    updateTranslationOff: function({ commit }, language) {
        commit('setTranslationOff', language);
    },
};


const mutations = {
   
    setTransSettings: function(state, sets) {
        state.trans_settings = sets;
    },

    setTranslationOn: function(state, {language, output_language}) {
        if (state.trans_settings[language].available.length > 0) {
            for (let l of state.trans_settings[language].available) {
                if (l.iso == output_language) {
                    l.trans_active = true;
                } else {
                    l.trans_active = false
                }
            }
        }
    },

    setTranslationOff: function(state,language) {
        if (state.trans_settings[language].available.length > 0) {
            for (let l of state.trans_settings[language].available) {
                l.trans_active = false
            }
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

