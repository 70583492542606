<template>  
    <div>
        <v-footer
        app
        height="32"
        :class="!$rbac.is_user_logged_in() ?'gradient' : ''"
        color="Dialog"
        >
            <v-card flat class="ml-auto mr-auto"  color="transparent">
                <v-card-text v-if="!$rbac.is_user_logged_in()" class="py-0 text-center Dialog--text">©{{ new Date().getFullYear() }} {{$settings.site}}</v-card-text>
                <v-card-text v-else class="py-0 text-center accent--text">©{{ new Date().getFullYear() }} {{$settings.site}}</v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>

<script>

export default {
    name: 'Footer',
}
</script>

<style lang="scss" scoped>
.gradient {
    background-image: linear-gradient(40deg, var(--v-accent-base) 50%, var(--v-Dialog-base) 90%);
}
</style>
