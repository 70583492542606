<template>
    <div>
       
        <v-container v-if="source" fluid>
            <v-row>     
                <v-col md="5" lg="3">
                    
                    <!-- ADD CARDS -->
                    <v-sheet rounded outlined color="accent" key="cards-title-sheet">
                        <v-card flat key="cards-title-card">
                            <v-card-actions class="pb-0">
                                <v-spacer></v-spacer>
                                <span class="text-h6 font-weight-bold mr-5">CARDS</span>
                                <span class="font-weight-bold">{{ cards.length }}</span>
                                <v-btn small @click="addCard" class="ml-5" color=""
                                    ><v-icon small>mdi-plus</v-icon></v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                            <v-card-subtitle class="pb-0">p,img,ul,h1,h2,h3,h4,h5,h6</v-card-subtitle>
                            <v-card-subtitle class="py-0">blockquote,span,em,strong</v-card-subtitle>

                            <v-card-text class="pt-0">
                                <!-- Chrome dp -->
                                <v-switch v-model="post_source.poster_is_chromedp" color="accent" label="Chromedp ?" hide-details=""></v-switch>
                                <!-- interceptors -->
                                <v-checkbox
                                    v-model ="source.has_post_interceptor"
                                    label="interceptor ?"
                                    hide-details
                                    color="warning"
                                >
                                    <template v-slot:label>
                                        <div><strong class="warning--text">Poster Interceptor ?</strong></div>
                                    </template>
                                </v-checkbox>
                            </v-card-text>
                        </v-card>

                    </v-sheet>

                    <!-- Chromedp mode ? -->
                    <v-sheet v-if="post_source.poster_is_chromedp" rounded outlined color="accent" key="cards-chromedp-sheet" class="mt-2">
                        <v-card flat key="cards-chromedp-card">
                            <v-card-text>
                                <!-- chromedper time out -->
                                <v-text-field 
                                    outlined
                                    dense
                                    v-model.number="post_source.poster_chromedp_time_out"
                                    label="Time out in sec."
                                    :rules="[]"
                                    required
                                    hides-details
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="post_source.poster_chromedp_target"
                                    label="CSS Body target #id .class"
                                    :rules="[]"
                                    hide-details=""
                                ></v-text-field>
                                <v-card-actions class="pb-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        x-small
                                        @click="eventSaveSource"
                                        class="mr-5 py-0 Dialog--text font-weight-bold"
                                        color="accent"
                                    >save</v-btn>                                    
                                </v-card-actions>
                            </v-card-text>
                        </v-card>
                    </v-sheet>
                    
                    <!-- Cards -->
                    <!--<draggable group="cards" key="draggable-cards">-->
                        <PostCard
                            v-for="(_, index) in post_cards"
                            :key="'card - ' + index"
                            :card="post_cards[index]"
                            :index="index"
                            :totalCards="post_cards.length"
                            @update="updateCard"
                            @delete="deleteCard"
                            @cardup="moveCardUp"
                            @carddown="moveCardDown"
                            class="my-2"
                        />
                    <!-- </draggable> -->
                </v-col>

                <v-col md="7" lg="9">
                    <PosterTest 
                        :feeds="feeds"
                        :source="addCards()"
                    />
                </v-col>
            </v-row>
         </v-container>

    </div>
</template>

<script>
import { Model } from "@/common/model";
//import Draggable from 'vuedraggable'
import PostCard from './PostCard'
import PosterTest from "./PosterTest";

// Card CLASS
class Card extends Model {
    id = null;
    source_id = null;
    target = null;
    selectors = null;
    insert_in_img_url = null;
    exclusion_strings = null;
    exclusion_selectors = null;
}

export default {

    name: 'PosterTab',

    props: ['source','cards','feeds'],

    components: {
        //Draggable,
        PostCard,
        PosterTest,
    },

    watch:{
        
        source: {
            deep: true,
            immediate: true,
            handler() {
                this.post_source = this.source;
            }
        },

        cards: {
            deep: true,
            immediate: true,
            handler() {
                this.post_cards = this.cards;
            }
        }
    },

    data: () => ({
        post_source: null,
        post_cards: null,
    }),

    methods: {

        //* CARDS
        addCard() {
            let card = Card.init();
            this.post_cards.push(card);
        },

        updateCard(card, index) {
            this.post_cards[index] = card;
            this.eventSaveSource();
        },

        deleteCard(card, index) {
            console.log("delete: ", card.id, index);
            if (this.post_cards.length > 0) {
                this.post_cards.splice(index, 1);
                this.eventSaveSource();
            }
        },

        moveCardUp(index) {
            let movingCard = this.post_cards.splice(index, 1)[0];
            console.log(movingCard)
            this.post_cards.splice(index - 1, 0, movingCard);
        },

        moveCardDown(index) {
            let movingCard = this.post_cards.splice(index, 1)[0];
            this.post_cards.splice(index + 1, 0, movingCard);
        },

        // ATTACH CARDS TO SOURCE
        addCards() {
            if (this.post_cards.length > 0) {
                let cards_payload = [];
                for (let card of this.post_cards) {
                    // we need to delete card.Id
                    // if not the DB reattached the same cards with same ID in db
                    // meaning the same order for cards
                    // as a result we cant move up cards
                    delete card.id;
                    cards_payload.push(JSON.stringify(card));
                }
                this.post_source.cards_payload = cards_payload;
            }
            return this.post_source
        },

        //* EVENTS
        eventSaveSource() {
            this.$emit("eventSaveSource", this.post_source);
        },

    },



}
</script>

<style>

</style>