<template>
    <div>
        <v-card flat tile class="d-flex flex-column align-center justify-start py-1" width="65" color="transparent">
            <v-icon v-if="post.is_published || post.is_scheduled || post.is_draft" @click="goPublisher" :color="iconColor">mdi-alpha-s-circle</v-icon>
            <v-icon v-else @click="goPublisher" class="" color="lime">mdi-publish</v-icon>
            <v-card-subtitle class="text-caption lime--text py-0 mt-2"><span v-if="imgsCount >0">{{ imgsCount }}/</span>{{ wordsCount }}</v-card-subtitle>
        </v-card>
    </div>
</template>

<script>

export default {

    name: "PublisherWidget",

    props: ['post'],

    methods: {

        goPublisher() {
            this.$emit("eventGoPublisher", this.post.id)
        },
    },

    computed: {
        
        iconColor() {
            if (this.post.is_published) {
                return "blue"
            }
            if (this.post.is_scheduled && !this.post.is_published) {
                return "lime"
            }
            if (this.post.is_draft && !this.post.is_scheduled && !this.post.is_published) {
                return "grey"
            }
            
           return "warning"
        },

        imgsCount() {
            let imgsUrls = [];

            // from content
            let regex = /<img.*?src="(.*?)".*?>/g;
            let match;
            while ((match = regex.exec(this.post.content))) {
                imgsUrls.push(match[1]);
            }

            // from feeder
            if (this.post.img_url.length > 0) {
                imgsUrls.push(this.post.img_url)
            }

            // remove duplicates
            imgsUrls = [...new Set(imgsUrls)];
            return imgsUrls.length
        },

        wordsCount() {
            let noHtml = this.post.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },
    }

}
</script>

<style>

</style>