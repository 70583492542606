<template>
    <div>

        <v-card flat color="Background">
            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="poolColor"
                :active="loading">
            </v-progress-linear>

            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
            
             <!-- no post msg -->
            <v-card v-if="error == 'empty'" flat color="transparent">
                <v-card-text class="text-center">{{$i18n.t("noMorePost")}}</v-card-text>
            </v-card>

            <!-- Pagination -->
            <div v-if="total > options.itemsPerPage" class="text-center my-3">
                <v-pagination
                v-model="page"
                @input="inputPage"
                :length="paginationLength"
                total-visible="7"
                :color="poolColor"
                circle>
                </v-pagination> 
            </div>

            <v-sheet 
            outlined rounded 
            v-for="(post, index) in posts" :key="'post_'+post.id" 
            class="my-2 mx-3" :color="poolColor">
                <v-card flat class="d-flex">
                    <!-- Widgets -->
                    <v-card class="mt-5" color="transparent">
                        <PoolWidget 
                            :post="post" :index="index" position="vertical"
                            @eventPostRemoveFromPool="eventPostRemoveFromPool" 
                            class=""
                        ></PoolWidget>
                    </v-card>
                    <!-- Post -->
                    <Post 
                        :post="post" :index="index" tab="pool"
                        @eventGoPost="eventGoPost" 
                        :key='"p" + post.id'
                    ></Post>
                </v-card>
            </v-sheet>

            <!-- DIALOG SINGLE -->
            <v-dialog 
                v-model="dialogSingle"
                fullscreen
                key="pool-dialog-single"
            >   
                <!-- v-card needed for triggering fullscreen-->    
                <v-card>  
                    <Single 
                        :single="dialogSinglePost" 
                        :index="dialogSingleIndex" 
                        :colorView="poolColor"
                        @eventPostRemoveFromPool="eventPostRemoveFromPool"
                        @eventCloseSingleDialog="eventCloseSingleDialog"
                    ></Single>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import Post from '../components/Post'
import PoolWidget from './SubscriberPoolWidget'
import Single from './SubscriberDialogSingle'

export default {

    name: 'Pool',

    data: () => ({
        loading: false,
        error: null,
        posts: [],

        // dialog single
        dialogSingle: false,
        dialogSinglePost: null,
        dialogSingleIndex: null,

        poolColor: "blue lighten-1",
        
        //* PAGING
        total: null,
        page: 1,
        paginationLength: null,
        options: {
            itemsPerPage: 15,
        },
    }),

    props:['propMainPostAddedInPool','propMainPostRemoveFromPool'],

    components: {
        Post,
        PoolWidget,
        Single,
    },

    created() {
        this.fetchPool();
    },

    watch: {
        propMainPostAddedInPool: {
            deep: true,
            immediate: true,
            handler() {
                this.fetchPool()
            }
        },

        propMainPostRemoveFromPool: {
            deep: true,
            immediate: true,
            handler() {
                console.log("up propMainPostRemoveFromPool")
                this.fetchPool()
            }
        },
    },

    methods: {

        fetchPool: async function() {
            this.loading = true
            this.error = null;
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.page - 1)
            );

            try {
                let res = await this.$api.get("/profile/subscriber/pool" + filter);
                this.posts = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
                this.$emit("eventPoolTotal", this.total)    
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
                if (this.error == "empty") {
                    this.posts = [];
                    this.total = null;
                }
            } finally {
                this.loading = false;
            }
        },
        
        //* NAVIGATION
        inputPage(pg) {
            this.page = pg;
            this.fetchPool();
        },

        //* SINGLE
        eventGoPost(index) {
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index]
            this.dialogSingleIndex = index;
        },

        eventCloseSingleDialog(){
            this.dialogSingle = false;
        },

        //* COMMUNICATION

        // no need to send index to main
        // index is for this view
        eventPostRemoveFromPool(id,index) {
            this.posts.splice(index,1);
            this.$emit("eventViewPostRemoveFromPool", id)
        },

    },
}
</script>

<style>

</style>