<template>
    <div>
            <v-expansion-panels flat>
                <v-expansion-panel >
                    <v-expansion-panel-header>
                        <v-card outlined>
                            <v-card-text class="font-weight-bold py-1 purple--text">Translation Sets</v-card-text>
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card outlined class="d-flex flex-wrap">
                            <v-card flat v-for="(ts) in trans_settings" :key="ts.language">
                                <v-card-text v-if="ts.available.length > 0">
                                    <!-- language name to be tranlsated -->
                                    <v-btn 
                                        value="off"
                                        rounded 
                                        x-small 
                                        class="font-weight-bold mr-3" color="purple">
                                        {{ts.name}}
                                    </v-btn>
                        
                                    <!-- languages options -->
                                    <v-btn-toggle v-model="toggle" dense :key="toggle[ts.language]">
                                        <v-btn 
                                            x-small 
                                            value="off"
                                            @click="translationOff(ts.language)"
                                            class="font-weight-bold"
                                            >
                                                OFF
                                        </v-btn>
                                        <v-btn 
                                            v-for="lang in ts.available" 
                                            :key="lang.iso"
                                            :value="lang.iso"
                                            @click="translationOn(ts.language,lang.iso)"
                                            x-small
                                            class="font-weight-bold "
                                        >
                                            {{lang.name}}
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    
    name: 'TranslationsSelect',

    data: () => ({
        toggle: [],
    }),

    watch: {
        trans_settings:  {
            deep: true,
            immediate: true,
            handler() {
                for (let key in this.trans_settings) {
                    let langArray = this.trans_settings[key].available
                    if (langArray.length > 0) {
                        
                        let trans_active = false;
                        
                        for (let l of langArray) {
                            if (l.trans_active) {
                                this.toggle = l.iso;
                                trans_active = true;
                            }
                        }
                        
                        if (!trans_active) {
                            this.toggle = "off";
                        }
                    }
                }
            }
        },
    },

    methods: {

        ...mapActions(['updateTranslationOn','updateTranslationOff']),

        translationOff(language) {
            this.updateTranslationOff(language);
        },

        translationOn(language, output_language) {
            this.updateTranslationOn({language, output_language});
        },
        
    },

    computed: {
        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),
    },
}
</script>

<style>

</style>