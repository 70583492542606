<template>
    <div>
        <v-card flat outlined>
            
            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="color"
                :active="loading">
            </v-progress-linear>

            <v-card-actions class="py-1">
                <v-spacer></v-spacer>
                <v-btn x-small @click="fetchFeeds" class="Dialog--text font-weight-bold"  :color="color">FETCH FEEDS</v-btn>
                <v-spacer></v-spacer>
                {{not_in_count}} new / {{total}} feeds
                <v-spacer></v-spacer>
                <v-switch v-model="hide_already_in" label='hide already in' :color="color"></v-switch>
                <v-spacer></v-spacer>
                <v-switch v-model="fetch_and_translate_title" label='translate title when fetching' :color="color"></v-switch>
                <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-subtitle class="py-0">=> Filtered out feeds are not part of the server response</v-card-subtitle>
                
            <v-divider></v-divider>
            
            <!-- error msg -->
            <v-card-text v-if="error" class="error--text py-1 mb-2 text-center">{{ error }}</v-card-text>

            <!--issues msg -->
            <v-card-text v-if="issues" class="warning--text py-1 mb-2 text-center">{{ issues }}</v-card-text> 

            <v-card v-for="(item, index) in feeds" :key="index" flat outlined class="d-flex mt-1 mx-1 mb-3">
                <v-sheet  tile outlined :color="item.exists?'light-blue accent-1':'orange accent-2'" width="100%" class="d-flex">
                    <v-card v-if="showFeed(item)" max-width="50" class="pa-2" flat tile color="">
                        <v-btn x-small outlined class="font-weight-bold text-body-2">#{{index}}</v-btn> 
                        <v-btn x-small fab class="mt-2 ml-1" @click="goArticle(item.feed.url)">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </v-card>
                    <v-card v-if="showFeed(item)" color="" flat tile class="d-flex" width="100%">
                        <v-avatar v-if="item.feed.img_url && item.feed.img_url != 'no img found' && item.feed.img_url.length > 0 && !item.feed.imgError" 
                            tile
                            size="100"
                        >
                            <v-img  
                                :src="item.feed.img_url"
                                @error="item.feed.imgError = true"
                            ></v-img>
                        </v-avatar>
                        <v-icon v-if="item.feed.imgError" color="error">mdi-image-broken-variant</v-icon>
                            <v-card flat tile color="" width="100%">
                                <!-- url -->
                                <v-card-subtitle class="py-0">{{item.feed.url}}</v-card-subtitle>
                                <!-- title -->
                                <v-card-title class="text-body-1 wordbreak pt-1">{{item.feed.title}}</v-card-title>
                                <v-card-subtitle v-if="item.trans_title.length > 0" class="font-weight-bold amber--text wordbreak py-0">{{item.trans_title}}</v-card-subtitle>
                                <!-- cat -->
                                <v-card-subtitle v-if="item.feed.categories.length > 0" class="wordbreak warning--text pt-1"><strong>Categories:</strong>{{item.feed.categories}}</v-card-subtitle>
                                <!-- desc -->
                                <v-card-subtitle class="py-0">{{item.feed.description}}</v-card-subtitle>
                                <v-card-subtitle v-if="item.trans_description.length > 0" class="py-0 amber--text">{{item.trans_description}}</v-card-subtitle>
                                <!-- date -->
                                <v-card-subtitle class="success--text py-1">{{item.feed.date}} [string: "{{item.date_string}}"]</v-card-subtitle>
                            </v-card>
                            <v-card v-if="!item.exists" flat tile color="" width="55">
                                <v-card-text class="py-0">
                                    <v-checkbox dense class="ml-1" :color="color" v-model="selectedFeeds" :value="index" hide-details=""></v-checkbox>
                                    <v-btn x-small depressed :loading="feed_upload_loading && feed_upload_loading_index === index" :disabled="feed_upload_loading && feed_upload_loading_index === index" fab @click="uploadFeed(index)" class="mt-2" ><v-icon :color="color">mdi-upload</v-icon></v-btn>
                                    <v-btn x-small depressed fab @click="translate(index)" class="mt-2 mb-2"><v-icon :color="color">mdi-translate</v-icon></v-btn>
                                </v-card-text>
                            </v-card>
                    </v-card>
                </v-sheet>
            </v-card>

            <v-card-actions v-if="not_in_count > 0">
                <v-spacer></v-spacer>
                <v-spacer><v-btn small :color="color" class="Dialog--text font-weight-bold" @click="uploadAllFeeds">UPLOAD ALL FEEDS TO ES-DS-FEEDS</v-btn></v-spacer>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'SpecialOpsFeedsTest',

    props: ['sop'],

    data: () => ({
        feeds: null,
        loading: false,
        total: 0,
        error: null,
        issues: null,
        selectedFeeds: [],
        color: "orange accent-2",
        hide_already_in: true,
        not_in_count: 0,
        fetch_and_translate_title: false,
        feed_upload_loading: false,
        feed_upload_loading_index: null,
    }),

     created() {
        if (this.sop.target_url > 0) {
            this.fetchFeeds();
        }
    },


    methods: {

        fetchFeeds: async function () {

            this.loading = true;
            this.feeds = null;
            this.total = 0;
            this.error = null;
            this.issues = null;

            // fetch and translate title
            this.sop.fetch_and_translate_title = this.fetch_and_translate_title;

            try {
                let res = await this.$api.post("/special_ops/feeds", this.sop);
                if (res.data.length > 0) {
                    this.feeds = res.data;
                    this.total = parseInt(res.headers["x-paginated-items"]);
                    this.$emit("eventSoFeeds", this.feeds);
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                if (this.feeds && this.feeds.length > 0) {
                    this.issues = this.feeds[0].issues
                    this.$emit("eventSoFeeds", this.feeds)
                    this.notInCount();
                }
                this.loading = false;
            }

        },

        uploadFeed: async function(index) {
            console.log("uploading feed:", index);
            this.feed_upload_loading = true;
            this.feed_upload_loading_index = index;
            this.error = null;
            try {
                await this.$api.post("/special_ops/upload/feed", this.feeds[index].feed);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.feeds[index].exists = true
                this.not_in_count ++
                this.feed_upload_loading = false;
                this.feed_upload_loading_index = null;
            }
        },

        uploadAllFeeds() {
            for (let i = 0; i <= (this.feeds.length -1) ; i++) {
                console.log(this.feeds[i].feed.url)
                this.uploadFeed(i);
            }
        },

        showFeed(item) {
            if (item.exists && this.hide_already_in) {
                return false;
            } else {
                return true;
            }
        },

        notInCount() {
            this.not_in_count = 0;
            for (let f of this.feeds) {
                if (!f.exists) {
                    this.not_in_count ++;
                }
            }
        },

        goArticle(url) {
            window.open(url, '_blank');
        },

        selectFeed(index) {
            console.log(index)
        },  

        translate: async function(index) {
            console.log("translate", index)

            this.loading = true;
            this.error = null;

            let payload = {
                "title": this.feeds[index].feed.title,
                "description": this.feeds[index].feed.description,
                "language": this.sop.language,
            }

            try {
                let res = await this.$api.post("/special_ops/translate/feed", payload);
                if (res.data) {
                   this.feeds[index].trans_title = res.data.trans_title;
                   this.feeds[index].trans_description = res.data.trans_description;
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>

<style>

</style>