const state = {
   currencies: null,
};

const getters = {
    getCurrencies() {
        return state.currencies;
    },
};

const actions = {
    updateCurrencies: function({ commit }, obj) {
        commit('setCurrencies', obj);
    },
};

const mutations = {
    setCurrencies: function(state, obj) {
        state.currencies = obj;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};