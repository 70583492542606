<template>
    <div>
        <v-card flat color="Background" key="law-check-view">

            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="tabColor"
                :active="loading">
            </v-progress-linear>

            <Header 
                :languageMain="language"
                :countryMain="country"
                @eventLanguage="eventLanguage" 
                @eventCountry="eventCountry"
                @eventReload="eventReload"
                stream="special_ops"
                :color="tabColor"
                class="my-2"
            ></Header>

            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
        
            <!-- no post msg -->
            <v-card outlined v-if="error == 'empty' || remaining == 0" flat class="ma-2">
                <v-card-text class="text-center light-blue--text text--lighten-2">{{$i18n.t("noMorePost")}}</v-card-text>
            </v-card>

            <!-- common frame -->
            <Frame
                :tab="tab"
                :frameColor="tabColor"
                :posts="posts"
                :tabDialogSingle="tabDialogSingle"
                :tabDialogSingleIndex="tabDialogSingleIndex"
                :tabDialogSinglePost="tabDialogSinglePost"
                @eventVote="eventVote"
                @eventGoTags="eventGoTags"
                @eventGoLoot="eventGoLoot"
                @eventGoCorpus="eventGoCorpus"
                @eventGoMiner="eventGoMiner"
                @eventOpenSingleDialog="eventOpenSingleDialog"
                @eventCloseSingleDialog="eventCloseSingleDialog"
            ></Frame>
        </v-card>

    </div>
</template>

<script>
import trainer from "@/common/trainer";
import Header from '../components/HeaderLangCountry'
import Frame from './TrainerFrame.vue'

export default {

    name: 'SpecialOps',

    components: {
        Header,
        Frame,
    },

    data: () => ({
        
        // setup
        loading: false,
        error: null,
        tab: "so",
        tabColor: "light-blue accent-1",

        // header
        language: null,
        country: null,
        remaining: 0,
        stashRemaining: 0,
        
        // paging
        page: 0,
        options: {
            itemsPerPage: 50,
        },

        // data
        posts: [],
        stash: [],

        // scrolling
        scrolling: 0,

        // tab single
        tabDialogSingle: false,
        tabDialogSingleIndex: 0,
        tabDialogSinglePost: null,

        // in order to fetch when changing tabs
        // only if have been created already
        hasBeenCreated: false,
    }),

    props: ['tabNumber'],

    created() {
        // init when back from single
        this.language = trainer.retriveSpecialOpsLanguage();
        this.country = trainer.retriveSpecialOpsCountry();
        this.tabDialogSingle = trainer.retriveSpecialOpsDialog();
        this.tabDialogSingleIndex = trainer.retriveSpecialOpsIndex();
        //  displaying the old single post before getting updates in tags/loot
        this.tabDialogSinglePost = trainer.retriveSpecialOpsSingle()
        this.scrolling = trainer.retriveSpecialOpsScroll();
        this.fetchSpecialOpsPosts()
        .then(() => { 
            this.tabDialogSinglePost = this.posts[this.tabDialogSingleIndex]
        })
        .then( () => {
            if (!this.tabDialogSingle) {
                window.scrollTo(0, this.scrolling);
            }
            this.hasBeenCreated = true;
        });
    },

    watch: {
        tabNumber: {
            deep: true,
            immediate: true,
            handler() {
                if (this.tabNumber == 4 && this.hasBeenCreated) {
                    this.posts=[];
                    this.fetchSpecialOpsPosts();
                }
            }
        }
    },

    methods: {

        fetchSpecialOpsPosts: async function() {
            console.log("fetch special ops posts")
            this.loading = true
            this.error = null;
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + this.page + 
                this.languageApiFilter() + 
                this.countryApiFilter() 
            );

            try {
                let res = await this.$api.get("/profile/special_ops/list" + filter);
                for (let p of res.data) {
                    this.posts.push(p);
                }
                this.remaining = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
                this.remaining = 0;
            } finally {
                this.$emit("eventSpecialOpsRemaining", this.remaining)
                this.loading = false;
            }
        },


        //* HEADER

        // Header Events
        eventLanguage(lg) {
            trainer.setSpecialOpsLanguage(lg);
            this.language = lg;
            this.posts = [];
            this.stash = [];
            this.fetchSpecialOpsPosts();
        },

        eventCountry(country) {
            trainer.setSpecialOpsCountry(country);
            this.country = country;
            this.posts = [];
            this.stash = [];
            this.fetchSpecialOpsPosts();
        },

        eventReload() {
            this.posts = [];
            this.fetchSpecialOpsPosts();
        },

        // header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "&country=" + this.country
            }
            return  ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            } 
            return ""
        },

        //* FRAME

        // Events

        // Vote
        eventVote(...[,index,stream]) {
            this.posts.splice(index,1)
            this.remaining --;
            this.$emit("eventSpecialOpsRemaining", this.remaining)
            if (stream == 'single') {
                this.eventCloseSingleDialog();
            }
        },

        //* Go tools Tags
        eventGoTags(index) {
            trainer.setSpecialOpsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

        //* Go tools Loot
        eventGoLoot(index) {
            trainer.setSpecialOpsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        // Go tools Corpus
        eventGoCorpus(index) {
            trainer.setSpecialOpsScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_corpus_tab/" + this.posts[index].id})
        },

        // Go Miner
        eventGoMiner(index) {
            trainer.setSpecialOpsScroll(window.scrollY);
            this.$router.push({path: "/main/miner_prompt/" + this.posts[index].id})
        },

        //* SINGLE
        eventOpenSingleDialog(index) {
            trainer.setSpecialOpsDialog(true);
            trainer.setSpecialOpsSingle(this.posts[index]);
            trainer.setSpecialOpsIndex(index);
            trainer.setSpecialOpsScroll(window.scrollY);
            this.scrolling = window.scrollY;
            this.tabDialogSingle = true;
            this.tabDialogSingleIndex = index;
            this.tabDialogSinglePost = this.posts[index];
        },

        eventCloseSingleDialog(){
            trainer.setSpecialOpsDialog(false);
            trainer.setSpecialOpsSingle(null);
            trainer.setSpecialOpsIndex(0);
            window.scrollTo(0,this.scrolling);
            this.tabDialogSingle = false;
            this.tabDialogSingleIndex = 0;
            this.tabDialogSinglePost = null;
        },
    },
}
</script>

<style>

</style>