<template>
    <div>
        <v-card flat outlined class="ma-1">
            Slot POSTS
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'SlotPosts',

    props: ['color'],
}
</script>

<style>

</style>