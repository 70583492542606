<template>
  <div>
    
    <v-card flat 
        v-if="post && transSettingsStore[post.language] && transSettingsStore[post.language].available.length > 0" 
        class="transparent"
    >
        <v-icon small  class="" :color="color">mdi-translate</v-icon>

        <!-- Original title -->
        <v-tooltip v-if="this.post.language && this.post.language != 'fr' && this.post.language != 'en' && this.post.translations.length > 0" bottom max-width="700" class="mt-3">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :color="color"
                    text
                    class="ml-1"
                >
                    Original
                </v-btn>
            </template>
            <span>{{this.post.title}}</span>
        </v-tooltip>
                    

        <!-- :disabled='!outlinedLangButton(l.iso)' -->
        <v-btn 
            v-for="(l,index) in transSettingsStore[post.language].available" 
            @click="goTrans"
            :key="index"
            :loading="loading"
            x-small
            :outlined='!outlinedLangButton(l.iso)'
            :disabled='!outlinedLangButton(l.iso)'
            class="font-weight-bold ml-1"
            :color="color"
            :text="outlinedLangButton(l.iso)"
        >
            {{ l.iso.toUpperCase() }}
        </v-btn>


        <v-btn
            x-small
            v-for="(l,index) in languages_available" 
            :key="'l-' + index"
            @click="displayLanguageSelectedTranslation(l)"
            class="success--text font-weight-bold ml-1"
        >
           {{l.toUpperCase()}}
        </v-btn>


        <!-- error-->
        <v-card-text class="py-0 error---text">{{ error }}</v-card-text>
    </v-card>

    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name:'Translation',

    props: ['post','stream'],

    data: () => ({
        loading: false,
        doContentNotDescription: false,
        doTitle: true,
        dialog: false,
        error: null,
        content: "",
        description: "",
        title: "",
        languages_available: [],
        color: "lime lighten-3",
    }),

    created() {
        if (this.post && this.post.translations && this.post.translations.length > 0 ) {
            for (let t of this.post.translations) {
                if(t.output_language == this.$auth.retriveUserField("language_ui")) {
                    if (t.title.length > 1 ) {
                        this.doTitle = false;
                    }
                }
            }
        }

        this.postTranslationsAvailable();
    },


    methods: {
        goTrans: async function() {
            console.log("Translate in user language ui", this.$auth.retriveUserField("language_ui"))
            this.dialog = false;

            this.loading = true
            this.error = null;

            if (this.stream == "single") {
                this.doContentNotDescription = true;
            }

            let payload = {
                "id": this.post.id,
                "post_language": this.post.language,
                "target_language": this.$auth.retriveUserField("language_ui"),
                "do_content_not_description": this.doContentNotDescription,
                "do_title": this.doTitle,
            }

            try {
               let res = await this.$api.post("/translation/translate", payload);
               this.content = res.data.content;
               this.description = res.data.description;
               this.title = res.data.title;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.$emit("eventTranslation",this.$auth.retriveUserField("language_ui"), this.content, this.description, this.title)
            }

        },


        postTranslationsAvailable() {
            if (this.post && this.post.translations) {
                for (let t of this.post.translations) {
                    if (t.output_language != this.$auth.retriveUserField("language_ui")) {
                        this.languages_available.push(t.output_language)
                    }
                } 
            }
        },

        displayLanguageSelectedTranslation(lang) {
            for (let t of this.post.translations) {
                if (t.output_language == lang) {
                    this.content = t.content;
                    this.description = t.description;
                    this.title = t.title;
                }
                this.$emit("eventTranslation",this.$auth.retriveUserField("language_ui"),this.content,this.description,this.title)
            }
        },

        outlinedLangButton(lang) {
            // lang description already in post
            let b = true 
           
            //  stream post & description
            if (this.stream == "post" && this.post.translations) {
               
                for (let t of this.post.translations) {
                    if (t.description && t.output_language == lang && (t.description.length > 1 || t.content.length > 1)) {
                        b = false
                    }
                }
               
                // new description in
                if (this.description.length > 0 ) {
                    b = false
                }
            } 

            //  stream single & content
            if (this.stream == "single") {
                for (let t of this.post.translations) {
                    if (t.content && t.output_language == lang && t.content.length > 1) {
                        b = false
                    }
                }
                // new content in
                if (this.content.length > 0 ) {
                    b = false
                }
            } 

            return b
        },
    },

    computed: {
        ...mapGetters({
            transSettingsStore: 'getTransSettings',
        }),
    },
}
</script>

<style>

</style>