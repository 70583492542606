export default {

    // min tab
    tab: 0,

    setTab(value) {
        this.tab = value;
    },
 
    retriveTab() {
        return this.tab;
    },
 
    // News
    news: {
        language: "all",
        country: "all",
        dialog: false,
        single: null,
        index: 0,
        scroll: 0,
        page: 1,
    },

    setNewsPage(page) {
        this.news.page = page;
    },

    setNewsLanguage(lang) {
        this.news.language = lang;
    },

    setNewsCountry(country) {
        this.news.country = country;
    },

    setNewsDialog(bool) {
        this.news.dialog = bool;
    },

    setNewsSingle(obj) {
        this.news.single = obj;
    },
    
    setNewsIndex(i) {
        this.news.index = i;
    },

    setNewsScroll(val) {
        this.news.scroll = val;
    },

    retriveNewsPage() {
        return this.news.page;
    },
    
    retriveNewsLanguage() {
        return this.news.language;
    },

    retriveNewsCountry() {
        return this.news.country;
    },

    retriveNewsSingle() {
        return this.news.single;
    },

    retriveNewsIndex() {
        return this.news.index;
    },

    retriveNewsScroll() {
        return this.news.scroll;
    },

    retriveNewsDialog() {
        return this.news.dialog;
    },

}