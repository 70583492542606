<template>
    <div>
        <v-card flat outlined>
          
            <v-card-title class="font-weight-bold justify-center py-1 lime--text">Currency Converter</v-card-title>
            <v-divider></v-divider>

            <v-card-actions class="">
                <v-spacer></v-spacer>
                <v-text-field outlined dense v-model="value" label="Amount" hide-details color="lime" clearable></v-text-field>
                <v-select
                    outlined
                    dense
                    :items="currenciesStore"
                    v-model="currencySelect"
                    label="Currencies"
                    item-text="iso4217"
                    return-object
                    hide-details
                    clearable
                    color="lime"
                ></v-select>
                <v-spacer></v-spacer>
                <v-card-subtitle>rate={{rate}}</v-card-subtitle>
                <v-spacer></v-spacer>
                <v-text-field outlined dense readonly v-model="result" label="Conversion" hide-details color="lime" suffix="USD"></v-text-field>
                <v-spacer></v-spacer>
              
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

    name: 'Converter',

    props: ['country'],

    data: () => ({

        value: null,
        rate: null,
        result: null,
        currency: "",
        currencySelect: {},
    }),

    watch: {

        currenciesStore: {
            deep: true,
            immediate: true,
            handler() {
                for (let cr of this.currenciesStore) {
                    if (cr.countries.includes(this.country.toLowerCase())) {
                        this.rate = cr.rate;
                        this.currency = cr.iso4217;
                        this.currencySelect = cr
                    }
                }
            }
        },

        currencySelect: {
            deep: true,
            immediate: true,
            handler() {
                this.rate = this.currencySelect.rate;
                this.currency = this.currencySelect.iso4217;
                this.result = Math.round(100 * this.value * this.rate) / 100;
            }
        },

        value: {
            deep: true,
            immediate: true,
            handler() {
                this.result = Math.round(100 * this.value * this.rate) / 100;
            },
        }
    },

    methods: {

       
    },

    computed: {
        ...mapGetters({
            currenciesStore: 'getCurrencies',
        }),
    }
}
</script>

<style>

</style>