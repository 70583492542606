<template>
    <div>
        
        <v-card v-if="classifier" flat outlined class="mb-3" key="classifier-title">
            <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">New Classifier</v-card-title>
            <v-card-title v-else class="justify-center text-h5 accent--text font-weight-bold">{{classifier.ref}} - {{classifier.type}} - id#{{classifier.id}}</v-card-title>
        </v-card>

        <v-sheet  v-if="classifier" rounded outlined :color="classifier.type == 'ONNX_FT' ? 'warning':''">
            <v-card flat>
                <!-- error msg -->
                <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

                <!-- loading -->
                <v-progress-linear
                    indeterminate
                    color="accent"
                    :active="loading">
                </v-progress-linear>

                <v-container fluid key="clfr-form">
                    <v-row>
                        <v-col cols="6">
                            <v-card flat color="transparent" key="clfr-form-left">
                            
                                <v-card-text> 
                                    <v-select
                                        outlined dense
                                        :items="languages"
                                        v-model="classifier.language"
                                        label="Language"
                                        item-text="iso"
                                        item-value="iso"
                                        @change="currentLangClassifierRef"
                                    ></v-select>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model="classifier.ref"
                                        label="REF"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                    <v-select
                                        outlined dense
                                        v-model="classifier.role"
                                        :items="model_roles"
                                        label="PROD: Model Role"
                                    ></v-select>
                                    <v-divider></v-divider>
                                    <v-card-subtitle class="text-center">Below, form is all filled with params.json</v-card-subtitle>
                                    <v-select
                                        outlined dense
                                        v-model="classifier.type"
                                        :items="model_types"
                                        label="Model Type"
                                        hide-details
                                    ></v-select>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                        v-model="classifier.stemming"
                                        label="Stemming ?"
                                        color="success"
                                        class=""
                                    ></v-switch>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                        v-model="classifier.stop_words"
                                        label="Stop Words ?"
                                        color="success"
                                        class=""
                                    ></v-switch>
                                    <v-spacer></v-spacer>
                                    <v-switch
                                        v-model="classifier.manager_on"
                                        label="Manager ?"
                                        color="warning"
                                        class=""
                                    ></v-switch>
                                    <v-spacer></v-spacer>
                                </v-card-actions>

                                <v-card-text>  
                                    <v-textarea
                                        outlined
                                        dense
                                        v-model="classifier.description"
                                        :label="$i18n.t('description')"
                                    ></v-textarea>
                                    <v-text-field
                                        v-if="!isNew"
                                        rounded outlined dense
                                        v-model.number="classifier.created_at"
                                        label="Created at"
                                    ></v-text-field>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model.number="classifier.samples"
                                        label="Samples"
                                    ></v-text-field>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model.number="classifier.mitigation"
                                        label="mitigation [TPFN,FP,TN]"
                                    ></v-text-field>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model.number="classifier.mse"
                                        label="Mean Squared Error"
                                    ></v-text-field>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model.number="classifier.tags_max_id"
                                        label="Tags Max Id"
                                    ></v-text-field>
                                    <v-text-field
                                        rounded outlined dense
                                        v-model.number="classifier.sources_max_id"
                                        label="Sources Max Id"
                                    ></v-text-field>
                                
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6">

                            

                            <!-- MLP Params-->
                            <v-card flat color="transparent" key="clfr-params">
                                <v-card-title class="justify-center text-body-1 font-weight-bold">PARAMETERS</v-card-title>
                                <v-card-text>
                                    <pre>{{classifier.params | pretty(4)}}</pre>
                                </v-card-text>
                            </v-card>

                            <!-- FiLes MLP Import-->
                            <v-card v-if="isNew && !classifier.params" flat color="transparent"  key="clfr-params-import">
                                <v-card-title class="justify-center font-weight-bold">CLFR IMPORT PARAMS</v-card-title>

                                <v-card-text >
                                    <v-file-input
                                        accept=".json"
                                        v-model="params"
                                        label="params"
                                        outlined
                                        dense
                                        @change="inputParamsFile"
                                    ></v-file-input>
                                </v-card-text>

                            </v-card>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small
                                    color="accent"
                                    v-if="isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="create"
                                    class="mr-2 Dialog--text"
                                >
                                    <v-icon left>mdi-plus</v-icon> Create
                                </v-btn>
                                <v-btn small
                                    color="success"
                                    v-if="!isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save"
                                    class="mr-2"
                                >
                                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                                </v-btn>
                                <v-btn small
                                    v-if="!isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    color="error"
                                    @click="remove"
                                    class="mr-2"
                                >
                                    <v-icon small>mdi-delete</v-icon>
                                    Remove
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-sheet>


    </div>
</template>

<script>
import moment from "moment"
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';

// Classifier CLASS
class Classifier extends Model {
    id = null;
    params = null;
    ref = "";
    created_at = null;
    type = "OUT";
    role = null;
    description = null;
    samples = null;
    mitigation = null;
    language = null;
    stemming = true;
    stop_words = true;
    manager_on = false;
    mse = 0;
    mape = 0;
    tags_max_id = 0;
    sources_max_id = 0;

    onParse() {
        if (this.created_at) {
            this.created_at = moment.utc(this.created_at).format("YYYY-MM-DD HH:mm");
        }
    }

    onFormat() {
        if (this.created_at) {
            this.created_at = moment.utc(this.created_at).format();
        }
    }
}

export default {

    name: 'Classifier',

    data: () => ({
        loading: false,
        classifier: null,
        isNew: false,
        languages: null,
        params: null,

        rules: {
            required: v => !!v || 'Field required',
        },

        model_types: [
            "ONNX",
            "ONNX_FT",
        ],

        model_roles: [
            "MASTER",
            "SLAVE",
            "OUT",
        ],

        // api error
        error: null,
    }),

    created() {
        let id = this.$route.params.id;
        if (id != "new") {
            this.fetch(id);
            return;
        }
        this.isNew = true;
        this.classifier = Classifier.init();
    },

    watch: {
        languagesStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.languagesStore) {
                    this.languages = this.languagesStore;
                }
            }
        }
    },

    methods: {

        inputParamsFile() {
            var reader = new FileReader();
            reader.onload = () => {
                this.classifier.params = reader.result;
                console.log(JSON.parse(reader.result));
                let paramsJson = JSON.parse(reader.result);
                
                if (paramsJson.model_type.includes("MLP")) {
                    this.classifier.type = "MLP"
                }

                this.classifier.ref = paramsJson.name;
                this.classifier.type = paramsJson.model_type;
                this.classifier.role = "OUT";
                this.classifier.samples = paramsJson.samples;
                this.classifier.mitigation = paramsJson.mitigation;
                this.classifier.language = paramsJson.language;
                this.classifier.stemming = paramsJson.stemming;
                this.classifier.mse = paramsJson.mse;
                this.classifier.mape = paramsJson.mape_perc;
                this.classifier.tags_max_id = paramsJson.tags_max_id;
                this.classifier.sources_max_id = paramsJson.sources_max_id;
                this;this.classifier.stop_words = paramsJson.stop_words
            }
            reader.readAsText(this.params);
        },

    
        fetch: async function(id) {
            console.log("fetch classifier:",id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/classifiers/" + id);
                this.classifier = Classifier.parse(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        currentLangClassifierRef(value) {
            for (let l of this.languages) {
                if (l.iso_ref == value) {
                    let clf_count = l.classifiers_count;
                    this.classifier.ref = l.iso_ref.toUpperCase() + ("00" + clf_count).slice(-3);
                }
            }
        },

        //* CLASSIFIER CRUD
        create: async function() {
            this.loading = true;
            this.error = null;
            try {
                await this.$api.post("/classifiers", this.classifier.format());
                this.$router.go(-1);
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        save: async function() {
            console.log("save")
            this.loading = true;
            this.error = null;
            try {
                var payload = this.classifier;
                await this.$api.patch("/classifiers/" + this.classifier.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.classifier.is_model_stored = true;
                this.loading = false;
            }
        },

        remove: async function() {
            if (!confirm("Remove classifier \"" + this.classifier.ref + "/" + this.classifier.type  + "\"?")) {
                return;
            }
            await this.$api.delete("/classifiers/" + this.classifier.id);
            this.$router.go(-1);
        },
    },

    filters: {
        pretty: (val, indent = 2) => {
            if (typeof val !== "object") {
            try {
                val = JSON.parse(val)
            } catch (err) {
                console.warn("value is not JSON")
                return val
            }
            
            return JSON.stringify(val, null, indent)
            }
        }
    },

    computed: {
        ...mapGetters({
            languagesStore: 'getLanguages',
        }),
    }

}
</script>

<style>

</style>