import api from "@/common/api";
import auth from "@/common/auth";
// store
import store from '@/store/index'

export default {
    // Mobile
    isMobile: false,

    setIsMobile(bool) {
        this.isMobile = bool;
    },

    retriveIsMobile() {
        return this.isMobile;
    },

    // Objects
    // contains objects we fetch from DB
    // in order to fetch only once, we store them here
    objects: {
        countries: [],
        languages: [],
        currencies: [],
    },

    // needed for all users
    fetchObjects() {
        this.fetchCountries();
        this.fetchLanguages();
        this.fetchCurrencies();
    },

    retriveObject(name) {
        return this.objects[name];
    },


    //* COUNTRIES
    // list of countries are fetch according to user lang store in db
    // format {iso, name }
    fetchCountries: async function() {
        console.log("fetch countries")
        let countries = [];
        try {
            // build countries list for app 
            let res = await api.get("/countries");
            for (let c of res.data) {
                countries.push(c);
            }
            // sort countries alphabetically
            countries.sort((a,b) => a.name.localeCompare(b.name));
            this.objects.countries = countries
            
            // send to store
            store.dispatch('updateCountries', countries);

        } catch (e) {
            let data = (e.response || {}).data || "unknown error";
            console.log("app.js/objects: err fetching countries", data.message);
        } finally {

            // build store user_pkg_countries
            let userCountriesArray = auth.retriveUserField("packages_countries").split(',');
            let userPkgCountries = [];
            for (let c of countries) {
                if (userCountriesArray.includes(c.iso3)) {
                    userPkgCountries.push(c)
                }
            }
            
            // finally update in store for an app wide access
            store.dispatch('updateUserPkgCountries', userPkgCountries)
        }
    },


    // needs to be in app !
    // not in store
    retriveCountryNameFromIso(iso) {
        let name = "";
        for (let c of this.objects.countries) {
            if (c.iso3 == iso) {
                name = c.name;
            }
        }
        return name
    },

    retriveCountryIsoFromName(name) {
        let iso = "";
        for (let c of this.objects.countries) {
            if (c.name == name) {
                iso = c.iso3;
            }
        }
        return iso
    },


    //* LANGUAGES

    // init trans settings
    trans_settings: {},

    // list of languages in db 
    // format {id, iso, sources_count, classifier_count, name_fr, name_en, name_el}
    fetchLanguages: async function() {
        console.log("fetch languages")
        let languages = [];
        try {
            let res = await api.get("/languages");
            for (let lang of res.data) {
                languages.push(lang);
            }
            
            // sort languages alphabetically
            languages.sort((a,b) => a.iso.localeCompare(b.iso));

            this.objects.languages = languages

            // send to store
            store.dispatch('updateLanguages', languages);

        } catch (e) {
            let data = (e.response || {}).data || "unknown error";
            console.log("app.js/objects: err fetching languages", data.message);
        } finally {

            // merge with user packages laguage list to build trans settings
            let userLangArray = auth.retriveUserField("packages_languages").split(',');
        
            // build store user_pkg_countries

            let userLang = auth.retriveUserField("language_ui");
            let userPkglanguages = [];
            for (let l of languages) {
                let name = null;
                if (userLangArray.includes(l.iso)) {
                    if (l["name_" + userLang].length > 0) {
                        name = l["name_" + userLang]
                    } else {
                        name = l.iso;
                    }
                    userPkglanguages.push({iso: l.iso, name: name})
                }
            }
            
            // finally update in store for an app wide access
            store.dispatch('updateUserPkgLanguages', userPkglanguages)

            //* BUILD TRANS SETTINGS
            for (let l of userLangArray) {
                this.trans_settings[l] = {
                    language: l,
                    name: this.fetchLanguageNameAccordingUserLangUi(l),
                    available: [],
                }
            }

            // fetch trans sets (inside finish building trans_settings)
            this.fetchTransSets()
        }
    },

    // return name of a language accoding to its iso
    fetchLanguageNameAccordingUserLangUi(iso) {
        let name = "unknown"
        // get user lang
        let userLang = auth.retriveUserField("language_ui");
        if (this.objects.languages){
            for (let l of this.objects.languages) {
                if (l.iso == iso) {
                    if (l["name_" + userLang].length > 0) {
                        name = l["name_" + userLang]
                    } else {
                        name = iso;
                    }
                }
            }
        }
        return name
    },

    //* TRANS SETS
    // this function fetch post trans set then enrich trans settings 
    // before sending trans settings to the store
    fetchTransSets: async function() {
        let trans_sets =[];
        try {
            let res = await api.get("/translation/posts_sets");
            trans_sets = res.data
        } catch (e) {
            let data = (e.response || {}).data || "unknown error";
            console.log("app.js/objects: err fetching post translation sets", data.message);
        } finally {

            // continue building trans_settings
            // here we add available languages specified in post trans set

            // WARNING => trans_active set to TRUE !
            for (let ts of trans_sets) {
        
                let outputLanguages = ts.output_languages.split(',');
                for (let ol of outputLanguages) {
                
                    if (this.trans_settings[ts.post_language]) {
                        this.trans_settings[ts.post_language].available.push({ iso: ol, name: this.fetchLanguageNameAccordingUserLangUi(ol), trans_active: true});
                    }
                }
            }

            // finally update in store for an app wide access
            store.dispatch('updateTransSettings',this.trans_settings)
        }
    },


    //* CURRENCIES
    fetchCurrencies: async function() {
        let currencies = []
        try {
            let res = await api.get("/currencies");
            currencies = res.data
        } catch (e) {
            let data = (e.response || {}).data || "unknown error";
            console.log("app.js/objects: err fetching currencies", data.message);
        } finally {
            this.currencies = currencies;
            // finally update in store for an app wide access
            store.dispatch('updateCurrencies', currencies)
        }

    }
}   