<template>
    <div>
        <v-container v-if="source" fluid>
            <v-row>     
                <v-col md="5" lg="3">
                    <v-card flat outlined>
                        DATE INTERCEPTOR
                    </v-card>
                </v-col>
                <v-col md="7" lg="9">
                    <SpecialOpsPostTest
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import SpecialOpsPostTest from './SpecialOpsPostTest.vue';

export default {

    name: 'SpecialOpsPostsTab',

    props: ['source','cards','feeds'],

    components: {
        SpecialOpsPostTest
    },

    watch:{
        
        source: {
            deep: true,
            immediate: true,
            handler() {
                this.post_source = this.source;
            }
        },

        cards: {
            deep: true,
            immediate: true,
            handler() {
                this.post_cards = this.cards;
            }
        }
    },

    data: () => ({
        post_source: null,
        post_cards: null,
    }),

}
</script>

<style>

</style>