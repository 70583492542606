<template>
    <div>
        <v-card v-if="set" flat outlined class="mb-2">
            <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">
                New Post Translation Set
            </v-card-title>
            <v-card-title v-else class="justify-center accent--text text-h5 font-weight-bold">
                Post Translation Set #{{ set.id }}
            </v-card-title>
        </v-card>

        <v-card flat outlined color="transparent">
            
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>

            <v-card-actions v-if="set">

                <!-- Post Language -->
                <v-spacer></v-spacer>
                <v-card-text>
                    <v-select
                        outlined
                        dense
                        :items="languagesStore"
                        v-model="set.post_language"
                        label="Post Language"
                        item-text="iso"
                        item-value="iso"
                    ></v-select>
                </v-card-text>
                
                <!-- Active ? -->
                <v-switch
                    v-model="set.active"
                    :label='set.active ? "ON":"OFF"'
                    color="success"
                    class="pb-10 mx-10"
                    hide-details
                ></v-switch>
                
                <!-- Output Languages -->
                <v-spacer></v-spacer>
                <v-card-text>
                    <v-select
                        outlined
                        dense
                        :items="languagesStore"
                        v-model="set.output_languages"
                        label="Output Languages"
                        item-text="iso"
                        item-value="iso"
                        multiple
                    ></v-select>
                </v-card-text>
            </v-card-actions>
        
            <v-container v-if="set" fluid key="set-form">
                <v-row>
                    <v-col cols="6">
                        <v-card  flat color="transparent" key="set-form-left">
                            <v-card-title class="py-0 justify-center warning--text">TRASH</v-card-title>

                            <!-- title-->
                            <v-switch
                                v-model="set.do_trash_title"
                                label="Do trash title"
                                color="warning"
                                class=""
                                hide-details=""
                            ></v-switch>

                            <!-- description -->
                            <v-switch
                                v-model="set.do_trash_description"
                                label="Do trash description"
                                color="warning"
                                class=""
                                hide-details=""
                            ></v-switch>

                            <!-- content -->
                            <v-switch
                                v-model="set.do_trash_content"
                                label="Do trash content"
                                color="warning"
                                class=""
                                hide-details=""
                            ></v-switch>
                            
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="set-form-right">
                            
                            <v-card-title class="py-0 justify-center green--text">STANDBY</v-card-title>

                            <!-- title-->
                            <v-switch
                                v-model="set.do_standby_title"
                                label="Do standby title"
                                color="success"
                                class=""
                                hide-details=""
                            ></v-switch>

                            <!-- description -->
                            <v-switch
                                v-model="set.do_standby_description"
                                label="Do standby description"
                                color="success"
                                class=""
                                hide-details=""
                            ></v-switch>

                            <!-- content -->
                            <v-switch
                                v-model="set.do_standby_content"
                                label="Do standby content"
                                color="success"
                                class=""
                                hide-details=""
                            ></v-switch>

                            
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card flat color="transparent" key="set-form-actions">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="success"
                    v-if="isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="create"
                    class="mr-2"
                >
                    <v-icon left>mdi-plus</v-icon> Create
                </v-btn>
                <v-btn
                    small
                    color="success"
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="save"
                    class="white--text mr-2"
                >
                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                </v-btn>
                <v-btn
                    small
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    color="error"
                    @click="remove"
                    class="mr-2"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Remove
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- snack bar -->
        <v-snackbar centered v-model="snackbar" timeout="500" color="success">
        <span class="font-weight-bold Dialog--text">Saved !</span>
        <template v-slot:action="{ attrs }">
            <v-btn
            small
            class="Dialog--text"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            {{ $i18n.t("close") }}
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';


class Set extends Model {
    id = null;
    active = null;
    post_language = null;
    output_languages = null;
    do_description = null;
    do_content = null;
    do_if_trash = null;
    
    do_standby_title = null;
    do_standby_description = null;
    do_standby_content = null;

    do_trash_title = null;
    do_trash_description = null;
    do_trash_content = null;

    onParse() {
        if (this.output_languages) {
            this.output_languages = this.output_languages.split(",")
        }
    }

    onFormat() {
        if (this.output_languages) {
            this.output_languages = this.output_languages.join(",")
        }
    }
}

export default {

    name: 'PostTransSet',

    data: () => ({
        loading: false,
        set: null,
        isNew: false,
        snackbar: false,

        rules: {
            required: (v) => !!v || "Field required",
        },

        // api error
        error: null,
       
    }),

    created() {
        let id = this.$route.params.id;
        if (id != "new") {
            this.fetch(id);
            return;
        }
        this.isNew = true;
        this.set = Set.init();
    },

    methods: {
        fetch: async function (id) {
            console.log("fetch set:", id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/translation/post_set/" + id);
                this.set = Set.parse(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* SET CRUD
        create: async function () {
            console.log("create");
            this.loading = true;
            try {
                await this.$api.post("/translation/post_sets", this.addPayload(this.set).format());
                this.$router.go(-1);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        save: async function () {
            console.log("save");
            this.loading = true;
            try {
                var payload = this.addPayload(this.set).format();
                await this.$api.patch("/translation/post_sets/" + this.set.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.snackbar = true;
                this.loading = false;
            }
        },

        remove: async function () {
            if (!confirm('Remove post trans set "' + this.set.iso + " | "  + this.set.language + '"?')) {
                return;
            }
            await this.$api.delete("/translation/post_sets/" + this.set.id);
            this.$router.go(-1);
        },

        addPayload(load) {
            return load
        },
    },

    computed: {
        ...mapGetters({
            languagesStore: 'getLanguages',
        }),
    },

}
</script>

<style>

</style>