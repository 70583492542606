<template>
    <div>
        <v-card flat outlined class="mb-3">

            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="color"
                :active="loading">
            </v-progress-linear>

            <v-card-actions>
                <v-spacer></v-spacer>
                <!-- start_page -->
                <v-text-field
                    v-model.number="start_page"
                    label="Start Page"
                    required
                    hide-details
                    :color="color"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model.number="count_page"
                    label="Pages Count"
                    required
                    hide-details
                    :color="color"
                ></v-text-field>
                <v-spacer></v-spacer>
                <!-- languages-->
                <v-text-field
                    v-model="lang"
                    label="language"
                    required
                    hide-details
                    :color="color"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-switch v-model="do_tags" label="do tags?"  :color="color"></v-switch>
                <v-spacer></v-spacer>
                <v-switch v-model="do_loot" label="do loot?"  :color="color"></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    @click="updatePosts" 
                    class="Drawer--text" 
                    :color="color">
                    G0
                </v-btn>

                <v-spacer></v-spacer>

            </v-card-actions>

            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Pager',

    data: ()=> ({
        loading: false,
        error: null,
        color:"yellow",

        start_page: 1,
        count_page: 1,
        do_tags: true,
        do_loot: true,
        lang: "",
        posts: [],
    }),


    methods: {

        updatePosts: async function() {
            
            let payload = {
                start: this.start_page,
                count: this.count_page,
                do_tags: this.do_tags,
                do_loot: this.do_loot,
                language: this.lang,
            }
            console.log("update posts: payload=", payload)

            try {
                await this.$api.post("/tooler/pager", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },
    },
    
}
</script>

<style>

</style>