<template>
    <div>
        <v-card flat color="Background" key="frame-posts">
            
            <!-- <TranslationsSelectWidget class="my-2 mx-3"/> -->

            <!-- POSTS -->
            <v-sheet 
                outlined rounded 
                v-for="(post, index) in posts" :key="'post_'+post.id" 
                class="mb-2 mx-3" :color="frameColor"
            >
                <v-card flat color="" class="d-flex" key="widgets + post">
                    <!-- Widgets -->
                    <v-card class="mt-5" color="transparent"  key="widgets">
                        <VoteWidget 
                            :post="post" 
                            :index="index"  
                            stream="post"
                            :tab="tab"
                            @eventVote="eventVote" 
                            :key='"v" + post.id'
                        ></VoteWidget>
                    </v-card>
                    <!-- Post -->
                    <Post 
                        :post="post" :index="index" :tab="tab"
                        @eventGoSingle="eventOpenSingleDialog"
                        @eventGoTags="eventGoTags"
                        @eventGoLoot="eventGoLoot"
                        @eventGoCorpus="eventGoCorpus"
                        @eventGoMiner="eventGoMiner"
                        @eventGoSource="eventGoSource"
                        :colorView="frameColor"
                        :key='"p" + post.id'
                    ></Post>
                </v-card>
            </v-sheet>
        </v-card>

        <!-- DIALOG SINGLE -->
        <v-dialog 
            v-model="dialogSingle"
            fullscreen
            key="frame-single-dialog"
        >    
            <!-- v-card needed for triggering fullscreen-->    
            <v-card key="card-single">  
                <Single v-if="dialogSinglePost"
                    :single="dialogSinglePost" 
                    :index="dialogSingleIndex" 
                    :colorView="frameColor"
                    :tab="tab"
                    @eventCloseSingleDialog="eventCloseSingleDialog"
                    @eventVote="eventVote" 
                    @eventTranslation="eventTranslation"
                    @eventGoTags="eventGoTags"
                    @eventGoLoot="eventGoLoot"
                    @eventGoCorpus="eventGoCorpus"
                    @eventGoMiner="eventGoMiner"
                    @eventGoSource="eventGoSource"
                ></Single>
            </v-card>
        </v-dialog>
       

           
    </div>
</template>

<script>
//import TranslationsSelectWidget from '../../Translations/TranslationsSelectWidget.vue'
import Post from '../components/Post'
import VoteWidget from '../../Vote/VoteWidget.vue'
import Single from './TrainerDialogSingle'

export default {

    name: 'Frame',

    components: {
        //TranslationsSelectWidget,
        Post,
        VoteWidget,
        Single,
    },

    props: ['tab', 'frameColor', 'posts', 'tabDialogSingle', 'tabDialogSingleIndex', 'tabDialogSinglePost'],

    data: () => ({

        dialogSingle: false,
        dialogSingleIndex: 0,
        dialogSinglePost: null,

    }),

    created() {
        this.dialogSingle = this.tabDialogSingle;
        this.dialogSingleIndex = this.tabDialogSingleIndex;
        this.dialogSinglePost = this.tabDialogSinglePost
    },


    watch: {
        // when updates for example in tags component
        posts: {
            deep: true,
            immediate: true,
            handler() {

            }
        },

        tabDialogSingle: {
            deep: true,
            immediate: true, 
            handler() {
                this.dialogSingle = this.tabDialogSingle;
            }
        },

        // if changed in tags for example
        // when back from tools/tags via a go(-1)
        // we need to wait for the updated posts in tab
        tabDialogSinglePost: {
            deep: true,
            immediate: true, 
            handler() {
                this.dialogSinglePost = this.tabDialogSinglePost
            }
        }
    },


    methods: {
        
        //* Events
        eventVote(cl, index, stream) {
            if ( stream == 'single') {
                this.eventCloseSingleDialog();
            }
            this.$emit('eventVote', cl, index, stream);
        },

        eventGoTags(index) {
            this.$emit("eventGoTags", index);
        },

        eventGoLoot(index) {
            this.$emit("eventGoLoot", index);
        },

        eventGoCorpus(index) {
            this.$emit("eventGoCorpus", index);
        },

        eventGoMiner(index) {
            this.$emit("eventGoMiner", index);
        },

        eventGoSource(index) {
            this.$emit("eventGoSource", index);
        },

        eventTranslation(index,output_language,content,description,title) {
            console.log("eventTranslation",index, output_language)
            if (this.posts[index].translations.length > 0) {
                for (let tr of this.posts[index].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title =title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        //* Dialog Single
        eventOpenSingleDialog(index) {
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index];
            this.dialogSingleIndex = index;
            this.$emit("eventOpenSingleDialog", index)
        },

        eventCloseSingleDialog(){
            this.dialogSingle = false;
            this.dialogSinglePost = null;
            this.dialogSingleIndex = null;
            this.$emit("eventCloseSingleDialog")
        },
       
    },
}
</script>

<style>

</style>