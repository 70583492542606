<template>
    <div>
        <v-tabs v-model="tab" centered background-color="transparent" key="trainer-main-tabs">
            <v-tabs-slider :color="tabSliderColor"></v-tabs-slider>
            <v-tab @click="selectTab(0)" class="font-weight-bold green--text">{{$i18n.t("standby")}} {{standbyRemaining}}</v-tab>
            <v-tab @click="selectTab(1)" class="font-weight-bold warning--text">{{$i18n.t("trash")}} {{trashRemaining}}</v-tab>
            <v-tab @click="selectTab(2)" class="font-weight-bold yellow--text">Crimorg {{crimorgRemaining}}</v-tab>
            <v-tab @click="selectTab(3)" class="font-weight-bold amber--text">Loot</v-tab>
            <v-tab @click="selectTab(4)" class="font-weight-bold light-blue--text text--accent-1">Special Ops {{soRemaining}}</v-tab>
        </v-tabs>
        
        <v-card flat outlined key="trainer-main-tabs-items">
            <v-tabs-items v-model="tab" :active-class="backgroundThemeSwitcher" class="tab-items">
                <v-tab-item key="standby" class="">
                    <Standby @eventStandbyRemaining="eventStandbyRemaining" :tabNumber="tab"/>
                </v-tab-item>
                <v-tab-item key="trash" class="">
                    <Trash @eventTrashRemaining="eventTrashRemaining" :tabNumber="tab"/>
                </v-tab-item>
                <v-tab-item key="crimorg" class="">
                    <Crimorg @eventCrimorgRemaining="eventCrimorgRemaining" :tabNumber="tab"/>
                </v-tab-item>
                <v-tab-item key="loot" class="">
                    <Loot :tabNumber="tab"/>
                </v-tab-item>
                 <v-tab-item key="special_ops" class="">
                    <SpecialOps @eventSpecialOpsRemaining="eventSpecialOpsRemaining" :tabNumber="tab"/>
                </v-tab-item>
              
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import trainer from "@/common/trainer";
import Standby from './TrainerTabStandby'
import Trash from './TrainerTabTrash'
import Crimorg from './TrainerTabCrimorg'
import SpecialOps from './TrainerSpecialOpsTab.vue'
import Loot from './TrainerTabLoot'

export default {

    name: 'TrainerMain',
    
    data: () => ({
        tab: null,
        tabColors: ["green","warning","yellow","amber","light-blue accent-1","pink"],
        standbyRemaining: null,
        trashRemaining: null,
        crimorgRemaining: null,
        lawRemaining: null,
        soRemaining: null,
    }),

    components: {
        Standby,
        Trash,
        Crimorg,
        Loot,
        SpecialOps,
    },

    created() {
        // init tab: /common/.js
        this.tab = trainer.retriveTab();
    },

    methods: {

        selectTab(value) {
            trainer.setTab(value)
        },

        eventStandbyRemaining(r) {
            this.standbyRemaining = r;
        },

        eventTrashRemaining(r) {
            this.trashRemaining = r;
        },

        eventCrimorgRemaining(r) {
            this.crimorgRemaining = r;
        },

        eventSpecialOpsRemaining(r) {
            this.soRemaining = r;
        },

    },

    computed: {
        tabSliderColor() {
            return this.tabColors[this.tab];
        },

        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return "active-items-dark"
            } else {
                return "active-items-light"
            }
        }
    
    }
}
</script>

<style>
</style>