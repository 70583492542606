<template>
    <div  height="100%">
        <v-card height="100%">
            <v-row align="center" justify="center">
                <v-card flat class="" color="">
                    <v-card-text class="font-weight-bold text-h4 text-center">{{$settings.site}}</v-card-text>
                </v-card>
            </v-row>
        </v-card>
    </div>
</template>

<script>

export default {

    name: 'PublicHome',

    components: {
        
    },

    data: () => ({
    }),

    created() {
    },

    methods: {
    },
}
</script>

<style>

</style>