<template>
    <div>
        <v-card flat outlined class="mb-2 pb-2">
            <!-- loading -->
            <v-progress-linear
                indeterminate
                :color="color"
                :active="loading">
            </v-progress-linear>

            <v-card-actions class="py-0">
                <v-spacer></v-spacer>
                <!-- POST ID -->
                <v-text-field
                    v-model="id"
                    label="Post ID"
                    required
                    hide-details
                    :color="color"
                ></v-text-field>
                
                <!-- eye -->
                <v-spacer></v-spacer>
                <span v-if="post"><a :href="post.url" target="_blank"><v-icon :color="color">mdi-eye</v-icon></a></span>

                <v-spacer></v-spacer>
                <v-btn
                small
                @click="fetchLoot" 
                class="Drawer--text" 
                :color="color">
                    G0
                </v-btn>
                <v-spacer></v-spacer>
                <v-switch v-model="filterLoot" @change="colorText" :label='filterLoot? "filter On": "filter Off"' color="amber"></v-switch>
                <v-spacer></v-spacer>
            </v-card-actions>

            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
        </v-card>

        <v-card v-if="post" flat outlined class="mb-5">
            <v-expansion-panels v-if="post" accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header >
                        <v-card flat color="transparent">
                            <v-card-text class="font-weight-bold amber--text py-0">
                                <span>Post: {{post.title}}</span>
                            </v-card-text>
                            <v-card-subtitle class="py-0">source: {{  post.source_name }} · {{formatDate}} · {{ post.language }} · src country: {{  post.country }}</v-card-subtitle>
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card-text>
                            <span v-html="post.content"></span>
                        </v-card-text>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- Old Post Loot Widget -->
        <v-card flat>
            <v-card-actions class="py-0">
                <v-card-title>POST</v-card-title>
                <LootWidget  v-if="post" 
                    :loot="old_post_loot" 
                    :pid="id" 
                    :language="post.language" 
                    stream="lootPostTab" 
                    status="old" class=""
                    @eventLoot="eventLoot"
                    key="old"
                />
            </v-card-actions>
        </v-card>

        <!-- New Post tags Widget -->
        <v-card flat>
            <v-card-actions class="py-0">
                <v-card-title>LOOTER</v-card-title>
                <LootWidget v-if="post" 
                    :loot="new_post_loot" 
                    :pid="id" 
                    :language="post.language" 
                    stream="lootPostTab"
                    status="new"  
                    key="new"
                />
            </v-card-actions>

            <v-card-text>
               ORIGINAL: <span v-html="original"></span>
            </v-card-text>
       
            <v-card-text>
                LOOT CORPUS: <span v-html="modifiedCorpus"></span>
            </v-card-text>
            <v-card-text v-if="post && post.translations && post.translations.length> 0" class="green--text">
                <p>{{noHtml(post.translations[0].title)}}</p>
                <p>{{noHtml(post.translations[0].description)}}</p>
                <p>{{noHtml(post.translations[0].content)}}</p>
            </v-card-text>
        </v-card>

        
            <v-card v-for="(loot,index) in loots" :key="index"
                flat
                class="mb-2"
                color="transparent"
            >
                <v-card outlined v-if="loot.to_keep || !filterLoot "
                    flat color="transparent"
                >
                    <v-card-text class="pTagText--text font-weight-bold py-1">
                        #{{ index }} - 
                        <span class='font-weight-bold amber--text'>
                            {{ loot.normalized }}
                            {{ displayCategoryMetric(loot) }}
                        </span> 
                        [ <span v-if="loot.category.length > 0">cat: '{{ loot.category }}' -</span> token: {{ loot.token }} <span v-if="hasMulti(loot).length > 0">- multi: {{hasMulti(loot)}}</span> - p: {{ loot.position }} ]
                    </v-card-text>
                    <v-card-subtitle class="py-0 pTagText--text ml-5">  
                        <span  v-if="loot.tag_ref.length > 0" class='font-weight-bold pink--text'>TagRef: {{ loot.tag_ref }} [token= "{{ loot.tag_ref_token }}"]</span>
                    </v-card-subtitle>
                    <v-card-subtitle class="py-0 pTagText--text ml-5">

                        Keep
                        <v-icon v-if="loot.to_keep" small color="green">mdi-check-circle</v-icon>
                        <v-icon v-else small color="error">mdi-close-circle</v-icon>

                        <span v-if="loot.is_dollar_mitigated" class="font-weight-bold green--text"> | $ MITIGATION</span>
                        <span v-if="loot.iso_4217.length > 0" class="font-weight-bold green--text"> | iso4217={{ loot.iso_4217 }}</span>

                        <v-icon v-if="loot.is_duplicate" small color="warning" class="ml-2">mdi-content-duplicate</v-icon>
                        <v-icon v-if="loot.is_value_proximity"  color="warning" class="ml-2">mdi-numeric</v-icon>
                        <v-icon v-if="loot.is_value_proximity" color="warning" class="">mdi-approximately-equal</v-icon>
                        <span v-if="loot.is_worth_weight_same_tag_ref" class="warning--text"> | cat: worth == weight - is_worth_weight_same_tag_ref = true</span>
                        <span v-if="loot.is_secondary" class="warning--text"> | lower loot: is_secondary = true </span>
                        <span v-if="loot.is_secondary_cash" class="warning--text"> | lower cash loot: is_secondary_cash = true</span>
                        <span v-if="loot.is_secondary_cash" class="warning--text"> | lower cash loot: is_secondary_cash = true</span>
                        <span v-if="loot.no_tag_ref" class="warning--text"> | No Tag Ref</span>
                         <span v-if="loot.is_date" class="warning--text"> | Is Equiv. Year Date</span>
                        <v-btn v-if="loot.has_no_number" x-small class="font-weight-bold ml-3" color="error">Number: 0</v-btn>
                    </v-card-subtitle>
                    <v-card-subtitle v-if="loot.before.fragment.length > 0" class="py-0 pTagText--text ml-5">
                        Before fragment: '{{ loot.before.fragment }}'
                    </v-card-subtitle>
                    <v-card-subtitle v-if="loot.after.fragment.length > 0" class="py-0 pTagText--text ml-5">
                        After fragment: '{{ loot.after.fragment }}'
                    </v-card-subtitle>
                  
                </v-card>
                
                <v-divider></v-divider>
            </v-card>
        

    </div>
</template>

<script>
import moment from "moment";
import LootWidget from '../Loot/LootWidget.vue'

export default {

    name: 'Loot',

    components: {
        LootWidget,
    },

    data: ()=> ({
        loading: false,
        error: null,
        id: null,
        post: null,
        color:"amber",
        old_post_loot: [],
        new_post_loot: [],
        loots: null,
        original: "",
        corpus: "",
        modifiedCorpus: "",
        filterLoot: false,
    }),

    created() {
        this.corpus = "";
        if (this.$route.params.pid) {
            this.id = this.$route.params.pid.trim()
            this.fetchLoot();
        }
    },

    methods: {

        fetchLoot: async function() {

            console.log("fetching loot")
            this.loading = true;
            this.error = null;
            this.corpus = "";
            this.modifiedCorpus = "";

            this.loots = []

            let filter = (
                "?pid=" + this.id.trim() + 
                "&user_lang=" + this.$auth.retriveUserField("language_ui")
            );

            try {
                let res = await this.$api.get("/tooler/post_loot" + filter);
                this.post = res.data.post;
                this.original = res.data.original;
                this.corpus = res.data.corpus;
                this.old_post_loot = this.post.loot;
                this.new_post_loot = res.data.new_post_loot;
                this.loots = res.data.loot;
                this.colorText()

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // set golden fragment + value
        colorText() {

            this.modifiedCorpus = this.corpus
            
            let textTokens = this.modifiedCorpus.split(" ");

            for (let l of this.loots) {
                if (l.position > 0) {
                    if (this.filterLoot) {
                      // colorize any fragment
                      if (l.to_keep) {
                            textTokens[l.position] = "<span class='font-weight-bold text-decoration-underline amber--text'>" + l.token + " [kept+filtered]</span>";
                        } else {
                            textTokens[l.position] = "<span class=' green--text'>" + l.token + "</span>";
                        }
        
                    } else {
                        // colorize any fragment
                        if (l.to_keep) {
                            textTokens[l.position] = "<span class='font-weight-bold text-decoration-underline amber--text'>" + l.token + "</span>";
                        } else {
                            textTokens[l.position] = "<span class='amber--text'>" + l.token + "</span>";
                        }
                    }
                }
            }

            this.modifiedCorpus = textTokens.join(" ")

            // WARNING ONLY AFTER FRAGMENT IS USED
            // TO DO left language => before !
            for (let l of this.loots) {
                if (l.to_keep || l.is_worth_weight_same_tag_ref) {
                    let fragment = l.after.fragment
                   
                    if (l.tag_ref_token.length > 0 ) {
                        fragment = fragment.replace(l.tag_ref_token,"<span class='font-weight-bold pink--text'>" + l.tag_ref_token + "</span>")
                    }
                   
                    if (l.after.metrics) {
                        for (let m of l.after.metrics) {
                            fragment = fragment.replace(m.trigger_token, "<span class='font-weight-bold blue--text'>" + m.trigger_token + "</span>")
                        }
                    }
                    
                    this.modifiedCorpus = this.modifiedCorpus.replaceAll(l.after.fragment, fragment)
                }
            }

        },

        eventLoot() {
            console.log("eventLoot");
            this.sleep(1000).then(() => this.fetchLoot());
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        displayCategoryMetric(loot) {
            let m = "?"
            switch (loot.category) {
                case "weight":
                    m = "kg"
                    break;
                case "cash":
                    m = "US$"
                    break;
                case "object":
                    if (loot.normalized > 1) {
                        m = "objects"
                    } else {
                        m = "object"
                    }
                    break;
                case "worth":
                    m = "worth US$"
                    break;
                default:
                    break;
            }

            return m
        },

        hasMulti(loot) {
            let t = ""
            if (loot.after.metrics) {
                for (let m of loot.after.metrics) {
                    if (m.type == "multi") {
                        t= m.loot_ref
                    }
                }
            }
            return t
        },

        noHtml(text) {
            let output = ""
            if (text.length > 0) {
                output = text.replace(/<[^>]+>/g, "")
            }
            return output
        }


    },

    computed:{
        formatDate() {
            return moment(this.post.created_at).format("DD/MM/YY HH:MM")
        },
    }

}
</script>

<style>

</style>