var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":[
            { text: 'Key', value: 'key', align: 'left', class: 'text-body-1 py-3' },
            { text: 'Tag Ref', value: 'tag_ref', align: 'center', class: 'text-body-1 py-3' },
            { text: 'ascii_name', value: 'ascii_name', align: 'center', class: 'text-body-1 py-3' },
            { text: 'channel', value: 'index', align: 'center', class: 'text-body-1 py-3' },
        ],"items":_vm.labels,"loading":_vm.loading,"search":_vm.search,"footer-props":{
    itemsPerPageText: 'labels par page',
    itemsPerPageOptions: [40, 80, -1],
},"dense":""},on:{"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"purple"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v("LABELS FROM TAG => '"+_vm._s(_vm.tag_ref)+" [channel: "+_vm._s(_vm.channel)+"]"),(_vm.total > 1)?_c('span',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm.total)+")")]):_vm._e()]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","flat":"","rounded":"","solo":"","single-line":"","label":_vm.$tools.capitalizeWord(_vm.$i18n.t("search")),"hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","color":"Dialog","elevation":"0"},on:{"click":function($event){return _vm.create(_vm.tag_ref)}}},[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"purple","indeterminate":""},slot:"progress"})],1),(_vm.error)?_c('span',{staticClass:"ml-5 error--text"},[_vm._v("API Error: "+_vm._s(_vm.error))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }