import Vue from "vue";
import axios from "axios";
import App from "@/shell/App.vue";
import router from "@/router/router";
import store from './store';

// plugins
import i18n from "@/plugins/i18n-setup";
import vuetify from "@/plugins/vuetify";
import flags from "@/plugins/flags";


// shell
import app from "@/common/app";
import api from "@/common/api";
import auth from "@/common/auth";
import rbac from "@/common/rbac";
import settings from "@/common/settings"
import tools from "@/common/tools"
import roles from "@/roles/roles";
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;
Vue.prototype.$app = app;
Vue.prototype.$api = api;
Vue.prototype.$auth = auth;
Vue.prototype.$rbac = rbac;
Vue.prototype.$roles = roles;
Vue.prototype.$settings = settings;
Vue.prototype.$tools = tools;

// init
// init addresses
const debugApi = "http://localhost:8891"; // go server
const debugUi = "http://localhost:8084";  // local dev

axios.get(window.location.origin + window.location.pathname + "config.json")
    .then(function(res) {
        // if https prod api base - prod server
        if (window.location.origin.includes('https://')) {
            api.base = res.data.api.base;
            api.ui = res.data.api.ui;
            //console.warn("[INIT]main api:", api.base);
        } else if(window.location.href.includes('78.')) {
            api.base = "http://78.192.159.115:8891";
            api.ui = "http://78.192.159.115:8084";
            console.warn("[INIT]main: Using SSH DEV server - api:", api.base, "ui:", api.ui);
        }else {
            console.warn("[INIT]main: Using debug server - api:", debugApi, "local:", debugUi);
            api.base = debugApi;
            api.ui = debugUi;
        }
    })
    .catch(function() {
        console.warn("[INIT]main: config.json not found");
        api.base = debugApi;
        api.ui = debugUi;
    })
    .finally(function() {
        new Vue({
            router,
            store,
            vuetify,
            i18n,
            flags,
            render: h => h(App)
        }).$mount("#app");
    });



