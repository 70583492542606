<template>
    <div>

        <v-app-bar fixed min-height="170" color="" class="">
            <template v-slot:extension>
                <v-card flat width="100%" color="transparent" class="mt-2">
                    
                    <v-card-actions class="py-0">

                        <!-- dialog -->
                        <v-btn
                            class="font-weight-bold"
                            color="transparent"
                            text
                            @click="closeDialog"
                        >
                            <v-icon :color="colorView" large>mdi-close-circle-outline</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>

                        <!-- TITLE -->
                        <v-card-title v-if="single.translations && single.translations[transIndex] && transIndex < single.translations.length" class="text-h6 font-weight-bold text-center wordbreak py-0">{{single.translations[transIndex].title}}</v-card-title>
                        <v-card-title  v-else class="text-h6 font-weight-bold text-center wordbreak py-0">{{single.title}}</v-card-title>

                        <v-spacer></v-spacer>

                    </v-card-actions>
                    
                    <v-card-actions class="py-0">
                        <v-spacer></v-spacer>
                        <!-- META -->
                        <v-card-subtitle class="text-body-2 py-0">
                            <a>
                                <v-btn x-small rounded @click="goArticle" elevation="0" :color="colorView" class="Dialog--text font-weight-bold mx-0 px-1">
                                    <country-flag :country='single.country' size='small' :rounded="true" class=""/><span class="pt-1 pr-1">{{single.source_name}}</span>
                                </v-btn>
                            </a>
                            <v-icon @click="eventGoSource" class="ml-1" :color="colorView">mdi-alpha-s-circle-outline</v-icon>
                            · {{$app.retriveCountryNameFromIso(single.country.toLowerCase())}} 
                            · {{formatPostDate}} | ES {{formatDate}} ({{sinceDate}}) · {{single.id}} · {{ wordsCount }} words
                        </v-card-subtitle>
                    
                        <!-- WIDGETS -->
                        <PoolWidget
                            :post="single" :index="index" position="horizontal"
                            @eventPostAddedInPool="eventPostAddedInPool"
                            @eventPostRemoveFromPool="eventPostRemoveFromPool"
                        ></PoolWidget>
                        
                        <VoteWidget v-if="isUserProfileTrainer"
                            :post="single" 
                            :index="index" 
                            :tab="tab"
                            stream="single"
                            @eventVote="eventVote" 
                        ></VoteWidget>
                        
                        <PublisherWidget v-if="tab != 'deck'"
                            :post="single"
                            stream="post" 
                            @eventGoPublisher="eventGoPublisher"
                        ></PublisherWidget>
                        
                        <TranslationWidget 
                            :post="single" 
                            stream="single"
                            :tab="tab"
                            @eventTranslation="eventTranslation" 
                            class="ml-3"
                        ></TranslationWidget>
                        
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    
                    <!-- TAGS WIDGET -->
                    <v-card-actions class="py-0">
                        <v-spacer></v-spacer>
                        <TagsWidget 
                            :pid="single.id" 
                            :tags="single.tags" 
                            stream="single"
                            @eventGoTags="eventGoTags" 
                            class=""
                        ></TagsWidget>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                    <!-- Loot Widget -->
                    <v-card-actions class="py-2">
                        <v-spacer></v-spacer>
                        <LootWidget
                            :pid="single.id"
                            :loot="single.loot"
                            :language="single.language" 
                            @eventGoLoot="eventGoLoot"
                            stream="single" 
                            class="ml-4"
                        ></LootWidget>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </template>
        </v-app-bar>

        
        <v-card flat tile outlined class="mt-15 pt-3" color="" height="100%">
            <v-container fluid key="single-content" class="mt-15">
                <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="8">
                        <!-- SINGLE -->
                        <SingleContentTransTabs 
                            v-if="single.translations && single.translations[0].content.length > 0" 
                            :single="single" 
                            :colorView="colorView"
                            @eventTransTabIndex="eventTransTabIndex"
                            class="mt-5"
                        ></SingleContentTransTabs>
                        <v-sheet v-else rounded outlined :color="colorView" key="single-content" class="mt-7" width="100%">
                            <v-card flat class="px-3">
                                <v-card-text class="content text-body-1 pTagText--text"><span v-html="single.content" ></span></v-card-text>
                            </v-card>
                        </v-sheet>
                    </v-col>
                    <v-col cols="2"></v-col>
                </v-row>
            </v-container>
        </v-card> 
  
    </div>
</template>

<script>
import moment from "moment";
import PoolWidget from './SubscriberPoolWidget'
import VoteWidget from '../../Vote/VoteWidget.vue'
import TagsWidget from '../../Tags/TagsWidget.vue'
import SingleContentTransTabs from '../components/SingleContentTransTab'
import TranslationWidget from '../../Translations/TranslationWidget.vue'
import LootWidget from '../../Loot/LootWidget.vue'
import PublisherWidget from '../../Publisher/PublisherPostWidget'


export default {

    name:'DialogSingle',

    props:['single','index','tab','isUserProfileTrainer','colorView'],

    components: {
        PoolWidget,
        VoteWidget,
        TagsWidget,
        SingleContentTransTabs,
        TranslationWidget,
        LootWidget,
        PublisherWidget,
    },

    data: ()=> ({
        // keep track of which index in single.translations is active
        transIndex: 0,
    }),

    watch:{
        single:{
            deep: true,
            immediate: true,
            handler(){}
        }
    },

    methods: {
        goArticle(event) {
            window.open(this.single.url, '_blank');
            event.preventDefault();
        },

        closeDialog() {
            this.$emit("eventCloseSingleDialog");
        },

        eventPostAddedInPool(pid, index) {
            this.$emit("eventPostAddedInPool", pid,index)
        },

        eventPostRemoveFromPool(id, index) {
            this.$emit("eventPostRemoveFromPool", id, index);
        },

        eventVote(cl, index, stream) {
            this.$emit("eventVote", cl, index, stream)
        },

        eventTrainerWidgetVote(cl, index) {
            this.$emit("eventTrainerWidgetVote", cl, index)
        },

        eventTransTabIndex(tabIndex) {
            this.transIndex = tabIndex;
        },

        eventTranslation(output_language, content, description, title) {
            this.$emit("eventTranslation", output_language, content, description, title)
        },

        eventGoTags() {
            this.$emit("eventGoTags", this.index);
        },

        eventGoLoot() {
            this.$emit("eventGoLoot", this.index);
        },

        eventGoCorpus() {
            this.$emit("eventGoCorpus", this.index);
        },

        eventGoPublisher(id) {
            this.$emit("eventGoPublisher", id);
        },

        eventGoMiner() {
            this.$emit("eventGoMiner", this.index);
        },

        eventGoSource() {
            this.$emit("eventGoSource", this.index);
        }

    },

    computed: {

        sinceDate() {
            return moment(this.single.created_at).locale(this.$auth.retriveUserField("lang")).fromNow();
        },

        formatDate() {
            return moment.utc(this.single.created_at).format("DD/MM/YY@HH:MM UTC")
        },

        formatPostDate() {
            return moment(this.single.date).format("DD/MM/YY")
        },

        wordsCount() {
            let noHtml = this.single.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },

    }

}
</script>

<style>
.wordbreak {
    word-break: normal;
}

.content p {
    margin-bottom: 15px;
    margin-top: 7px;
}

.content h2,h3,h4 {
    margin-bottom: 16px;
    margin-top: 20px;
}

.cardImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-width: 500px;
}
</style>