<template>
    <div>
        <v-card v-if="set" flat outlined class="mb-2">
            <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">
                New Package Set
            </v-card-title>
            <v-card-title v-else class="justify-center accent--text text-h5 font-weight-bold">
                Package Set #{{ set.id }}
            </v-card-title>
        </v-card>

        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

        <!-- loading -->
        <v-progress-linear
            indeterminate
            color="accent"
            :active="loading">
        </v-progress-linear>

        <v-card flat outlined>
            <v-container v-if="set" fluid key="set-form">
                <v-row>
                    <v-col cols="6">
                        <v-card  flat color="transparent" key="set-form-left">
                            <v-card-text>
                                <v-autocomplete
                                outlined
                                dense
                                :items="countriesStore"
                                v-model="set.iso"
                                :label="$i18n.tc('country',1)"
                                item-text="name"
                                item-value="iso3"
                                :rules="[rules.required]"
                                ></v-autocomplete>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="set-form-right">
                            <v-card-text>
                                <v-select
                                outlined
                                dense
                                :items="languagesStore"
                                v-model="set.language"
                                label="Language"
                                item-text="iso"
                                item-value="iso"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card flat color="transparent" key="set-form-actions">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="success"
                    v-if="isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="create"
                    class="mr-2"
                >
                    <v-icon left>mdi-plus</v-icon> Create
                </v-btn>
                <v-btn
                    small
                    color="success"
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="save"
                    class="white--text mr-2"
                >
                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                </v-btn>
                <v-btn
                    small
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    color="error"
                    @click="remove"
                    class="mr-2"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Remove
                </v-btn>
            </v-card-actions>
        </v-card>

        <!-- snack bar -->
        <v-snackbar centered v-model="snackbar" timeout="500" color="success">
        <span class="font-weight-bold Dialog--text">Saved !</span>
        <template v-slot:action="{ attrs }">
            <v-btn
            small
            class="Dialog--text"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            {{ $i18n.t("close") }}
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';

class Set extends Model {
    id = null;
    iso = null;
    country = null;
    language = null;
}

export default {

    name: 'Set',

    data: () => ({
        loading: false,
        set: null,
        isNew: false,
        snackbar: false,

        rules: {
            required: (v) => !!v || "Field required",
        },

        // api error
        error: null,
       
    }),

    created() {

        let id = this.$route.params.id;
        if (id != "new") {
            this.fetch(id);
            return;
        }
        this.isNew = true;
        this.set = Set.init();
    },

    methods: {
        fetch: async function (id) {
            console.log("fetch set:", id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/packages_set/" + id);
                this.set = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* SET CRUD
        create: async function () {
            console.log("create");
            this.loading = true;
            try {
                await this.$api.post("/packages_sets", this.set.format());
                this.$router.go(-1);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        save: async function () {
            console.log("save");
            this.loading = true;
            try {
                var payload = this.addPayload(this.set);
                await this.$api.patch("/packages_sets/" + this.set.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.snackbar = true;
                this.loading = false;
            }
        },

        remove: async function () {
            if (!confirm('Remove set "' + this.set.iso + " | "  + this.set.language + '"?')) {
                return;
            }
            await this.$api.delete("/packages_sets/" + this.set.id);
            this.$router.go(-1);
        },

        addPayload(load) {
            return load
        },
    },

    computed: {
        ...mapGetters({
            languagesStore: 'getLanguages',
            countriesStore: 'getCountries',
        }),
    },
}
</script>

<style>

</style>