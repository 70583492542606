export default {

    tab: 0,
    language: "all",
    country: "all",
    
    scroll: 0,

    //* SET
    setTab(value) {
        this.tab = value;
    },

    setLanguage(lang) {
        this.language = lang;
    },

    setCountry(country) {
        this.country = country;
    },

   
    setScroll(val) {
        this.scroll = val;
    },

    //* RETRIEVE
    retriveTab() {
        return this.tab;
    },

    retriveLanguage() {
        return this.language;
    },

    retriveCountry() {
        return this.country;
    },

    retriveScroll() {
        return this.scroll;
    },


    // Paramaters
    parameters: {
        
        doc_epsilon: 85,
        doc_max_tokens: 210,

        day_offset: 1,
        daily_batch_hour: 18,
        only_clusters: false,

        // DBSCAN
        tags_loot_epsilon: 13,
        min_points: 1,
        continent: "all",

        // deck visibility
        see_out: false,
        publisher_model_ref: "PUB_C018",
        
        w2c_dimensions: 50,
        w2c_model: "glove",
    },

    setParameters(params) {
        this.params = params;
    },

    retriveParameters() {
        return this.parameters;
    },

    //* Cluster posts single
    cluster_dialog: false,
    cluster_single: null,
    cluster_index: 0,
    post_index: 0,

    setClusterDialog(bool) {
        this.cluster_dialog = bool;
    },

    setClusterSingle(obj) {
        this.cluster_single = obj;
    },

    setClusterIndex(i) {
        this.cluster_index = i;
    },

    setClusterPostIndex(i) {
        this.post_index = i;
    },

    retriveClusterDialog() {
        return this.cluster_dialog;
    },

    retriveClusterSingle() {
        return this.cluster_single;
    },

    retriveClusterIndex() {
        return this.cluster_index;
    },

    retriveClusterPostIndex() {
        return this.post_index;
    },

}