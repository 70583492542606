const state = {
    focus: "",
    type: "week",
};


const getters = {
    getFocus() {
        return state.focus;
    },

    getType() {
        return state.type;
    },
};

const actions = {
    updateFocus: function({ commit }, str) {
        commit('setFocus', str);
    },

    updateType: function({ commit }, str) {
        commit('setType', str);
    },
};


const mutations = {
    setFocus: function(state, str) {
        state.focus = str;
    },

    setType: function(state, str) {
        state.type = str;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
