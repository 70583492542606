<template>
    <div>

        <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table :headers="[
            { text: 'Key', value: 'key', align: 'left', class: 'text-body-1 py-3' },
            { text: 'Tag Ref', value: 'tag_ref', align: 'center', class: 'text-body-1 py-3' },
            { text: 'ascii_name', value: 'ascii_name', align: 'center', class: 'text-body-1 py-3' },
            { text: 'channel', value: 'index', align: 'center', class: 'text-body-1 py-3' },
        ]" :items="labels" :loading="loading" :search="search" @click:row="selectRow" :footer-props="{
    itemsPerPageText: 'labels par page',
    itemsPerPageOptions: [40, 80, -1],
}" dense>
            <v-progress-linear slot="progress" color="purple" indeterminate></v-progress-linear>

            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="purple">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="font-weight-bold">LABELS FROM TAG => '{{ tag_ref }} [channel: {{ channel }}]<span
                            v-if="total > 1" class="ml-2">({{ total }})</span></v-toolbar-title>

                    <v-spacer></v-spacer>

                    <!-- TABLE SEARCH -->
                    <v-text-field dense flat rounded solo single-line v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))' hide-details clearable></v-text-field>
                    <v-spacer></v-spacer>

                    <!-- NEW BUTTON -->
                    <v-btn small fab color="Dialog" @click="create(tag_ref)" elevation="0">
                        <v-icon color="">
                            mdi-plus
                        </v-icon>
                    </v-btn>

                </v-toolbar>
            </template>
        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Labels',

    data: () => ({
        labels: [],
        tag_ref: null,
        children_refs: [],
        channel: null,

        //set up
        loading: false,
        total: null,
        search: null,

        // api error
        error: null,
    }),

    created() {
    },

    watch: {

        // needed to trigger the rendering of already downloaded posts stored in data
        // when we navigate back form tools.TagsPostTab 
        // the route change trigger the rendering with stored data
        $route: {
            deep: true,
            immediate: true,
            handler() {

                if (this.$route.params.tag_ref) {
                    this.tag_ref = this.$route.params.tag_ref;
                    this.channel = this.$route.params.channel;
                    this.fetchLabelsFromTagRef()

                    // update store
                    this.updateLabels({
                        tag_ref: this.tag_ref,
                        channel: this.channel,
                    })

                } else {
                    if (this.labelsStore) {
                        this.tag_ref = this.labelsStore.tag_ref;
                        this.channel = this.labelsStore.channel;
                        this.fetchLabelsFromTagRef()
                    }
                }
            }
        },
    },

    methods: {

        ...mapActions(['updateLabels']),

        fetchLabelsFromTagRef: async function () {

            this.loading = true;
            this.error = null;
            this.total = null;
            this.labels = [];

            try {
                let payload = { ref: this.tag_ref }
                let res = await this.$api.post("/tagger/labels/ref/", payload);
                this.labels = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false
            }
        },


        // go to Source.vue
        selectRow(row) {
            this.$router.push({ name: "label", params: { id: row.id, channel: this.channel } })
        },

        create(tag_ref) {
            this.$router.push({ name: "label", params: { id: "new", tag_ref: tag_ref, channel: this.channel } })
        },
    },


    computed: {
        ...mapGetters({
            labelsStore: 'getLabels',
        }),
    }
}
</script>

<style></style>