var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":[
            { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
            { text: 'Login', value: 'email', align:'center', class:'text-body-2' },
            { text: 'Name', value: 'name', align:'center', class:'text-body-2' },
            { text: 'Role', value: 'role', align:'center', class:'text-body-2' },
            { text: 'UI', value: 'language_ui', align:'center', class:'text-body-2' },
            { text: 'Theme', value: 'theme', align:'center', class:'text-body-2' },
            { text: 'Created', value: 'created_at', align:'center', class:'text-body-2' },
            { text: 'Updated', value: 'updated_at', align:'center', class:'text-body-2' },
        ],"items":_vm.users,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.total,"disable-sort":"","disable-filtering":"","footer-props":{
            itemsPerPageText:'utilisateurs par page',
            itemsPerPageOptions: [15,30,-1],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.selectRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"Dialog"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$i18n.t("users")))]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","flat":"","rounded":"","solo":"","single-line":"","label":_vm.$tools.capitalizeWord(_vm.$i18n.t("search")),"hide-details":""},on:{"change":_vm.searchUsers},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('template',{slot:"append"},[_c('v-icon',{on:{"click":_vm.searchUsers}},[_vm._v("mdi-magnify")]),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){_vm.search = null}}},[_vm._v("mdi-close")])],1)],2),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","color":"accent","elevation":"0"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"color":"Dialog"}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:`item.role`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$i18n.t(item.role))+" ")]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.updated_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"accent","indeterminate":""},slot:"progress"})],1),(_vm.error)?_c('span',{staticClass:"red--text"},[_vm._v("API ERROR/"+_vm._s(_vm.error))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }