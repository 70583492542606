<template>
    <div>

        <v-sheet rounded outlined color="accent" class="mb-3" key="selected-feed-header">
           
            <v-card flat class="">
                <v-card-actions class="py-0">
                    <v-btn v-if="selectedFeedIndex > 0 && fetchFeedUrl == feedUrl" small icon  @click="prevFeed" class="ml-5"><v-icon color=" accent">mdi-arrow-left-bold</v-icon></v-btn> 
                    <v-spacer></v-spacer>
                    <v-icon @click="goArticle" color="accent">mdi-eye</v-icon>
                    <v-icon @click="reload" class="ml-5" color="accent">mdi-reload</v-icon>
                    <v-checkbox dense small v-model="isProdMode" class="ml-5 pt-3" color="accent" :label='isProdMode ? "mode Prod": "mode Test"' ></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-btn v-if="selectedFeedIndex < feeds.length - 1 && fetchFeedUrl == feedUrl" small icon  @click="nextFeed" class="mr-5"><v-icon color=" accent">mdi-arrow-right-bold</v-icon></v-btn> 
                </v-card-actions>
                
                <v-progress-linear
                    indeterminate
                    color="accent"
                    :active="loading"
                ></v-progress-linear>
              
                <v-card-text>
                    <v-text-field
                        outlined dense
                        label="fetch feed url"
                        v-model="fetchFeedUrl"
                        class="text-subtitle-2"
                        hide-details
                    ></v-text-field>
                </v-card-text>
                <v-card-title v-if="fetchFeedUrl == feedUrl"
                    class="text-body-1 wordbreak py-0" 
                    color="accent"
                >
                    #{{selectedFeedIndex + 1}}/{{feeds.length}} - {{feedTitle}} <span v-if="delay > 0 && !loading">[delay {{Math.round(100 * delay)/100}} s] </span>
                </v-card-title>
                <v-card-text v-if="fetchFeedUrl == feedUrl">   
                    {{feedUrl}}
                </v-card-text>

            </v-card>
        </v-sheet>

        <!-- error msg -->
        <v-card-text v-if="error" class="error--text py-0 text-center">{{ error }}</v-card-text>
        
        <!-- POST -->
        <v-sheet v-if="post && feeds && contentItems" rounded outlined color="accent" key="fetched_post_content">
            <v-card flat>
                <v-card-text class="text-body-1 text-center pt-5 mb-2">
                    <v-row>
                        <v-spacer></v-spacer>
                        Content: {{postContentToKeepLength}} characters
                        <v-spacer></v-spacer>
                        <v-btn x-small :color="post.softmax > 0.5? 'success':'error'" class="font-weigh-bold" elevation="0">Softmax: {{Math.round(post.softmax * 10) / 10}}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn x-small class="font-weight-bold" @click="switchExcludedVisibility" elevation="0">
                            <span v-if="excludedVisible" class="error--text">EXCLUDED IN</span>
                            <span v-else class="success--text">EXCLUDED OUT</span>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <!-- tabs-->
                <v-tabs v-model="tab" centered>
                    <v-tabs-slider color="accent"></v-tabs-slider>
                    <v-tab>SELECTORS</v-tab>
                    <v-tab>HTML</v-tab>
                </v-tabs>

                <v-tabs-items  v-model="tab" class="pt-4">
                    <v-tab-item key="SELECTORS">
                        <v-card flat v-for="(item,index) in contentItems" :key="index">
                            <v-card-title class="wordbreak accent--text text-subtitle-1 py-0">{{index + 1}} - {{item.selector}}</v-card-title>
                            <v-card-text v-if="item.content.includes('STRING EXCLUSION') || item.selector.includes('Excluded')" class="error--text text-subtitle-2">{{item.content}}</v-card-text>
                            <v-card-text v-else-if="item.content.includes('TAG EXCLUSION')" class="warning--text text-subtitle-2">{{item.content}}</v-card-text>
                            <v-card-text v-if="!item.content.includes('EXCLUSION') && !item.selector.includes('Excluded')" class="pTagText--text text-subtitle-2">{{item.content}}</v-card-text>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="HTML">
                        <v-card flat v-for="(item,index) in contentItems" :key="index">
                            <v-card-text v-if="item.content.includes('STRING EXCLUSION') || item.selector.includes('Excluded')" class="error--text text-subtitle-2" ><span v-html="'#' + item.content"></span></v-card-text>
                            <v-card-text v-else-if="item.content.includes('TAG ATTRIBUTE EXCLUSION')" class="warning--text text-subtitle-2" ><span v-html="'#' + item.content"></span></v-card-text>
                            <v-card-text v-else class="content pTagText--text py-0"><span  v-html="item.content" ></span></v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-sheet>

    </div>
</template>

<script>
export default {

    name: 'PostTest',

    // proped source includes postcards_payload
    // which contains a array of card under json format
    props: ['source','feeds'],

    watch: {

        feeds: {
            deep: true,
            immediate: true,
            handler() {
                if (this.feeds) {
                    this.fetchFeedUrl = this.feeds[this.selectedFeedIndex].url;
                    if (this.source.cards.length > 0) {
                        this.fetchTestPost()
                    }
                }
            }
        },

        selectedFeedIndex: {
            deep: true,
            immediate: true,
            handler() {
                if (this.feeds && this.source.cards.length > 0) {
                    if (this.source.cards.length > 0) {
                        this.fetchTestPost()
                    }
                }
            }
        },

        fetchFeedUrl: {
            deep: true,
            immediate: true,
            handler() {
                if (this.feeds) {
                    if (this.fetchFeedUrl != this.feeds[this.selectedFeedIndex].url) {
                        this.post = null;
                        this.postContentToKeepLength = 0;
                    }
                }
            }
        },
    },

    data: () => ({
        loading: false,
        tab: null,
        content: null,
        error: null,
        post: null,
        delay: 0,
        postContentToKeepLength: 0,
        contentItems: [],
        selectedFeedIndex: 0,
        fetchFeedUrl: null,
        excludedVisible: true,
        isProdMode: false,
    }),

    methods: {
        fetchTestPost: async function() {
            console.log("fetchTestPost")
            this.loading = true;
            this.post = null;
            this.error = null;
            this.delay = 0;

            // attached selected feed to the source
            this.source.selected_feed = this.fetchFeedUrl;
            let mode = "test"
            if (this.isProdMode) {
                mode = "prod";
            }

            try {
                let res = await this.$api.post("/sources/post/test?mode=" + mode, this.source);
                this.post = res.data.post;
                this.delay = res.data.delay;
                this.buildContentItems();
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        buildContentItems() {
            this.contentItems =[];
            let count = 0;

            // if we use a hook only a content with raw html no ## to split
            // get rid of first '##' then split
            if (this.post.content.includes('##')) {
                let segments = this.post.content.slice(2).split('##')
                for (let seg of segments) {
                    let s = seg.split('&&');
                    let contentItem = {
                        selector: s[0],
                        content: s[1],
                    }

                    if (!s[0].includes('Excluded') && !s[1].includes('STRING EXCLUSION')) {
                        count += s[1].length;
                    }
            
                    if (this.excludedVisible) {
                        this.contentItems.push(contentItem);
                    } else {
                        // STRING
                        if (!s[0].includes('Excluded') && !s[1].includes('STRING EXCLUSION')) {
                            // INNER TAG
                            if (!s[1].includes('INNER TAG EXCLUSION')) {
                                this.contentItems.push(contentItem);
                            }
                        }
                    }
                }
            } else {
                this.contentItems.push({selector: "PROD", content: this.post.content});
            }
            
            
            this.postContentToKeepLength = count;
        },

        goArticle() {
            window.open(this.fetchFeedUrl, '_blank');
        },

        reload() {
            this.fetchTestPost();
        },

        prevFeed() {
            this.selectedFeedIndex --;
            this.fetchFeedUrl = this.feeds[this.selectedFeedIndex].url;
            this.fetchTestPost();
        },

        nextFeed() {
            this.selectedFeedIndex ++;
            this.fetchFeedUrl = this.feeds[this.selectedFeedIndex].url;
            this.fetchTestPost();
        },

        switchExcludedVisibility() {
            this.excludedVisible =! this.excludedVisible
            this.buildContentItems();
        },
    },

    computed: {
        feedUrl() {
            if (this.feeds) {
                return this.feeds[this.selectedFeedIndex].url;
            } else {
                return "no selected feed url"
            }
        },

        feedTitle() {
            if (this.feeds) {
                return this.feeds[this.selectedFeedIndex].title;
            } else {
                return "no selected feed title"
            }
        },
    }
}
</script>

<style>
.wordbreak {
    word-break: normal;
}

content h1,h2,h3,h4,h5,h6 {
    margin-top: 15px;
    margin-bottom: 15px;
}

content ul {
    margin-bottom: 10px;
}

.cardImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    max-width: 500px;
}

</style>