<template>
  <div>
    <!-- SOURCE HEADER -->
    <v-card 
        v-if="source" 
        flat outlined 
        class="mb-2" 
        key="source-header"
    >
        <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">
                New Source
        </v-card-title>
        <v-card-title v-else class="justify-center accent--text text-h5 font-weight-bold">
            {{ source.name }} [{{ source.ref }}] id#{{ source.id }}
        </v-card-title>
    </v-card>

    <!-- SOURCE FORM -->
    <v-card v-if="source" flat outlined key="source-form" color="" class="pb-2">
        
        <!-- loading -->
        <v-progress-linear 
            indeterminate 
            color="accent" 
            :active="loading">
        </v-progress-linear>

        <v-row>
            <v-col cols="6">
                <v-card flat color="transparent" key="source-form-left">
                    <v-card-text>
                        <v-text-field
                            outlined
                            dense
                            v-model="source.name"
                            :label="$i18n.t('name')"
                            :rules="[rules.required]"
                        ></v-text-field>
                        <v-autocomplete
                            outlined
                            dense
                            :items="types"
                            v-model="source.type"
                            label="Type"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                        <v-select
                            outlined
                            dense
                            :items="languages"
                            v-model="source.lang"
                            label="Language"
                            item-text="iso"
                            item-value="iso"
                            @change="currentLangSourceRef"
                        ></v-select>
                        <v-select
                            outlined
                            dense
                            :items="levels"
                            v-model="source.level"
                            label="Level"
                            item-text="name"
                            item-value="level"
                        ></v-select>
                        <v-text-field
                            rounded
                            outlined
                            dense
                            v-model="source.ref"
                            :label="$i18n.t('sourceRef')"
                            :rules="[rules.required]"
                            hide-details
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="6">
                <v-card  flat color="transparent" key="source-form-right">
                    <v-card-text>
                        <!-- country -->
                        <v-autocomplete
                            outlined
                            dense
                            :items="countries"
                            v-model="source.country"
                            :label="$i18n.tc('country',1)"
                            item-text="name"
                            item-value="iso3"
                            :rules="[rules.required]"
                            @change="searchCities"
                        ></v-autocomplete>

                        <!-- city -->
                        <v-autocomplete
                            outlined
                            dense
                            :items="cities"
                            v-model="source.city_name"
                            :label="$i18n.tc('city',1)"
                            item-text="ascii_name"
                            item-value="name"
                            :rules="[rules.required]"
                            clearable
                        ></v-autocomplete>

                        <!-- description -->
                        <v-text-field
                            rounded
                            outlined
                            dense
                            v-model="source.description"
                            :label="$i18n.t('description')"
                        ></v-text-field>

                        <!-- created_at -->
                        <v-text-field
                            v-if="!isNew"
                            v-model="source.created_at"
                            readonly
                            rounded
                            outlined
                            dense
                            label="Created"
                        ></v-text-field>

                        <!-- Ok_at -->
                        <v-menu v-if="source.ok_at" 
                            v-model="date_menu" :close-on-content-click="false"
                            transition="scale-transition" 
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    v-model="source.ok_at"
                                    rounded
                                    outlined
                                    dense
                                    label="Ok Last At" 
                                    v-bind="attrs" 
                                    v-on="on" 
                                >
                                    <template v-slot:append-outer>
                                        <v-btn @click="setToToday" small dense class="font-weight-bold" color="success">Today ?</v-btn>
                                    </template>
                                </v-text-field>
                        </template>
                            <v-date-picker 
                                v-model="source.ok_at"
                                @input="date_menu = false" 
                            ></v-date-picker>
                        </v-menu>

                        
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row>

        <v-card-actions class="py-0">

            <!--  Is Community ? -->
            <v-spacer></v-spacer>
            <v-switch
                v-model="source.is_community"
                label="Is Community ?"
                color="purple accent-2"
                class=""
                hide-details=""
            ></v-switch>

            <!-- Image -->
            <v-spacer></v-spacer>
            <v-card flat color="transparent">
                <v-card-text class="py-0">
                    <v-switch
                        v-model="source.img_from_content"
                        label="IMG from content ?"
                        color="success"
                        class=""
                        hide-details
                    ></v-switch>
                    Only if the img url inside the feed is not good<br>
                    std: no feed image => fetch first image in content
                </v-card-text>
            </v-card>

            <!-- Prod ON/OFF -->
            <v-spacer></v-spacer>
            <v-switch
                v-model="source.prod"
                :label="`${source.prod ? 'IN PRODUCTION' : 'OFF'}`"
                color="success"
                class=""
                hide-details=""
            ></v-switch>

         
            <v-spacer></v-spacer>
            <v-btn
                v-if="isNew"
                small
                color="success"
                :loading="loading"
                :disabled="loading"
                @click="create"
                class="font-weight-bold mr-2 mt-5"
            >
                <v-icon left>mdi-plus</v-icon> Create
            </v-btn>
            <v-btn
                v-if="!isNew"
                small
                color="success"
                :loading="loading"
                :disabled="loading"
                @click="save"
                class="font-weight-bold white--text mr-2 mt-5"
            >
                <v-icon small>mdi-arrow-up-circle</v-icon>Save
            </v-btn>
            <v-btn
                small
                v-if="!isNew"
                :loading="loading"
                :disabled="loading"
                color="error"
                @click="remove"
                class=" font-weight-bold mr-2 mt-5"
            >
                <v-icon small>mdi-delete</v-icon>
                Remove
            </v-btn>
        </v-card-actions>
            
        <!-- error msg -->

        <v-card-text v-if="error || langError" class="ml-5 error--text"
            >API Error: {{ error }} {{ langError }}
        </v-card-text>

    </v-card>

    <v-card flat outlined key="source-tabs" class="mt-2">

        <v-tabs v-model="tab" centered>
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab class="font-weight-bold">Feeder</v-tab>
            <v-tab class="font-weight-bold">Poster</v-tab>
            <v-tab v-if="!isNew" class="font-weight-bold">SO Feeds</v-tab>
            <v-tab v-if="!isNew" class="font-weight-bold">SO Post</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">

            <!-- Feeds Tab -->
            <v-tab-item key="feeds-tab">
                <FeederTab 
                    :source="source"
                    :isNew="isNew"
                    @eventSaveSource="eventSaveSource"
                    @eventFeeds="eventFeeds"
                />
            </v-tab-item>

            <!-- Posts Tab -->
            <v-tab-item key="posts-tab">
                <PosterTab
                    :source="source"
                    :cards="cards"
                    :feeds="feeds"
                    @eventSaveSource="eventSaveSource"
                />
            </v-tab-item>
            
            <!-- Special Ops Feeds Tab -->
            <v-tab-item  v-if="!isNew" key="special-ops-feeds-tab">
                <SpecialOpsFeedsTab 
                    :source="source"
                    @eventSoFeeds="eventSoFeeds"
                ></SpecialOpsFeedsTab>
            </v-tab-item>

            <!-- Special Ops Posts Tab -->
            <v-tab-item  v-if="!isNew" key="special-ops-posts-tab">
                <SpecialOpsPostsTab 
                    :source="source"
                    :cards="cards"
                    :feeds="so_feeds"
                ></SpecialOpsPostsTab>
            </v-tab-item>
        </v-tabs-items>
    </v-card>

    <!-- snack bar -->
    <v-snackbar centered v-model="snackbar" timeout="500" color="success">
      <span class="font-weight-bold Dialog--text">Saved !</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          class="Dialog--text"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $i18n.t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import { Model } from "@/common/model";
import FeederTab from './FeederTab.vue'
import PosterTab from './PosterTab.vue'
import SpecialOpsFeedsTab from "./SpecialOps/SpecialOpsFeedsTab.vue"
import SpecialOpsPostsTab from './SpecialOps/SpecialOpsPostsTab.vue'
import { mapGetters } from 'vuex';

// Source CLASS
class Source extends Model {
    id = null;
    prod = false;
    ref = null;
    name = "";
    created_at = null;
    ok_at = null;
    country = null;
    city_name = null;
    city_lon = null;
    city_lat = null;
    level = 0;
    type =  null;
    description = null;
    lang = null;
    img_from_content = null;

    // feed
    url = "";
    stream_type = "";
    depth = null;
    feeder_chromedp_time_out = 20;
    feeder_chromedp_target = "";
    
    has_feed_interceptor= false;
    add_feed_host = false;
    feed_between_slash = false; // sometimes feedHost + url feeds needs a / between
    feeed_no_description = false;
    
    feed_filter_out = null;
    feed_filter_out_categories = "";
    feed_host = null;
    source_host = null;
    
    feed_url_selectors = null;
    feed_title_selectors = null;
    feed_description_selectors = null;

    poster_is_chromedp = false;
    has_post_interceptor= false;
    poster_chromedp_time_out = 20;
    poster_chromedp_target = ""
    
    // CARDS
    cards_payload = [];

    // feeder chromedp
    node_path = "";
    url_path = "";
    title_path = "";
    description_path = "";
    categories_node_path = "";

    // poster chromedp
    with_chromedp = false;
    chromedp_target = "";

    // community
    is_community = false;

    // TLS: insecureSkipVerify
    colly_insecure_skip_verify = false; 

    onParse() {
        if (this.created_at) {
            this.created_at = moment.utc(this.created_at).format("YYYY-MM-DD");
        }

        if (this.ok_at) {
            this.ok_at = moment.utc(this.ok_at).format("YYYY-MM-DD");
        }
    }
}

// Card CLASS
class Card extends Model {
    id = null;
    source_id = null;
    target = null;
    selectors = "";
    insert_in_img_url = "";
    exclusion_strings = "";
    exclusion_selectors = "";
}

export default {

    name: "Source",

    components: {
        FeederTab,
        PosterTab,
        SpecialOpsFeedsTab,
        SpecialOpsPostsTab,
    },

    data: () => ({
		
        tab: null,
        source: null,
        loading: false,
        cards: [],
        isNew: false,
        snackbar: false,
        date_menu: false,
        warningInPast: false,
    
        // objects
        countries: null,
        languages: null,
        cities: [],
        city: "",

        rules: {
            required: (v) => !!v || "Field required",
        },

        // api error
        error: null,
        langError: null,

        types: ["Newspaper", "Magazine", "Press Agency", "Government Agency",  "Website", "Blog", "Tabloid", "Law Enf.", "Justice Dpt.", "Institute", "Broadcaster", "Trainer"],

        levels: [
			{ level: 0, name: "Hang Around" }, // 'hang around' => trainer must confirm post either class = 0 or class = 1
			{ level: 1, name: "Prospect" }, // 'prospect' => trainer must confirm post class = 1
			{ level: 2, name: "Full Patch" }, // no trainer class confirmation needed
        ],

        // Feeds
        feeds: null,
        so_feeds: null,

    }),

    created() {
        let ref = this.$route.params.ref;
        if (ref != "new") {
            this.fetch(ref);
            return;
        }
        this.isNew = true;
        this.source = Source.init();

    },

    watch: {

        languagesStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.languagesStore) {
                    this.languages = this.languagesStore;
                }
            }
        },

        countriesStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.countriesStore) {
                    this.countries = this.countriesStore;
                }
            }
        },
    },

    methods: {
        
        fetch: async function (ref) {
            console.log("fetch source:", ref);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/sources/" + ref);
                this.source = Source.parse(res.data);
                if (res.data.cards) {
                    for (let c of res.data.cards) {
                        let card = Card.init();
                        card = c;
                        this.cards.push(card);
                    }
                }
                // set city value
                if (this.source.city_name.length > 0 ) {
                    this.city = this.source.city_name.toLowerCase();
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.searchCities()
                this.loading = false;
            }
        },

        currentLangSourceRef: async function (lg) {
            console.log("fetch sources count lang=", lg);
            let count = 0;
            try {
                let res = await this.$api.get("/sources_count/language?lang=" + lg);
                count = res.data + 1
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.source.ref = lg.toUpperCase() + ("00" + count).slice(-3);
            }
        },

        searchCities: async function () {
            console.log("search cities", this.source.country)
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/cities/" + this.source.country);
                this.cities = res.data
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        setToToday() {
            this.source.ok_at = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        },

        //* PAYLOAD
        addPayload(source) {

            // add city data
            for (let c of this.cities){
                if (c.name == this.city) {
                    source.city_name = this.city;
                    source.city_lon = c.lon;
                    source.city_lat = c.lat;
                }
            }

            // add ok_at
            if (!this.source.ok_at) {
                this.source.ok_at = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            }

            // trim
            this.source.url = this.source.url.trim();

            // json cards payload
            if (this.cards.length > 0) {
                let cards_payload = [];
                for (let card of this.cards) {
                    // we need to delete card.Id
                    // if not the DB reattached the same cards with same ID in db
                    // meaning the same order for cards
                    // as a result we cant move up cards
                    delete card.id;
                    cards_payload.push(JSON.stringify(card));
                }
                source.cards_payload = cards_payload;
            }

            return source;
        },

        //* EVENTS
        eventSaveSource(feed_source) {
            this.source = feed_source;
            this.save();
        },

        eventFeeds(feeds) {
            this.feeds= feeds;
        },

        eventSoFeeds(feeds) {
            this.so_feeds = feeds;
        },

        //* CRUD
        create: async function () {
            console.log("create");

            if (!this.source.city_name) {
                window.alert("No city !");
                return
            }

            this.loading = true;
            this.error = null;
            try {
                await this.$api.post("/sources", this.addPayload(this.source).format());
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.$router.go(-1);
                }
            }
        },

        save: async function () {

            if (!this.source.city_name) {
                window.alert("No city !");
                return
            }

            this.loading = true;
            this.error = null;
            try {
                var payload = this.addPayload(this.source);
                await this.$api.patch("/sources/update/" + this.source.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.snackbar = true;
                }
            }
        },

        remove: async function () {
            if (!confirm('Remove source "' + this.source.name + '"?')) {
                return;
            }

            this.loading = true;
            this.error = null;

            try {
                await this.$api.delete("/sources/delete/" + this.source.id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.$router.go(-1);
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            languagesStore: 'getLanguages',
            countriesStore: 'getCountries',
        }),
    }
};
</script>

<style>
</style>