<template>
    <div id="main-content">
        
        <System v-if="$rbac.is_user_logged_in() && !isMobile"/>
        <Bar v-else/>

        <!-- DESKTOP DRAWER -->
        <!-- mobile drawer in bar.vue -->
        <v-navigation-drawer 
            v-if="!isMobile"
            app 
            clipped
            fixed
            expand-on-hover
            :mini-variant.sync="mini"
            permanent
            width="220"
            color="Drawer"
        >   
            <NavList/>
            <!-- Conditions -->
            <v-card v-if="!mini" flat color="transparent">
                <v-card-text class="pb-0">
                   <router-link to="/main/privacy" class="grey--text text-decoration-none text-caption">{{$i18n.t("policyPrivacy")}}</router-link> 
                    <router-link to="/main/cookies" class="grey--text text-decoration-none text-caption"> · {{$i18n.t("policyCookies")}}</router-link>
                    <router-link to="/main/terms" class="grey--text text-decoration-none text-caption"> · {{$i18n.t("policyTermsConditions")}}</router-link>
                    <router-link to="/main/disclaimer" class="grey--text text-decoration-none text-caption"> · {{$i18n.t("policyLegalDisclaimer")}}</router-link>
                </v-card-text>
                
            </v-card>
        </v-navigation-drawer>

        <!-- Page content -->
        <v-main class="pa-0" >
            <v-container class="pa-0" fluid>
                <router-view/>
            </v-container>  
        </v-main>

        <Footer v-if="!isMobile"/>   
    </div>
</template>

<script>
import Bar from '../../views/components/Bar'
import System from '../components/System'
import Footer from '../../views/components/Footer'
import NavList from '../../views/components/NavList'

export default {

    name: 'Main',

    components: {
        Bar,
        System,
        NavList,
        Footer
    },

    data: () => ({
        mini: true,
        isMobile: false,
    }),

    created() {
        this.isMobile = this.$app.retriveIsMobile()
    },
   
    methods: {
    },

}
</script>

<style>

</style>