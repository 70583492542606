<template>
    <div>

        <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table
            :headers="[
                { text: 'Labels - Name', value: 'name', align:'left', class:'text-h6 accent--text py-2' },
                { text: 'Ref', value: 'ref', align:'left', class:'text-h6 accent--text py-2' },
                { text: 'Desc', value: 'description', align:'left', class:'text-h6 accent--text py-2' },
                { text: 'Needs [OR]', value: 'needs_ref', align:'left', class:'text-h6 accent--text py-2' },
            ]"
            :items="tags"
            :loading="loading"
            :search="search"
            :item-class= "row_classes" 
            :footer-props="{
                itemsPerPageText:'tags par page',
                itemsPerPageOptions: [200,400,-1],
            }"
            dense
            
        >
            <v-progress-linear slot="progress" color="purple" indeterminate></v-progress-linear>

            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->   
                    <v-toolbar-title class="accent--text text-h5 font-weight-bold">TAGS<span v-if="total > 1" class="ml-2">({{total}})</span></v-toolbar-title>

                    <v-spacer></v-spacer>
                    <!-- TABLE SEARCH -->
                    <v-text-field
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))'
                        hide-details
                        clearable
                        @click:clear="onClearSearch"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    
                    <!-- NEW BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>

                </v-toolbar>
            </template>


            <!-- name -->
            <template v-slot:[`item.name`]="{ item }" >
                <v-icon v-if="item.has_label" @click="goToLabels(item)" class="mr-6" color="purple lighten-2">mdi-alpha-l-circle-outline</v-icon>
                <v-icon v-else @click="goToLabels(item)" class="mr-6" color="warning lighten-2">mdi-alpha-l-circle-outline</v-icon>
                <v-btn v-if="item.level == 0" @click="goToTag(item)" :color="row_classes(item)" class="font-weight-bold">{{item.name.toUpperCase()}}</v-btn>
                <v-btn v-if="item.level == 1" @click="goToTag(item)" :color="row_classes(item)" class="font-weight-bold ml-4">{{$tools.capitalizeString(item.name)}}</v-btn>
                <v-btn small v-if="item.level == 2" @click="goToTag(item)" :color="row_classes(item)" class="font-weight-bold ml-8">{{$tools.capitalizeString(item.name)}}</v-btn>
                <v-btn small v-if="item.level == 3" @click="goToTag(item)" :color="row_classes(item)" class="font-weight-bold ml-12">{{$tools.capitalizeString(item.name)}}</v-btn>
            </template>

            <!-- go to labels -->
            <template v-slot:[`item.labels`]="{ item }">
                <v-icon @click="goToLabels(item)" class="" color="yellow">mdi-arrow-right-drop-circle</v-icon>
            </template>

            <!-- level -->
            <template v-slot:[`item.level`]="{ item }">
                <span class="mr-8">{{item.level}}</span>
            </template>

            <!-- Description tooltip -->
            <template v-slot:[`item.description`]="{ item }">
                <v-tooltip bottom nudge-left max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            v-if="item.description.length > 0"
                            v-bind="attrs"
                            v-on="on"
                            color="accent"
                        >
                            mdi-help-circle
                        </v-icon>
                    </template>
                    <span v-html="tooltipFormat(item.description)"></span>
                </v-tooltip>
            </template>
        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

    </div>
</template>

<script>
import tags from "@/common/tags";
import { mapGetters,mapActions } from 'vuex';

export default {

    name: 'Tags',

    data: () => ({
        //tags: [],
       
        //set up
        loading: false,
        total: null,
        options: {
            itemsPerPage: 15,
        },
        search: null,

        // api error
        error: null,

        // categories
        selectRef: null,
    }),

    props: ['tags','channel'],

    created() {
        this.search = this.searchStore
    },

    watch: {
        search: {
            deep: true,
            immediate: false,
            handler() {
                this.updateTagsSearch(this.search)
            }
        }
    },


    methods: {

        ...mapActions(['updateTagsSearch']),

        goToTag(item) {
            this.$router.push({name: "tag", params: {ref: item.ref, channel: this.channel}})
            tags.setScroll(window.scrollY)
        },

        create() {
            this.$router.push({name: "tag", params: {ref: "new", channel: this.channel}})
            tags.setScroll(window.scrollY)
        },

        goToLabels(item) {
            this.$router.push({name: "labels", params : {tag_ref: item.ref, channel: this.channel}})
            tags.setScroll(window.scrollY)
        },

        // colors the whole row !
        // style in template individual cell
        row_classes(item) {
            let c = ""
            switch (item.level) {
                case 1:
                    c = "warning--text";
                    break;
                case 2:
                    c = "green--text";
                    break;
                case 3:
                    c = "yellow--text";
                    break;
                default:
                    break;
            }
            return c
        },

        onClearSearch() {
            console.log("clearing search")
            this.search = null;
        },

        // display "\n" in description
        tooltipFormat(description) {
            return description.replace(/\n/g, '<br>');
        },
    },

    computed: {
        ...mapGetters({
            searchStore: 'getTagsSearch',
        }),
    },

}
</script>

<style>
.v-btn {
  text-transform:none !important;
}

.v-tooltip__content {
    opacity: 1 !important
}

</style>