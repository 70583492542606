<template>
    <div>
        <v-card flat outlined class="ma-1">
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <v-data-table
                :headers="[]"
                :loading="loading"
                :items="events"
                :footer-props="{
                    itemsPerPageText:'sources par page',
                    itemsPerPageOptions: [50,100,-1],
                }"
                dense
            >
                <v-progress-linear slot="progress" :color="color" indeterminate></v-progress-linear>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'SlotEventsTable',

    props: ['color'],

    data: () => ({
        loading: false,
        error: null,

        events: [],

        options: {
            itemsPerPage: 50,
        },
    }),


}
</script>

<style>

</style>