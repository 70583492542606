<template>
    <div>
        <v-card flat class="transparent">
            
            <v-card flat tile
                class="d-flex flex-wrap"
                color="transparent">
                
                <v-card flat tile color="transparent">
                    <!-- goToTags button -->
                    <v-icon v-if="stream == 'post'" @click="goTags" small class="mr-2" color="purple lighten-2">mdi-alpha-t-circle-outline</v-icon>
                    <v-icon v-if="stream == 'single'" @click="goTags" class="mr-2" color="purple lighten-2">mdi-alpha-t-circle-outline</v-icon>
                </v-card>

                <!-- country channel -->
                <v-card flat tile color="transparent">
                    <v-icon v-if="widgetTags && tagsByChannel['country'].length > 0" small left class="mr-0 pb-1" color="light-blue">mdi-earth</v-icon>
                    <span v-for="(t) of tagsByChannel.country" class="light-blue--text text-subtitle-2" :key="t.ref">
                        {{tagName(t,stream)}}
                        <v-icon @click="removeTag(t.ref)" color="light-blue" small>mdi-close-circle</v-icon>
                        <span v-if="stream == 'post_corpus'" class="ml-2">#{{t.db_id}}</span>
                    </span>
                </v-card>

                <!-- crimorg channel -->
                <v-card flat tile color="transparent">
                    <v-icon v-if="tagsByChannel['crimorg'].length > 0" small left class="ml-2 mr-1 pb-1" color="yellow">mdi-account-group</v-icon>
                    <span v-for="(t) of tagsByChannel.crimorg"  :key="t.ref">
                        <!-- description tool tip -->
                        <v-tooltip v-if="t.description.length > 0" bottom max-width="700" class="mt-3">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="goToTag(t)"
                                tile
                                outlined
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1 yellow--text text-subtitle-2 pt-1 px-1 mr-1"
                                >
                                    {{tagName(t,stream)}}
                                </v-btn>
                            </template>
                            <span v-html="tooltipFormat(t.description)"></span>
                        </v-tooltip>
                        <span v-else @click="goToTag(t)" style="cursor: pointer" class="yellow--text text-subtitle-2"> {{tagName(t,stream)}}</span>

                        <v-icon @click="removeTag(t.ref)" class="ml-1 mr-1" color="yellow" small>mdi-close-circle</v-icon>
                        <span v-if="stream == 'post_corpus'" class="ml-1">#{{t.db_id}}</span>
                    </span>
                </v-card>

                <!-- crimorg confirm tags -->
                <!-- <v-card v-if="tab == false" flat tile color="transparent">
                    <v-icon v-if="stream == 'post' && !confirmed && tagsByChannel.crimorg.length > 0" @click="confirmCrimorgTags" small class="ml-1" color="yellow">mdi-checkbox-outline</v-icon>
                    <v-icon v-if="stream == 'post' && confirmed && tagsByChannel.crimorg.length > 0" @click="confirmCrimorgTags" small class="ml-1" color="yellow">mdi-checkbox-marked</v-icon>

                    <v-icon small v-if="stream == 'single' && !confirmed && tagsByChannel.crimorg.length > 0" @click="confirmCrimorgTags" class="ml-1" color="yellow">mdi-checkbox-outline</v-icon>
                    <v-icon small v-if="stream == 'single' && confirmed && tagsByChannel.crimorg.length > 0" @click="confirmCrimorgTags" class="ml-1" color="yellow">mdi-checkbox-marked</v-icon>
                </v-card> -->


                <!-- market channel -->
                <v-card flat tile color="transparent">
                    <v-icon v-if="tagsByChannel['market'].length > 0" left small class="ml-2 mr-0 pb-1" color="lime">mdi-cash-multiple</v-icon>
                    <span v-for="(t) of tagsByChannel.market" class="lime--text text-subtitle-2" :key="t.ref">
                        
                        <!-- description tool tip -->
                        <v-tooltip v-if="t.description.length > 0" bottom max-width="700" class="mt-3">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="goToTag(t)"
                                tile
                                outlined
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1 lime--text text-subtitle-2 pt-1 px-1 mr-1"
                                >
                                    {{tagName(t,stream)}}
                                </v-btn>
                            </template>
                            <span v-html="tooltipFormat(t.description)"></span>
                        </v-tooltip>
                        <span v-else @click="goToTag(t)" style="cursor: pointer" class="lime--text text-subtitle-2"> {{tagName(t,stream)}}</span>
                        
                        <v-icon @click="removeTag(t.ref)" class="ml-0 mr-1" color="lime" small>mdi-close-circle</v-icon>
                        <span v-if="stream == 'post_corpus'" class="ml-1">#{{t.db_id}}</span>
                    </span>
                </v-card>

                <!-- mean channel -->
                <v-card flat tile color="transparent">
                    <v-icon v-if="tagsByChannel['mean'].length > 0" left small class="ml-2 mr-0 pb-0" color="red">mdi-decagram</v-icon>
                    <span v-for="(t) of tagsByChannel.mean" class="red--text text-subtitle-2" :key="t.ref">
                        {{tagName(t, stream)}}
                        <v-icon @click="removeTag(t.ref)" class="ml-0 mr-1" color="red" small>mdi-close-circle</v-icon>
                        <span v-if="stream == 'post_corpus'" class="ml-1">#{{t.db_id}}</span>
                    </span>
                </v-card>

                <!-- law channel -->
                <v-card flat tile color="transparent">
                    <v-icon v-if="tagsByChannel['law'].length > 0" left small class="ml-2 mr-0 pb-0" color="deep-purple lighten-3">mdi-police-badge</v-icon>
                    <span v-for="(t) of tagsByChannel.law" class="deep-purple--text text--lighten-3 text-subtitle-2" :key="t.ref">
                         <!-- description tool tip -->
                         <v-tooltip v-if="t.description.length > 0" bottom max-width="700" class="mt-3">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                @click="goToTag(t)"
                                tile
                                outlined
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                class="ml-1 deep-purple--text text--lighten-3 text-subtitle-2 pt-1 px-1 mr-1"
                                >
                                    {{tagName(t,stream)}}
                                </v-btn>
                            </template>
                            <span v-html="tooltipFormat(t.description)"></span>
                        </v-tooltip>
                        <span v-else @click="goToTag(t)" style="cursor: pointer" class="deep-purple--text text--lighten-3 text-subtitle-2"> {{tagName(t,stream)}}</span>
                        <v-icon @click="removeTag(t.ref)" class="ml-0 mr-1" color="deep-purple lighten-3" small>mdi-close-circle</v-icon>
                        <span v-if="stream == 'post_corpus'" class="ml-1">#{{t.db_id}}</span>
                    </span>
                </v-card>
                
                <!-- ADD tags -->
                <v-btn v-if="status == 'old'" 
                    small 
                    @click="openDialogAddTag" 
                    color="transparent"
                    class="ml-2">
                        <v-icon color="purple lighten-2">mdi-plus-circle-outline</v-icon>
                </v-btn>

                <!-- DIALOG ADD TAG -->
                <v-dialog v-model="dialog" width="400">
                    <v-card>

                        <!-- loading -->
                        <v-progress-linear
                            indeterminate
                            color="purple lighten-2"
                            :active="addTagLoading">
                        </v-progress-linear>

                        <v-card-title class="purple lighten-2">ADD POST TAG</v-card-title>

                        <v-card-text class="pt-2">
                           
                            <v-autocomplete v-if="searchTagByName"
                                outlined
                                dense
                                v-model="addTag"
                                :items="tagsList"
                                item-text="name"
                                color="purple lighten-2"
                                clearable
                                required
                                class="mt-2"
                                return-object
                                hide-details
                            ></v-autocomplete>
                            <v-autocomplete v-else
                                outlined
                                dense
                                v-model="addTag"
                                :items="tagsList"
                                item-text="ref"
                                color="purple lighten-2"
                                clearable
                                required
                                class="mt-2"
                                return-object
                                hide-details
                            ></v-autocomplete>
                          
                        </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn rounded small v-if="searchTagByName" @click="searchTagByName = !searchTagByName">Search by name</v-btn>
                        <v-btn rounded small v-else @click="searchTagByName = !searchTagByName">Search by ref</v-btn>

                        <v-spacer></v-spacer>
                        <v-btn small
                            color="purple lighten-2"
                            @click="addNewTag()"
                        >
                            Add
                        </v-btn>

                        <v-btn small
                            color=""
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
                
                <!-- UPDATE TAGS -->
                <v-btn v-if="status == 'new'" 
                    small 
                    @click="updateTags" 
                    :loading="loading"
                    :disabled="loading"
                    color="transparent"
                    class="ml-2">
                        <v-icon color="purple lighten-2">mdi-arrow-up-bold-circle-outline</v-icon>
                </v-btn>
            </v-card>
              
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
        </v-card>
    </div>
</template>

<script>
export default {
    
    name:'TagsWidget',

    props: ['tags','pid','stream','status','language','tab','are_tags_confirmed'],

    data: () => ({

        loading: false,
        error: null,

        tagsByChannel: {
            country: [],
            crimorg: [],
            market: [],
            mean: [],
            law: [],
        },

        widgetTags: [],
        initTagsRefList: [],
        widgetTagsRefList: [],

        // new tag
        dialog: false,
        dialogNew: false,
        addTag: null,
        addTagLoading: false,
        tagsList:[],

        confirmed: false,
        searchTagByName: true,
    }),

    created() {
        this.confirmed = this.are_tags_confirmed;
    },

    watch: {
        
        tags: {
            deep: true,
            immediate: true,
            handler() {
               
                this.tagsByChannel = {
                    country: [],
                    crimorg: [],
                    market: [],
                    mean: [],
                    law: [],
                }
    
                if (this.tags && this.tags.length > 0) {
                    for (let t of this.tags) {
                        this.tagsByChannel[t.channel].push(t);
                        // set init tags to detect changes
                        this.initTagsRefList.push(t.ref)
                        this.widgetTagsRefList.push(t.ref)
                    }
                }
                this.widgetTags = this.tags;

                // sort according to level
                // country is not sorted cause we want to keep highest count first
               
                this.tagsByChannel.law.sort((a, b) => b.level < a.level)
                this.tagsByChannel.mean.sort((a, b) => b.path < a.path)
                this.tagsByChannel.market.sort((a, b) => b.path < a.path)
                this.tagsByChannel.crimorg.sort((a, b) => b.path < a.path)

                // for country channel we dont follow path order, but count desc
                this.tagsByChannel.country.sort((a, b) => b.count > a.count)
        
            }

        },
    },

    methods: {

        //* Add Tag to post
        openDialogAddTag() {
            this.dialog = true;
            this.addTag = null;
            if (this.tagsList.length == 0) {
                this.fetchTags();
            }

        },

        fetchTags: async function() {
            console.log("fetching tags");
            this.addTagLoading = true;
            this.error = null;
            this.tagsList = [];
            try {
                let res = await this.$api.get("/tags");
                this.tagsList = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.addTagLoading = false;
            }
        },

        addNewTag: async function() {
            console.log("adding new tag=", this.addTag.ref);
            this.addTagLoading = true;
            this.error = null;

            let payload = {
                post_id: this.pid,
                ref: this.addTag.ref,
                channel: this.addTag.channel,
                language: this.language,
            }

            console.log(payload)

            try {
                await this.$api.post("/tagger/tag/widget/add", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.addTagLoading = false;
                this.dialog = false;
                this.$emit("eventTags");
            }
            
        },

        eventFetchTags() {
            this.$emit("eventFetchTags");
        },

        // detected by post/single components
        goTags() {
            this.$emit("eventGoTags");
        },

        
        // used to confirm tags channel=crimorg in trainerTab.crimorg
        confirmCrimorgTags: async function() {
            console.log("confirming crimorg tags")
            this.loading = true;
            try {
                await this.$api.get("/tagger/tags/widget/confirm/" + this.pid);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.confirmed = !this.confirmed;
            }
        },

        updateTags: async function () {
            console.log("updating tags")
            this.loading = true;
            this.error = null;

            // payload
            let payload = {
                pid: this.pid,
                tags: [],
                error: 0,
                language: this.language,
            };

            // monitor if changed in tags made by user => posts.tags_error = 1
            if (this.isChanged(this.widgetTagsRefList,this.initTagsRefList)) {
                payload.error = 1;
            }

            // json cards payload
            if (this.tags.length > 0) {
                let tags_payload = [];
                // build payload if update
                for (let t of this.widgetTags) {
                    // bypass tags filtered out
                    if (!t.is_filtered_out) {
                        tags_payload.push(JSON.stringify({
                            ref: t.ref,
                            channel: t.channel,
                        }))
                    }
                }
                payload.tags = tags_payload;
            }

        
            try {
                await this.$api.post("/tagger/tags/widget/update",payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.$emit("eventTags");
            }
        },

        // detect change between init/widget tags
        isChanged(array1, array2) {
            if (array1.length !== array2.length) {
                return true;
            }
            
            const array2Sorted = array2.slice().sort();
            const array1Sorted = array1.slice().sort();
            
            for (let i = 0; i < array1Sorted.length; i++) {
                if (array1Sorted[i] !== array2Sorted[i]) {
                    return true;
                }
            }
            
            return false;
        },

        removeTag(ref) {
            
            console.log("removing tag:", ref)
            let updatedTags = [];
            this.widgetTagsRefList = [];
            this.tagsByChannel = {
                country: [],
                crimorg: [],
                market: [],
                mean: [],
                law: [],
            }
            for (let t of this.widgetTags) {
                if (t.ref != ref) {
                    updatedTags.push(t);
                    this.widgetTagsRefList.push(t.ref)
                    this.tagsByChannel[t.channel].push(t);
                }
            }

            this.widgetTags = updatedTags
            this.updateTags();
        },

        eventGoTags() {
            this.$emit("eventGoTags");
        },

        goToTag(t) {
            this.$router.push({path: "/main/tag/" + t.ref})
        },

        tagName(t, stream) {
            let tagName = this.$tools.capitalizeString(t.name)
            
            // is_filtered out
            if (t.is_filtered_out && stream == "tagsPostTab") {
                tagName = "OUT " +  tagName + " | [" + t.needs_ref + "]"
            }

            return tagName
        },


        // display "\n" in description
        tooltipFormat(description) {
            return description.replace(/\n/g, '<br>');
        },
    },


}
</script>

<style>
.v-tooltip__content {
    opacity: 1 !important
}
</style>