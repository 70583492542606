import api from "@/common/api";
import auth from "@/common/auth";
import roles from "@/roles/roles";

export default {

    // role is an array in main/Drawer/items
    drawerAuthorize(role) {
        let tokenRole = auth.retriveUserField("role");
        if (tokenRole) {
            // check if route is restricted by role
            if (role.length && role.includes(tokenRole)) {
                // role not authorised so redirect to specific page
                return true;
            }
        } else if (role.length == 0 && !tokenRole){
            return true;
        }
        return false
    },

    // check if token is expired
    is_user_logged_in() {
        if (auth.retriveRefresher()) {
            return true
        } else {
            return false
        }
    },

    // used when token is expired
    // trough referesher ask for a new one
    // if refresher is expired, is_logged_in stays falsy
    checkRefresher: async function () {
        try {
            let res = await api.pre("post","/auth/refresh",{token: auth.retriveToken(), refresher: auth.retriveRefresher()})
            if (res.data.token) {
                console.log("check refresher updates token")
                auth.setToken(res.data.token);
            }
        } catch (e) {
            let data = (e.response || {}).data || "unknown error";
            console.log("error msg in checkRefresher", data.message);
        } 
    },

    get_user_role_name() {
        return auth.retriveUserField("name");
    },

    get_user_role() {
        if (auth.retriveUserField("role") != "jjmaster") {
            return auth.retriveUserField("role");
        } else {
            return "admin"
        }
    },

    // get user level from roles.js
    get_user_level: function() {
        let rls = roles.retrive();
        console.log(rls)
        for(let key of Object.keys(rls)) {
            if (auth.retriveUserField("role") == rls[key].tokenCode) {
                return rls[key].level;
            }
        }  
        return null;
    },

    is_admin_level: function () {
        let rls = roles.retrive();
        if (this.get_user_level() >= rls["admin"].level) {
            return true
        }
        return false
    },

}