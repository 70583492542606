<template>
    <div>
        <v-card v-if="position == 'vertical'" flat class="" width="80" height="145" color="transparent">
            <v-card-text class="pa-1">
                <!-- Agree -->
                <v-btn 
                    v-if="post.softmax >= 0.5"
                    x-small elevation="0" 
                    @click="agree" 
                    :loading="loading" 
                    class="my-3"
                    color="transparent"
                    :width="buttonWidth">
                        <v-icon color="green">mdi-check-circle</v-icon>
                </v-btn>
                
                <!-- Disagree -->
                <!-- SLAVE is right, Master wrong -->
                <v-btn v-if="post.softmax < 0.5" @click="slaveIsRight" x-small elevation="0"  :loading="loading" class="my-3" color="transparent"  :width="buttonWidth"><v-icon color="green">mdi-check-circle</v-icon></v-btn>
                
                <!-- MASTER is right, slave wrong -->
                <v-btn v-if="post.softmax < 0.5" @click="masterIsRight" x-small elevation="0"  :loading="loading"  color="transparent"  :width="buttonWidth"><v-icon color="warning">mdi-delete-circle</v-icon></v-btn>
                
                <v-btn rounded small elevation="0" class="accent--text font-weight-bold" color="transparent">S:{{slaveSoftmaxPercentage}}</v-btn>
                <v-btn rounded small elevation="0" class="accent--text font-weight-bold" color="transparent">M:{{softmaxPercentage}}</v-btn>

            </v-card-text>
        </v-card>

        <v-card v-else flat outlined width="300" class="py-1" color="transparent">
            <v-card-actions>
                <v-btn rounded x-small fab elevation="0" :color="levelButton.color" :class="levelButton.class + ''">
                    <v-icon large>mdi-{{ levelButton.icon }}</v-icon>
                </v-btn>
                <v-spacer v-if="view !='subscriber'"></v-spacer>
                <v-btn v-if="view !='subscriber'" small elevation="0" @click="vote(1)" :loading="loading" class="Dialog--text" :width="buttonWidth"  color="transparent"><v-icon color="green">mdi-check-circle</v-icon></v-btn>
                <!--<v-btn small elevation="0" @click="vote(0)" :loading="loading" class="Dialog--text" :width="buttonWidth"  color="transparent"><v-icon color="warning">mdi-delete-circle</v-icon></v-btn>-->
                <v-spacer></v-spacer>
                <v-icon small class="" color="accent">mdi-robot</v-icon><v-btn rounded small elevation="0" class="mt-1 accent--text font-weight-bold" color="transparent" :width="buttonWidth">MASTER {{softmaxPercentage}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'SlaverWidget',

    props:['post','index','position','view', 'classifier'],

    created() {
        this.setPatch();
    },

    data: () => ({
        loading: false,
        buttonWidth: 75,
        levelButton: {
            icon: null,
            color: null,
            class: null,
        },
    }),

    watch: {
        post: {
            deep: true,
            immediate: true,
            handler() {},
        }
    },

    methods: {

        agree:async function() {
            this.loading = true
            this.$emit('eventVote', this.index);
            try {
                await this.$api.post("/profile/slaver/agree?classifier=" + this.classifier, {slave_url: this.post.url});
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },
        
        disagree:async function(whoIsRight) {
            this.loading = true
            this.$emit('eventVote', this.index);
            try {
                await this.$api.post("/profile/slaver/disagree?&classifier=" + this.classifier + "&is_right=" + whoIsRight, {slave_url: this.post.url});
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        slaveIsRight() {
            this.disagree("SLAVE");
        },

        masterIsRight() {
            this.disagree("MASTER");
        },
        

        setPatch() {
        
            switch (this.post.source_level) {
                case 0:
                this.levelButton = {
                    icon: "alpha-h",
                    color: "Dialog",
                    class: " accent--text",
                }
                break;

                case 1:
                this.levelButton = {
                    icon: "alpha-p",
                    color: "red",
                    class: "white--text",
                }
                break;

                case 2:
                this.levelButton = {
                    icon: "alpha-f",
                    color: "white",
                    class: "red--text",
                }
                break;
            }
        },
    },
    
    computed: {
        softmaxPercentage() {
            return Math.round(this.post.softmax * 100) + "%";
        },

        slaveSoftmaxPercentage() {
            return Math.round(this.post.slave_softmax * 100) + "%";
        },
    }
}
</script>

<style>
</style>