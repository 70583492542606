<template>
    <div>

        <v-sheet rounded outlined :color='source.has_feed_interceptor ? "warning" : "accent"' key="feed-test">
            <v-card flat>

                
                <!-- loading -->
                <v-progress-linear
                    indeterminate
                    color="accent"
                    :active="loading">
                </v-progress-linear>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn x-small @click="fetchTestFeeds" class="Dialog--text font-weight-bold"  color="accent">FETCH FEEDS</v-btn>
                    <v-spacer></v-spacer>
                    {{total}} feeds <span v-if="delay > 0 && !loading" class="ml-1">- [delay {{Math.round(100 * delay)/100}} s] </span>
                    <v-spacer></v-spacer>
                </v-card-actions>
                
                <v-divider></v-divider>
                
                <!-- error msg -->
                <v-card-text v-if="error" class="red--text py-0 text-center">{{ error }}</v-card-text>
                
                <v-card v-for="(item, index) in feeds" :key="index" flat outlined class="d-flex mt-1 mx-1" color="">
                    <v-card flat max-width="60" class="pa-2">
                        <v-btn x-small outlined class="accent--text font-weight-bold text-body-2" color="accent">#{{index+1}}</v-btn> 
                        <v-btn x-small depressed fab class="mt-2 ml-1" @click="goArticle(item.url)" color="accent darken-2">
                            <v-icon color="Dialog">mdi-eye</v-icon>
                        </v-btn>
                    </v-card>
                    <v-card color=""  flat class="d-flex px-2" width="100%">
                        <v-avatar v-if="item.img_url && item.img_url != 'no img found' && item.img_url.length > 0 && !item.imgError" 
                            tile
                            size="100"
                            class="mt-3"
                        >
                            <v-img  
                                :src="item.img_url"
                                @error="item.imgError = true"
                            ></v-img>
                        </v-avatar>
                        <v-icon v-if="item.imgError" color="error">mdi-image-broken-variant</v-icon>
                        <v-card flat color="transparent" width="100%">
                            <v-card-subtitle class="py-0 amber--text text--accent-3">{{item.url}}</v-card-subtitle>
                            <v-card-title class="text-body-1 accent--text wordbreak pt-1"><span v-html="item.title"></span></v-card-title>
                            <v-card-subtitle >
                                <span v-if="item.categories.length > 0" class="wordbreak warning--text pt-0"><strong>Categories:</strong> {{item.categories}}</span>
                            </v-card-subtitle>
                           
                            <v-card-subtitle class="py-0"><span class="mr-1">{{formatDate(item.date)}} :</span>{{item.description}}</v-card-subtitle>
                        </v-card>
                    </v-card>
                
                </v-card>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import moment from "moment";

export default {

    name: 'FeedTest',

    props: ['source','isNew'],

    data: () => ({
        loading: false,
        tab: null,
        feeds: null,
        delay: 0,
        total: 0,
        error: null,
        dialog: false,
    }),

    created() {
        if (this.source.stream_type.length > 0) {
            this.fetchTestFeeds();
        }
    },

    methods: {

        fetchTestFeeds: async function() {

            this.loading = true;
            this.feeds = null;
            this.total = 0;
            this.error = null;
            this.delay = 0;

            try {
                let res = await this.$api.post("/sources/feed/test", this.source);
                let feeds = [];
                for (let feed of res.data.feeds) {
                    feeds.push(feed)
                }
                this.feeds = feeds;
                this.delay = res.data.delay
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.$emit("eventFeeds", this.feeds);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.dialog = true;
            }
        },

        goArticle(url) {
            window.open(url, '_blank');
        },


        formatDate(date) {
            return moment(date).format("DD/MM/YY@HH:MM")
        },


    },

}
</script>

<style>
.wordbreak {
    word-break: normal;
}

</style>