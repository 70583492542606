<template>
    <div>
        <v-menu
            max-width="230px"
            offset-y
            absolute
            :position-y="menuOffset"
            :close-on-content-click="false"
            key="user-menu-view"
        >
            <!-- MENU NAME -->
            <template v-slot:activator="{ on, attrs }">
                
                <!-- BTN used to place the menu below the bar -->
                <!-- bug: system needs an offset in icon provided in props iconOffsetClass... -->
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                    color="Dialog"
                    class="no-highlight"
                    key="anchor-dialog"
                >
                    <v-icon 
                    color="accent" 
                    :class="iconOffsetClass">mdi-account</v-icon>
                </v-btn>

            </template>
            
            <v-list color="UserMenu" key="header">
                <v-list-item>
                    <!--<v-list-item-avatar>-->
                        <!--<img src="https://cdn.vuetifyjs.com/images/john.jpg">-->
                     <!--</v-list-item-avatar>-->

                    <v-list-item-content>
                        <v-list-item-title>{{user.name}}</v-list-item-title>
                        <v-list-item-subtitle>{{$i18n.t("role")}}: {{$i18n.t($rbac.get_user_role())}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>

            <v-list class="py-0" color="UserMenu" key="account-password-lang-theme">

                <!-- ACCOUNT -->
                <v-list-item>
                    <v-list-item-title @click="accountDialog = true" style="cursor: pointer">{{$i18n.t("accountYour")}}</v-list-item-title>
                </v-list-item>

                <!-- CHANGE PASSWORD -->
                <v-list-item>
                    <v-list-item-title @click="passwordDialog = true" style="cursor: pointer">{{$i18n.t("changeYourPassword")}}</v-list-item-title>
                </v-list-item>

                <!-- LANGUAGES SELECTION-->
                <v-divider></v-divider>
                <v-list-item>
                    <v-select
                        v-model="user.language_ui"
                        :items="ui_languages"
                        item-text="name"
                        item-value="iso"
                        dense
                        :label='$i18n.tc("language",2)'
                        hide-details
                        :menu-props="{ bottom: true, offsetY: true }"
                        @change="switchLang"
                        class="py-4"
                    ></v-select>
                </v-list-item>
                
                <!-- LIGHT/DARK THEME -->
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{$tools.capitalizeWord($i18n.t("theme"))}}</v-list-item-title>
                        <v-radio-group row v-model="theme" class="justify-center">
                            <v-radio :label='$i18n.t("light")' value="light" @change="switchTheme" color="accent"></v-radio>
                            <v-radio :label='$i18n.t("dark")' value="dark" @change="switchTheme" color="accent"></v-radio>
                        </v-radio-group>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

            </v-list>

            <!-- LOG OUT  -->
            <v-list class="py-0" color="UserMenu" key="logout">
                <v-list-item>
                    <v-list-item-title @click="logout" style="cursor: pointer" class="ml-1 accent--text">{{$i18n.t("signout")}} </v-list-item-title>
                </v-list-item>
            </v-list>

            <!-- My Account Dialog  -->
            <v-dialog v-model="accountDialog" max-width="250px" key="dialog-account">
                <v-card color="UserMenu" >
                    <v-progress-linear
                        :active="loading"
                        color="accent"
                        indeterminate
                    ></v-progress-linear>
                    <v-card-title class="justify-center text-h5">{{$i18n.t("accountYour")}}</v-card-title>
                    <v-container>
                        <v-row>

                            <!-- DISABLED : Login/Email -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="user.email"
                                    label="Login/Email *"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            
                            <!-- EDIT AVAILABLE: Name -->
                            <v-col cols="12">
                                <v-row>
                                    <v-text-field
                                        dense
                                        v-model="user.name"
                                        :label='$i18n.t("name")'
                                        :rules="[rules.required, rules.min2]"
                                        :disabled="!nameEdit"
                                        class="mx-3 red--text"
                                    ></v-text-field>

                                    <!-- edit btn -->
                                    <v-btn v-if="!nameEdit"
                                    small icon
                                    class="mr-2"
                                    @click="nameEdit = !nameEdit">
                                        <v-icon small color="accent">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn v-else
                                    small icon
                                    class="mr-2"
                                    @click="closeEditName">
                                        <v-icon small color="accent">mdi-close-circle</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>

                            <v-col cols="12">
                                <v-text-field
                                    dense
                                    :value="$i18n.t($rbac.get_user_role())"
                                    :label='$i18n.t("role")'
                                    disabled
                                ></v-text-field>
                            </v-col>

                        </v-row>
                     </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="accent" text @click="confirmChangeAccount">{{$i18n.t("confirm")}}</v-btn>
                        <v-btn color="accent" text @click="accountDialog = false">{{$i18n.t("cancel")}}</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Change Password dialog windows  -->
            <v-dialog v-model="passwordDialog" max-width="500px" key="dialog-password">
                <v-card color="UserMenu" >

                     <v-progress-linear
                        :active="loading"
                        color="accent"
                        indeterminate
                    ></v-progress-linear>

                    <v-window v-model="passwordStep">

                        <!-- WINDOW #1: Current password -->
                        <v-window-item :value="1">
                                <v-card color="UserMenu">
                                    <v-card-title class="justify-center font-weight-bold">{{$i18n.t("passwordCurrentConfirm") }}</v-card-title>
                                    <v-card-text>
                                        <v-form v-model="isCurrentPasswordValid">
                                            <v-text-field
                                                flat rounded solo single-line
                                                background-color="Dialog"
                                                v-model="currentPassword"
                                                :append-icon="showPasswordCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules.min8, rules.oneDigit, rules.oneCapital]"
                                                :type="showPasswordCurrent ? 'text' : 'password'"
                                                :label='$i18n.t("password") + "*"'
                                                @click:append="showPasswordCurrent = !showPasswordCurrent"
                                            ></v-text-field>

                                            <span class="ml-5 accent--text">{{$i18n.t(passwordError)}}</span>
                                        </v-form>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-btn
                                            :disabled="passwordStep === 1"
                                            text
                                            @click="passwordStep--"
                                        >
                                            {{$i18n.t("previous")}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            :disabled="!isCurrentPasswordValid"
                                            color="accent"
                                            depressed
                                            @click="passwordStep++"
                                        >
                                            {{$i18n.t("next")}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                        </v-window-item>

                        <!-- WINDOW #2: new password -->
                        <v-window-item :value="2">
                            <v-card color="UserMenu">
                                <v-card-title class="justify-center font-weight-bold">{{$i18n.t("passwordNewConfirm") }}</v-card-title>
                                <v-card-text>
                                    <v-form v-model="isNewPasswordValid">
                                        <!-- New password -->
                                        <v-text-field
                                            flat rounded solo single-line
                                            background-color="Dialog"
                                            v-model="newPassword"
                                            :append-icon="showPasswordNew ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.min8, rules.oneDigit, rules.oneCapital, newPasswordDifferentFromCurrentPassword]"
                                            :type="showPasswordNew ? 'text' : 'password'"
                                            :label='$i18n.t("password") + "*"'
                                            @click:append="showPasswordNew = !showPasswordNew"
                                        ></v-text-field>
                                        <!-- New password 2 -->
                                        <v-text-field
                                            flat rounded solo single-line
                                            background-color="Dialog"
                                            v-model="newPasswordBis"
                                            :append-icon="showPasswordNew ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[passwordMatch]"
                                            :type="showPasswordNew ? 'text' : 'password'"
                                            :label='$i18n.t("password")'
                                            @click:append="showPasswordNew = !showPasswordNew"
                                        ></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                        <v-btn
                                            :disabled="passwordStep === 1"
                                            text
                                            @click="passwordStep--"
                                        >
                                            {{$i18n.t("previous")}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            :disabled="!isNewPasswordValid"
                                            color="accent"
                                            depressed
                                            @click="changePassword"
                                        >
                                            {{$i18n.t("update")}}
                                        </v-btn>
                                    </v-card-actions>
                            </v-card>
                        </v-window-item>
                        

                        <!-- WINDOW #3 -->
                        <v-window-item :value="3">
                            <v-card color="UserMenu">
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                    <v-icon large color="green" class="mt-3">mdi-thumb-up</v-icon>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                                <v-card-text v-if="passwordSuccess" class="text-center" >
                                    {{$i18n.t("password_updated")}}
                                </v-card-text>
                            </v-card>
                        </v-window-item>
                    </v-window>

                </v-card>
            </v-dialog>

           
        </v-menu>
    </div>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/i18n-setup";
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';

// User CLASS
class User extends Model {
    id = null;
    name = null;
    email = null;
    role = null;
    password = null;
    theme = null;
    language_ui = null;
    countries = null;
    languages = null;
    packages = null;
    packages_payload = [];
}

export default {

    name: 'Menu',

    data: () => ({
        
        loading: false,
        user: null,

        rules: {
            required: v => !!v || 'Field required',
            min2: v => v && v.length >= 2 || 'Min 2 characters',
            min8: v => v && v.length >= 8 || 'Min 8 characters',
            oneDigit: v => v && /\d/.test(v) || 'At least one digit',
            oneCapital: v => v && /[A-Z]{1}/.test(v) || 'At least one capital letter',
        },

        // languages list
        ui_languages: [
            { iso:"en", name:"english" },
            { iso:"fr", name:"français" },
        ],

        // theme
        theme: "light",

        // dialogs
        accountDialog: false,
        nameEdit: false,

        // password
        passwordDialog: false,
        passwordStep: 1,

        currentPassword: null,
        showPasswordCurrent: false,
        isCurrentPasswordValid: false,

        newPassword: null,
        newPasswordBis: null,
        showPasswordNew: false,
        isNewPasswordValid: false,

        passwordSuccess: false,
        passwordError: null,


        error: null,
    }),

    // menu offset different between Bar and System
    props: ['menuOffset','iconOffsetClass'],

    created() {
        // init user parameters from token
        if (this.$auth.retriveUserField("theme") == "dark") {
            this.theme = "dark";
        }
        // update account data (lazy...)
        this.user = User.init();
        this.fetchUser();
    },

    methods: {
        
        //* lANG
        switchLang() {
            loadLanguageAsync(this.user.language_ui);
            this.updateUser();
        },

        //* THEME
        switchTheme() {
            if (this.theme == "light") {
                this.$vuetify.theme.dark = true;
                this.$vuetify.theme.light = false;
                this.theme = "dark";
                this.$auth.setUserField("theme","dark")
                this.user.theme = "dark";
            } else {
                this.$vuetify.theme.dark = false;
                this.$vuetify.theme.light = true;
                this.theme = "light";
                this.user.theme = "light";
                this.$auth.setUserField("theme","light")
            }
            // update local user
           
            // API update user TO DO
            this.updateUser();
        },

        //* LOG OUT
        logout() {
            this.$auth.clean();
            this.dialog = false;
            console.log("logout")
            //this.$router.go(); (dont work with safari)
            location.replace(this.$api.ui);  // ok Chrome + Safari
        },

        //* USER
        fetchUser: async function() {
            this.loading = true;
            let res = await this.$api.get("/users/me");
            this.user = User.parse(res.data);
            this.loading = false;
        },

        updateUser: async function() {
            this.loading = true;
            try {
                await this.$api.patch("/users/me", this.addPayload(this.user).format());
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.accountDialog = false;
            }
        },

        addPayload(user) {
            // sets payload: only id set
            if (user.packages.length > 0) {
                let packages_payload = [];
                for (let p of user.packages) {
                    packages_payload.push(p.id);
                }
                user.packages_payload = packages_payload;
            }
            return user
        },

        updatePasswordUser: async function() {
            this.loading = true;
            let inputs = {
                "password": this.currentPassword,
                "new_password": this.newPassword,
            }
            try {
                await this.$api.post("/password/change/me", inputs);
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.passwordError = data.message;
            } finally {
                this.loading = false;
            }

            switch (this.passwordError) {
                case "password_invalid":
                    this.passwordStep = 1;
                    break;
                    
                default:
                    this.passwordStep = 3;
                    this.passwordSuccess = true;
                    break;
            }
            
        },

        //* ACCOUNT DIALOG
        closeEditName() {
            this.nameEdit = false; 
            this.user.name = this.$auth.retriveUserField("name");
        },

        confirmChangeAccount() {
            this.updateUser()
        },

        //* PASSWORD DIALOG
        changePassword() {
            this.updatePasswordUser();
        }

    },

    computed: {

        ...mapGetters({
            userPkgLanguagesStore: 'getUserPkgLanguages',
        }),

        passwordMatch() {
            return this.newPassword === this.newPasswordBis || 'Password must match'
        },

        newPasswordDifferentFromCurrentPassword() {
            return this.newPassword !== this.currentPassword || this.$i18n.t("passwordDifferent")
        },
    },

}
</script>

<style>
.no-highlight::before {
    display: none;
}
</style>