export default {

    capitalizeString(input) {
        let tokens = input.split(" ");
        let output = "";
        for (let t of tokens) {
            if (t.length> 0) {
                output += t[0].toUpperCase() + t.substring(1) + " ";
            }
        }
        output = output.slice(0,-1)
        return output
    },

    capitalizeWord(word) {
        return word[0].toUpperCase() + word.substring(1);
    },


    // find token duplicates in an array of strings
    findDuplicates(array) {
        const duplicates = new Set();
        const seenValues = new Set();
        
        for (const value of array) {
            if (seenValues.has(value)) {
            duplicates.add(value);
            } else {
            seenValues.add(value);
            }
        }
        
        return Array.from(duplicates);
    },

    removeDuplicates(array) {
        return [...new Set(array)];
    },
    
}