const shellMessages = {
    
    // UI
    accountYour: "Your account",
    cancel: "Cancel",
    change: "change",
    changeTheme: "Change theme",
    changeYourName: "Change your name",
    changeYourPassword: "Change password",
    close: "Close",
    confirm: "Confirm",
    dark: "dark",
    delete: "Delete",
    emailCheck: "Check your email box",
    emailCheckSpam: "Be sure to check your spam/bulk mailbox, if you do not receive a message from us within a few minutes",
    nameNew: "Enter new name",
    itemDeleteConfirmation:  "Are you sure you want to delete ",
    invalidCredentials: "Invalid credentials",
    language: "Language | Languages",
    light: "light",
    login: "Login",
    lostPassword: "Forgotten Password",
    name: "name",
    next: "next",
    organization: "Organization",
    password: "password",
    passwordCurrentConfirm: "Confirm current password",
    passwordDifferent: "Password must be different from current one",
    passwordHint: "At least {count} characters",
    password_invalid: "Current password entered is invalid, please try again",  // '_' needed
    passwordLinkOutdated: "This link is no more valid. Please try again.",
    passwordNewConfirm: "Confirm new password",
    passwordReinitialize: "Reset my password",
    passwordReset: "Enter the email you provided as login",
    passwordResetMail: "A request for confirmation has been sent by email at ",
    password_updated: "Your password has been updated", // '_' needed
    policyCookies: "Cookies",
    policyLegalDisclaimer: "Legal Disclaimer",
    policyPrivacy: "Privacy Policy",
    policyTermsConditions: "Terms of Service",
    profiles: "Profiles",
    previous: "back",
    requiredFields: "* indicates required field",
    role: "Role",
    save: "Save",
    search: "search",
    searchInTitle: "Search in title",
    signin: "Sign in",
    signout: "Sign out",
    success: "success",
    theme: "theme",
    update: "update",
    user: "user | users",
    users: "Users",  // needed for drawer
    userNew: "New user",
    userEdit: "Edit user",
}

// 👇️ default export
export default shellMessages;