export default {
    user: {
        token: null,
        ttl: null,
        refresher: null,

        role: null,
        name: null,
        language_ui: null,
        theme: null,
        packages_countries: null,
        packages_languages: null,
    },

    fields: ["ttl","role","name","language_ui","theme","packages_countries","packages_languages"],

    setToken(token) {
        this.user["token"] = token
        localStorage.token = token;

        for(let f of this.fields) {
            this.user[f] = parseJwt(token)[f];
            localStorage[f] = this.user[f];
        }
    },

    setRefresher(refresher) {
        this.user.refresher = refresher;
        localStorage.refresher = refresher;
    },

    setUserField(field, value) {
        this.user[field] = value;
        localStorage[field] = value;
    },

    retriveToken() {
        if (!("token" in localStorage)) {
            return null;
        }
        this.token = localStorage.token;
        return this.token;
    },

    retriveRefresher() {
        if (!("refresher" in localStorage)) {
            return null;
        }
        this.refresher = localStorage.refresher;
        return this.refresher;
    },

    retriveUserField(field) {
        if (!(field in localStorage)) {
            return null;
        }
        return localStorage[field]
    },

    clean() {
        this.user.token = null;
        localStorage.removeItem("token");
        localStorage.removeItem("refresher");
        for(let f of this.fields) {
            this.user[f] = null;
            localStorage.removeItem(f);
        }
    },

};

// build a json object with the JWT payload
function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}