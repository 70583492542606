const state = {
    tab: null,
    labels: null,
    tagger_search: null,
};

const getters = {

    getLabels() {
        return state.labels;
    },

    getTaggerSearch() {
        return state.tagger_search;
    },
};

const actions = {

    updateLabels: function({ commit }, obj) {
        commit('setLabels', obj);
    },

    updateTaggerSearch: function({ commit }, obj) {
        commit('setTaggerSearch', obj);
    },

    resetTaggerSearch: function({ commit }) {
        commit('setResetTaggerSearch');
    },
};

const mutations = {

    setLabels: function(state, obj) {
        state.labels = obj;
    },

    setTaggerSearch: function(state, obj) {
        state.tagger_search = obj;
    },

    setResetTaggerSearch: function(state) {
        state.tagger_search = null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
