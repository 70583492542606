import Vue from 'vue'
import Vuetify from "vuetify/lib";

//* Vuetify
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {

        light: true,
        dark: false,

        options: {
            customProperties: true,
        },
        
        themes: {
            light: {
                accent: colors.indigo.darken2,
                primary: colors.indigo.darken4, // colors for basic (checkbox, switch, icon...)
                Dialog: colors.grey.lighten3,
                Drawer: colors.grey.lighten3,
                pTagText: colors.black,
                
                Background: colors.grey.lighten4, // main backgound
                UserMenu: colors.white,
                
                info: colors.blue.accent4,
                success: colors.green.accent4,
                warning: colors.orange,
                error: colors.red.accent4,
            },

            dark: {
                accent: colors.cyan.accent2,
                primary: colors.cyan.accent2,  // colors for basic (checkbox, switch, icon...)
                Dialog: colors.grey.darken3,
                Drawer: colors.grey.darken3,
                pTagText: colors.grey.lighten5,  // dialog single p tag color text
                
                UserMenu: colors.grey.darken4,
                Background: colors.black,   // main backgound

                info: colors.blue.accent1,
                success: colors.green.accent4,
                warning: colors.orange,
                error: colors.red,
            },
        }

    },
})