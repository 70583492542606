<template>
    <div>
        <v-card flat color="transparent" class="" key="header-lang-country">
            <v-card-actions  >
                <v-spacer></v-spacer>
                <v-autocomplete
                    outlined
                    dense
                    :items="user_pkg_languages"
                    v-model="language"
                    label="Source Language"
                    item-text="name"
                    item-value="iso"
                    hide-details
                    @change="eventLanguage"
                    clearable
                    :color="color"
                ></v-autocomplete>
                <v-spacer></v-spacer>
                <v-icon :color="color" @click="eventReload">mdi-reload</v-icon>
                <v-spacer v-if="stream == 'standby'"></v-spacer>
                <v-btn v-if="stream == 'standby'" small outlined :color="color" @click="eventUndo">Undo</v-btn>
                <v-spacer></v-spacer>
                <v-autocomplete
                    outlined
                    dense
                    :items="user_pkg_countries"
                    v-model="country"
                    label="Source Country "
                    item-text="name"
                    item-value="iso3"
                    hide-details
                    @change="eventCountry"
                    clearable
                    :color="color"
                ></v-autocomplete>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

    name: 'HeaderLangCountry',

    data: () => ({
        language: null,
        country: null,
    }),

    props: ['languageMain', 'countryMain', 'color', 'stream'],

    created() {
        this.language = this.languageMain;
        this.country = this.countryMain.toLowerCase();
    },


    methods: {
        eventLanguage() {
            if (this.language) {
                this.$emit("eventLanguage", this.language);
            } else {
                this.$emit("eventLanguage", "all");
            }
        },

        eventCountry() {
            if (this.country) {
                this.$emit("eventCountry",this.country.toUpperCase());
            } else {
                this.$emit("eventCountry","all");
            }
        },

        eventReload() {
            this.$emit("eventReload");
        },

        eventUndo() {
            this.$emit("eventUndo");
        },
    },

    computed: {
        ...mapGetters({
            user_pkg_countries: 'getUserPkgCountries',
            user_pkg_languages: 'getUserPkgLanguages',
        }),
    },
}
</script>

<style>
.azul{
 border: 2px solid red !important;
}
</style>