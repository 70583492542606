<template>  
    <div>
        <v-system-bar app height="50px" color="Dialog">
            <v-toolbar-title class="ml-5 font-weight-bold accent--text text-h5" style="cursor: pointer" @click="goHome">{{$settings.company}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- USER MENU: offset for dialog menu  -->
            <Menu v-if="$rbac.is_user_logged_in()" :menuOffset="38" :iconOffsetClass="'ml-1'" key="user-menu-data"/>
        </v-system-bar>
    </div>
</template>

<script>
import Menu from './UserMenu'

export default {
    
    name: 'System',

    components: {
        Menu,
    },

    methods: {
        goHome() {
            this.$router.push("/");
        },
    }
}
</script>

<style>

</style>