import _ from "lodash";
import Vue from "vue";
import axios from "axios";
import auth from "@/common/auth";

export default new Vue({
    data: () => ({
        base: "",  // init in main.js
        ui: "", // init in main.js => needed in logout function (UserMenu)
    }),

    /*
        Token is refreshed each time it is expired
        So every time, the user crdentials are checked
        The refresher last long time, the token lasts short time.

        token is refreshed every time there a API call.
    */
   
    methods: {

        pre: async function(method, path, data, options) {
            return axios(_.merge({
                method: method,
                baseURL: this.base,
                url: path,
                data: data,
                validateStatus: function(status) {
                    return status < 400;
                }.bind(this),
            }, options))
        },

        req: async function(method, path, data) {

            let client = axios.create(_.merge({
                method: method,
                baseURL: this.base,
                url: path,
                data: data,
            }));
            

            // WARNING must be a const to be available in interceptors...
            const authClient = axios.create({
                baseURL: this.base,
            });

            // interceptors
            client.interceptors.request.use(

                async function (config) {
                    // set user lang (i18n)
                    config.headers['Accept-Language'] = auth.retriveUserField("lang")
                    config.headers['Authorization'] = 'Bearer ' + auth.retriveToken();
                    return config;
                }, 
              
                function (error) {
                    console.log("interceptor request error:", error);
                    return Promise.reject(error);
                }
            );

            client.interceptors.response.use(

                function (response) {
                    return response;
                }, 

                async function (error) {

                    const originalRequest = error.config;

                    if (originalRequest.url != "/auth/token" && error.response) {
                        // token not valid or expired => 401
                        
                        if (error.response.status == 401 && !originalRequest._retry) {
                            originalRequest._retry = true;
                            
                            try {
                                let res = await authClient.post("/auth/refresh", {token: auth.retriveToken(), refresher: auth.retriveRefresher()})
                                auth.setToken(res.data.token);
                                return client(originalRequest);
                            } catch (_error) {
                                // refresher not valid => 400
                                return Promise.reject(error);
                            }
                        }
                    }
                    
                    return Promise.reject(error);
                }
            );
            
            let req = null;

            switch (method) {
                case 'GET':
                    req = client.get(path)
                    break;
                case 'POST':
                    req = client.post(path,data)
                    break;
                case 'PATCH':
                    req = client.patch(path,data)
                    break;
                case 'PUT':
                    req = client.put(path,data)
                    break;
                case 'DELETE':
                    req = client.delete(path)
                    break;
            }
            return req
        },
        get: async function(path) {
            return this.req('GET', path, null);
        },
        post: async function(path, data) {
            return this.req('POST', path, data);
        },
        patch: async function(path, data) {
            return this.req('PATCH', path, data);
        },
        put: async function(path, data) {
            return this.req('PUT', path, data);
        },
        delete: async function(path) {
            return this.req('DELETE', path, null);
        },
    }
});
