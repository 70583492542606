<template>
    <div>

        <!-- loading -->
        <v-progress-linear
            indeterminate
            :color="tabColor"
            :active="loading">
        </v-progress-linear>

        <Header 
            :languageMain="language"
            :countryMain="country"
            @eventLanguage="eventLanguage" 
            @eventCountry="eventCountry"
            @eventReload="eventReload"
            :color="tabColor"
            stream="trash"
            class="my-2"
        ></Header>
    
        <!-- error msg -->
        <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
        
        <!-- no post msg -->
         <v-card outlined v-if="error == 'empty' || remaining == 0" flat class="ma-2">
            <v-card-text class="text-center warning--text">{{$i18n.t("noMorePost")}}</v-card-text>
        </v-card>

        <!-- common frame -->
        <Frame
            :tab="tab"
            :frameColor="tabColor"
            :posts="posts"
            :tabDialogSingle="tabDialogSingle"
            :tabDialogSingleIndex="tabDialogSingleIndex"
            :tabDialogSinglePost="tabDialogSinglePost"
            @eventVote="eventVote"
            @eventGoTags="eventGoTags"
            @eventGoLoot="eventGoLoot"
            @eventGoCorpus="eventGoCorpus"
            @eventGoMiner="eventGoMiner"
            @eventGoSource="eventGoSource"
            @eventOpenSingleDialog="eventOpenSingleDialog"
            @eventCloseSingleDialog="eventCloseSingleDialog"
        ></Frame>

        <!-- TRASH BATCH -->
        <v-card v-if="posts.length > 0 & remaining > 0" flat key="trash-batch-button">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="doTrashBatch" class="font-weight-black Dialog--text" :color="tabColor">{{$i18n.t("trashBatchButton")}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <!-- NEW BATCH -->
        <v-card v-if="posts.length == 0 & remaining > 0" flat key="new-batch-button">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small @click="newBatch" class="font-weight-black Dialog--text" :color="tabColor">{{$i18n.t("newBatch")}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

            
    </div>
</template>

<script>
import trainer from "@/common/trainer";
import Header from '../components/HeaderLangCountry'
import Frame from './TrainerFrame'

export default {

    name: 'Trash',

    data: () => ({
        
        // setup
        loading: false,
        error: null,
        tab: "trash",
        tabColor: "warning",

        // header
        language: null,
        country: null,
        remaining: 0,
        stashRemaining: 0,

        // paging
        page: 0,
        options: {
            itemsPerPage: 25,
        },

        // data
        posts: [],
        stash: [],

        // scrolling
        scrolling: 0,

        // tab single
        tabDialogSingle: false,
        tabDialogSingleIndex: 0,
        tabDialogSinglePost: null,

        // in order to fetch when changing tabs
        // only if have been created already
        hasBeenCreated: false,
        
    }),

    components: {
        Header,
        Frame,
    },

    props: ['tabNumber'],

  
    created() {
        // init when back from single
        this.language = trainer.retriveTrashLanguage();
        this.country = trainer.retriveTrashCountry();
        this.tabDialogSingle = trainer.retriveTrashDialog();
        this.tabDialogSingleIndex = trainer.retriveTrashIndex();
        //  displaying the old single post before getting updates in tags/loot
        this.tabDialogSinglePost = trainer.retriveTrashSingle()
        this.scrolling = trainer.retriveTrashScroll();
        this.fetchTrashPosts()
        .then(() => { 
            this.tabDialogSinglePost = this.posts[this.tabDialogSingleIndex]
        })
        .then( () => {
            if (!this.tabDialogSingle) {
                window.scrollTo(0, this.scrolling);
            }
            this.hasBeenCreated = true;
        })
        .then(this.fetchStashPosts);
    },

    watch: {
        tabNumber: {
            deep: true,
            immediate: true,
            handler() {
                if (this.tabNumber == 1  && this.hasBeenCreated) {
                    this.posts=[];
                    this.fetchTrashPosts().then(this.fetchStashPosts);
                }
            }
        }
    },


    methods: {

        fetchTrashPosts: async function() {
            this.loading = true
            this.error = null;
            this.posts = [];
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=0&predicted_class=0" +
                this.languageApiFilter() + 
                this.countryApiFilter()
            );

            try {
                let res = await this.$api.get("/profile/trainer/list" + filter);
                this.posts = res.data
                this.remaining = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
                this.remaining = 0;
            } finally {
                this.$emit("eventTrashRemaining", this.remaining)
                this.loading = false;
            }
        },

        // fetch in the back when user is reading posts
        fetchStashPosts: async function() {
            console.log("fetch stash")
            if (this.remaining > this.options.itemsPerPage) {
                this.error = null;
                this.stash = [];
                let filter = (
                    "?size=" + this.options.itemsPerPage + 
                    "&page=1&predicted_class=0" +
                    this.languageApiFilter() + 
                    this.countryApiFilter()
                );

                try {
                    let res = await this.$api.get("/profile/trainer/list" + filter);
                    this.stash = res.data
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = "stash: " + data.message;
                } finally {
                    this.$emit("eventTrashRemaining", this.remaining)
                }
            } 
        },


        doTrashBatch() {
            this.trashBatch().then( (() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }))
        },

        newBatch() {
            this.fetchTrashPosts().then(this.fetchStashPosts);
        },

        trashBatch: async function() {
            console.log("trashing", this.posts.length, "posts")

            let urlsList = [];
            for (let p of this.posts) {
                urlsList.push(p.url);
            }

            this.remaining = this.remaining - this.posts.length
            this.$emit("eventTrashRemaining", this.remaining)
            
            // swap stash over to posts (no request made)
            // user has sthg to read right away, leaving time for the trashing process
            // to complete in the back
            // - sometimes posts and stash length are different
         
            this.posts = [];
            if (this.stash.length > 0) {
                this.posts = this.stash
                this.stash = []
            }

            // trashing process
            if (urlsList.length > 0) {
               
                try {
                    await this.$api.post("/profile/trainer/trash", { trash_urls: urlsList});
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = "trashing: " + data.message;
                } finally {
                    if (this.remaining - this.stashRemaining > this.options.itemsPerPage) {
                        // refill the stash after trashing in the background
                        // we implement a delay due to the progation of the updates in the nodes
                        // trash batch request is done but propagation not over in ndes...

                        // we update remaining directly, will be updated by fetchStash
                        if (this.remaining > this.options.itemsPerPage) {
                            setTimeout( () => this.fetchStashPosts(), 2000);
                        }
                    }
                }
            } 
            
        },

        //* HEADER

        // Header Events
        eventLanguage(lg) {
            trainer.setTrashLanguage(lg);
            this.language = lg;
            this.posts = [];
            this.stash = [];
            this.fetchTrashPosts().then(this.fetchStashPosts);
        },

        eventCountry(country) {
            trainer.setTrashCountry(country);
            this.country = country;
            this.posts = [];
            this.stash = [];
            this.fetchTrashPosts().then(this.fetchStashPosts);
        },

        eventReload() {
            this.posts = [];
            this.fetchTrashPosts().then(this.fetchStashPosts);
        },

        // Header filters
        countryApiFilter() {
            if (this.country != "all") {
                return "&country=" + this.country
            }
            return  ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            } 
            return ""
        },


        //* FRAME

        // Events

        // Vote
        eventVote(...[,index,stream]) {
            this.posts.splice(index,1)
            this.remaining --;
            if ( stream == 'single') {
                this.eventCloseSingleDialog();
            }
            this.$emit("eventTrashRemaining", this.remaining)
        },
        
        // Go tools Tags
        eventGoTags(index) {
            trainer.setTrashScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

        // Go tools Loot
        eventGoLoot(index) {
            trainer.setTrashScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        // Go tools Corpus
        eventGoCorpus(index) {
            trainer.setTrashScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_corpus_tab/" + this.posts[index].id})
        },

        // Go Miner
        eventGoMiner(index) {
            trainer.setTrashScroll(window.scrollY);
            this.$router.push({path: "/main/miner_prompt/" + this.posts[index].id})
        },

        // Go Source
        eventGoSource(index) {
            trainer.setTrashScroll(window.scrollY);
            this.$router.push({path: "/main/source/" + this.posts[index].source_ref})
        },

        //* SINGLE
        eventOpenSingleDialog(index) {
            trainer.setTrashDialog(true);
            trainer.setTrashSingle(this.posts[index]);
            trainer.setTrashIndex(index);
            trainer.setTrashScroll(window.scrollY);
            this.scrolling = window.scrollY;
            this.tabDialogSingle = true;
            this.tabDialogSingleIndex = index;
            this.tabDialogSinglePost = this.posts[index];
        },


        eventCloseSingleDialog() {
            trainer.setTrashDialog(false);
            trainer.setTrashSingle(null);
            trainer.setTrashIndex(0);
            window.scrollTo(0,this.scrolling);
            this.tabDialogSingle = false;
            this.tabDialogSingleIndex = 0;
            this.tabDialogSinglePost = null;
        },
    }
}
</script>

<style>

</style>