export default {

    // min tab
    tab: 0,
    scroll: 0,

    setTab(value) {
        this.tab = value;
    },
 
    retriveTab() {
        return this.tab;
    },

    setScroll(value) {
        this.scroll = value;
    },
 
    retriveScroll() {
        return this.scroll;
    },

}