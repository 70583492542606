export default {
    model: "",
    scroll: 0,

    setModel(mdl) {
        this.model = mdl
    },

    setScroll(val) {
        this.scroll = val;
    },

    retriveModel() {
        return this.model;
    },

    retriveScroll() {
        return this.scroll;
    },
}