const state = {
    languages: null,
    user_pkg_languages: null,
};

const getters = {
    getLanguages() {
        return state.languages;
    },

    getUserPkgLanguages() {
        return state.user_pkg_languages;
    },
};

const actions = {

    updateLanguages: function({ commit }, obj) {
        commit('setLanguages', obj);
    },

    updateUserPkgLanguages: function({ commit }, obj) {
        commit('setUserPkgLanguages', obj);
    },

};

const mutations = {
    setLanguages: function(state, obj) {
        state.languages = obj;
    },

    setUserPkgLanguages: function(state,obj) {
        state.user_pkg_languages = obj;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
