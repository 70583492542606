export default {
    // min tab
    tab: 0,

    setTab(value) {
        this.tab = value;
    },

    retriveTab() {
        return this.tab;
    },
}