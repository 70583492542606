<template>
    <div>
       
        <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table
            :headers="[
                { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
                { text: '', value: 'country_flag', align:'right', class:'text-body-2' },
                { text: 'PAYS', value: 'country', align:'left', class:'text-body-2' },
                { text: 'LANGUE', value: 'language', align:'center', class:'text-body-2' },
            ]"
            :items="sets"
            :loading="loading"
            @click:row="selectRow"
            :search="search"
            :footer-props="{
                itemsPerPageText:'sets par page',
                itemsPerPageOptions: [15,30,-1],
            }"
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
            
            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="accent--text">{{$i18n.t("Drawer.packages_sets")}}<span v-if="total > 0" class="ml-2">({{total}})</span></v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                    <!-- DESKTOP TABLE SEARCH -->
                    <v-text-field 
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeString($i18n.t("search"))'
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>

                    <!-- NEW USER BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </template>

            <!-- Country Flag -->
            <template v-slot:[`item.country_flag`]="{ item }">
                <country-flag :country='item.iso' size='normal' :rounded="true" class="mb-0"/>
            </template>

             <!-- Country Flag -->
            <template v-slot:[`item.country`]="{ item }">
                {{item.country}} [{{item.iso}}]
            </template>

            <template v-slot:[`item.language`]="{ item }">
                {{$app.fetchLanguageNameAccordingUserLangUi(item.language)}}
            </template>
        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="red--text">API ERROR/{{ error }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

    name: 'SetsTab',

    data: () => ({
        sets: [],
        loading: false,
        itemDialog: false,
        deleteItemDialog: false,
       
        //items 
        total: 0,
        options: {
            itemsPerPage: 15,
        },
        search: null,

        // api error
        error: null,
    }),

    watch: {
        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchSets();
            }
        },
    },

    created() {
        this.fetchSets();
    },

    methods: {

        fetchSets: async function() {
            console.log("fetching sets")
            this.loading = true;
            try {
                let res = await this.$api.get("/packages_sets");
                let items = [];
                for (let item of res.data) {
                    items.push(item)
                }
                // sort countries alphabetically
                items.sort((a,b) => a.country.localeCompare(b.country));
                this.sets = items;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // go to Set.vue
        selectRow(row) {
            this.$router.push({path: "/main/package_set/" + row.id})
        },

        create() {
            this.$router.push({path: "/main/package_set/new"})
        },

    },

    computed: {
        ...mapGetters({
            countriesStore: 'getCountries',
        }),
    },
}
</script>

<style>

</style>