<template>
    <div>
        <v-data-table
            :headers="[
                { text: 'Id', value: 'id', align:'left', class:'text-body-2' },
                { text: 'Login', value: 'email', align:'center', class:'text-body-2' },
                { text: 'Name', value: 'name', align:'center', class:'text-body-2' },
                { text: 'Role', value: 'role', align:'center', class:'text-body-2' },
                { text: 'UI', value: 'language_ui', align:'center', class:'text-body-2' },
                { text: 'Theme', value: 'theme', align:'center', class:'text-body-2' },
                { text: 'Created', value: 'created_at', align:'center', class:'text-body-2' },
                { text: 'Updated', value: 'updated_at', align:'center', class:'text-body-2' },
            ]"
            :items="users"
            :options.sync="options"
            :loading="loading"
            @click:row="selectRow"
            :server-items-length="total"
            disable-sort
            disable-filtering
            :footer-props="{
                itemsPerPageText:'utilisateurs par page',
                itemsPerPageOptions: [15,30,-1],
            }"
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
            
            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title>{{$i18n.t("users")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    
                    <!-- DESKTOP TABLE SEARCH -->
                    <v-text-field 
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))'
                        hide-details
                        @change="searchUsers"
                    >
                        <template slot="append">
                            <v-icon @click="searchUsers">mdi-magnify</v-icon>
                            <v-icon @click="search = null" class="ml-2">mdi-close</v-icon>
                        </template>
                    </v-text-field>
                    <v-spacer></v-spacer>

                    <!-- NEW USER BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </template> 
                  

            <!-- i18n role name -->
            <template v-slot:[`item.role`]="{ item }">
                {{$i18n.t(item.role)}}
            </template>

            <!-- CREATED AT DATE -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{formatDate(item.created_at)}}
            </template>

            <!-- UPDATED AT DATE -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{formatDate(item.updated_at)}}
            </template>

        </v-data-table>

        <!-- error msg -->
        <span v-if="error" class="red--text">API ERROR/{{ error }}</span>

    </div>
</template>

<script>

import moment from "moment";

export default {

    name: 'Users',

    data: () => ({

        //set up
        itemName: "user",
        loading: false,
        itemDialog: false,
        deleteItemDialog: false,
       
        //items 
        total: 0,
        options: {
            itemsPerPage: 15,
        },
        search: null,
        users: [],

        // api error
        error: null,
    }),


    watch: {

        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchUsers();
            }
        },

    },

    created() {
        this.fetchUsers();
    },

    methods: {

        fetchUsers: async function() {
            console.log("fetching users")
            this.loading = true;
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.options.page - 1)
            );
            try {
                let users = [];
                let res = await this.$api.get("/users" + filter);
                for (let user of res.data) {
                    users.push(user)
                }
                this.users = users;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // go to User.vue
        selectRow(row) {
            this.$router.push({path: "/main/user/" + row.id})
        },

        create() {
            this.$router.push({path: "/main/user/new"})
        },

        searchUsers: async function() {
            
            this.loading = true;
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.options.page - 1) +
                "&name=" + this.search.toLowerCase() +
                "&email=" + this.search.toLowerCase() +
                "&role=" + this.search.toLowerCase()
            );
            console.log("search=", filter)
            let res = await this.$api.get("/search/users" + filter);
            let users = [];
            for (let user of res.data) {
                users.push(user)
            }
            this.users = users;
            this.total = parseInt(res.headers["x-paginated-items"]);
            this.loading = false;
        },

        formatDate(date) {
            if (date != null) {
                return moment(date).format("DD/MM/YY");
            } else {
                return "-";
            }
        },

    },

}
</script>