<template>
    <div>

        <v-row align="center" justify="center">
            <v-card  class="pa-5 mt-10" color="" >
                <v-card-title class="font-weight-bold justify-center accent--text">{{$i18n.tc("login",2)}}</v-card-title>

                <v-text-field
                    dense
                    filled 
                    rounded
                    v-model="user"
                    type="text"
                    label="email"
                    :rules="[rules.required,isLoginEmail]"
                    required
                ></v-text-field>

                <v-text-field
                    dense
                    filled 
                    rounded
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required,isPasswordValid]"
                    :type="showPassword ? 'text' : 'password'"
                    :label='$i18n.t("password")'
                    @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        small
                        rounded solo single-line
                        @click="login"
                        class="font-weight-bold"
                        color="accent"
                    >
                        {{$i18n.t("signin")}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                        <span @click="lostPassword" style="cursor: pointer" class="text-subtitle-2 accent--text">{{$i18n.t("lostPassword")}}</span>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>

        </v-row>

        <!-- error snackbar -->
        <v-snackbar 
            v-model="error" 
            color="accent" 
            bottom
        >
            {{$i18n.t("invalidCredentials")}}
            <template v-slot:action="{ attrs}">
                <v-btn 
                    text
                    v-bind="attrs"
                    @click="error = false"
                >
                    {{$i18n.t("close")}}
                </v-btn>
            </template>
        </v-snackbar>  


    </div>
</template>

<script>
export default {

    name: 'Login',

    data: () => ({
        user: null,
        password: null,
        error: false,
        showPassword: false,
        rules: {
            required: v => !!v || 'Field required',
        },
    }),

    methods: {
      
        login: async function() {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(this.user) && this.password.length >= 8) {
                let inputs = {
                    "user": this.user, // max 20 char
                    "password": this.password, // max 8 char
                }
                try {
                    let res = await this.$api.pre("post", "/auth/token", inputs);
                    this.$auth.setToken(res.data.token);
                    this.$auth.setRefresher(res.data.refresher);
                    this.$router.replace({path: "/"});
                } catch(err) {
                    this.error = true;
                }
            } else {
                alert('Invalid credentials');
            }
        },

        lostPassword() {
            this.$router.push("/pmain/lost");
        },
    },

    computed: {
        isLoginEmail() {
            return (value) => {
                // Regular expression to validate email format
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                // Test if the login matches the regular expression
                if (!emailRegex.test(value)) {
                    return "email format requested";
                } else {
                    return true;
                }
            };
        },

        isPasswordValid() {
            return (value) => {
                // Minimum 8 characters, at least one digit, and at least one special character
                const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*(){}_+-])[a-zA-Z0-9!@#$%^&*(){}_+-]{8,}$/;
                // Test if the password matches the regular expression
                if (!passwordRegex.test(value)) {
                    return "Password must be at least 8 characters long and contain at least one digit and one special character.";
                } else {
                    return true;
                }
            };
        },
    }

}
</script>

<style lang="scss" scoped>
.gradient {
    background-image: radial-gradient(ellipse 140% 140% at 50% 50%, var(--v-Dialog-base) 10%, var(--v-accent-base) 60%);
}
</style>
