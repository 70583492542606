<template>
    <div>
        <Bar/>
       
        <!-- Page content -->
        <v-main 
        class="pa-0" >
            <keep-alive>
                <v-container fluid>
                    <router-view/>
                </v-container>  
            </keep-alive>     
        </v-main>

        <Footer/>
    </div>
</template>

<script>
import Bar from './components/Bar'
import Footer from './components/Footer.vue'

export default {

    name: 'Public',

    components: {
        Bar,
        Footer,
    },
}
</script>