<template>
    <div>
        <v-card outlined v-if="cluster" flat tile color="" class="mx-3 mt-2">

            <Posts
                :cluster="cluster"
                @eventPostsSelection="eventPostsSelection"
                class="mr-1"
            />

            <v-divider></v-divider>

            <!-- Chats -->
            <v-card flat>
               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- new chat -->
                    <v-btn small :loading="chat_loading" @click="fetchNewChats" icon>
                        <v-icon color="lime">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small @click="widgets_dialog = true"><v-icon color="lime">mdi-weight-pound</v-icon></v-btn>
                    <v-btn small @click="widgets_dialog = true" class="mr-2"><v-icon  color="lime">mdi-currency-usd</v-icon></v-btn>
                </v-card-actions>

                <!-- error msg -->
                <span v-if="chat_error" class="error--text">API Error: {{ chat_error }}</span>

                <v-divider></v-divider>
                
                <v-sheet outlined v-for="(c, index) in chats" :key="'chat-' + index" class="my-2 ml-2 mr-3" :color="chatColor(index)">
                    <v-card tile flat class="">
                        
                        <!-- chat meta -->
                        <v-card-subtitle class="lime--text pt-1 pb-0">
                            chat #{{index + 1}}/{{chats.length}} - resp doc tokens: {{ c.words_count}} - {{ c.resp_length }} char.
                        </v-card-subtitle>

                        <v-card-subtitle class="lime--text py-0">
                            #{{index + 1}} prompt: {{ c.prompt_tokens }} tks
                            - completion: {{ c.completion_tokens }} tks
                            - total: {{ c.total_tokens }} tks
                            - delay {{ Math.round(10 * c.gpt_delay) / 10 }} s
                            - finish: {{ c.finish_reason }}
                        </v-card-subtitle>
                       
                        <v-divider></v-divider>

                        <!-- text areas -->
                        <v-card-text>
                            <v-textarea outlined v-model="chats[index].chat" auto-grow hide-details color="lime">
                                <template v-slot:append-outer >
                                    <div class="d-flex flex-column">
                                        <v-btn small icon class="mt-5" >
                                            <v-icon @click="selectChat(index)" :color="chatColor(index)">mdi-arrow-right-bold-box-outline</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteChat(index,chats[index].id)" small icon>
                                            <v-icon color="error">mdi-close-circle-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-textarea>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-card>
        </v-card>

        <!-- Widgets -->
        <v-dialog v-model="widgets_dialog" width="500">
            <v-sheet rounded outlined color="lime">
                <v-card flat color="">
                    
                    <!-- CURRENCY CONVERTER WIDGET -->
                    <Converter  v-if="cluster.posts.length > 0" :country="cluster.posts[0].country" class=""></Converter>

                    <!-- POUNDS/KGS CONVERTER WIDGET -->
                    <v-card flat outlined class="">
                        <v-card-title class="font-weight-bold justify-center lime--text py-1">Lbs/Kgs Converter</v-card-title>
                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-text-field outlined dense v-model="weight_in_pounds" label="Weight in Pounds" hide-details color="lime" clearable></v-text-field>
                            <v-spacer></v-spacer>
                            <v-text-field outlined dense v-model="convertToKgs" label="Weight in Kilos" hide-details color="lime" clearable></v-text-field>
                            <v-spacer></v-spacer>
                        </v-card-actions>

                    </v-card>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small
                            color="lime"
                            @click="widgets_dialog = false"
                            class="Dialog--text"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>

    </div>
</template>

<script>
import Posts from './Widgets/PublisherWidgetPosts.vue'
import Converter from './Widgets/PublisherWidgetConverter.vue'

export default {

    name: 'Chats',

    components: {
        Posts,
        Converter,
    },

    props:['cluster'],

    data: () => ({

        chat_loading: false,
        chat_error: null,

        // contains post id + no content html
        selected_posts: [],
        cluster_post_ids: [],
        
        // chats
        chats: [],
        selected_chat_index: null,
        input_max_tokens: 0,

        // widgets converter+currency
        widgets_dialog: false,
        weight_in_pounds: 0,

        max_posts_count: 4,
    }),


    watch: {
        cluster: {
            deep: true,
        immediate: true,
        handler() {
            if (this.cluster.posts && this.cluster.posts.length > 0) {
                this.fetchChatsList();
            }
        },
        }
       
    },


    methods: {

        //* Posts Widget
        eventPostsSelection(posts) {
            this.selected_posts = posts;
        },

        buildClusterPostIds() {
            for (let p of this.cluster.posts) {
                this.cluster_post_ids.push(p.id)
            }
        },

        //* Chats
        fetchChatsList: async function () {
            this.buildClusterPostIds();
            //console.log("fetch chats:", this.cluster_post_ids.join(","))
            this.loading = true;
            this.error = null;

            let payload = {
                post_ids : this.cluster_post_ids.join(","),
            }

            //console.log(payload)

            try {
                let res = await this.$api.post("/publisher/chat/list", payload);
                this.chats = res.data
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // when loocking for teh first chat we ask for a second right away 1 sec later
        fetchNewChats() {
            if (this.chats.length == 0 ) {
                this.fetchNewChat().then(() => this.delay(1000)).then( () => this.fetchNewChat());
            } else {
                this.fetchNewChat();
            }
        },

        // Define a function that introduces a delay
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // chat
        selectChat(index) {
            this.selected_chat_index = index;
            this.$emit("eventSelectedChat", this.chats[index].chat)
        },

        chatColor(index) {
            if (index == this.selected_chat_index) {
                return "lime"
            }
            return ""
        },

        fetchNewChat: async function () {
            console.log("fetch new chat, posts count=", this.selected_posts.length)
            this.chat_loading = true;
            this.chat_error = null;

            let chat_text = []
            // content => no html !
            for (let p of this.selected_posts) {
                chat_text.push(p.title, p.description, p.content);
            }
            
            let payload = {
                text: chat_text.join(" "),
                post_ids: this.cluster_post_ids.join(","),
                // input_max_tokens: this.input_max_tokens,
                // output_max_tokens: 0,
            }

            //console.log(payload);

            try {
                let res = await this.$api.post("/publisher/chat/new", payload);
                this.chats.push(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.chat_error = data.message;
            } finally {
                this.chat_loading = false;
            }
        },


        deleteChat: async function (index, id) {
            if (!confirm("Remove Chat index=" + index + " (id=" + id +  ") ?")) {
                return;
            }
            try {
                await this.$api.delete("/publisher/chat/" + id);
             } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.chat_error = data.message;
            } finally {
                this.chats.splice(index, 1)
            }
        },


    },

    computed: {

        convertToKgs() {
            return Math.round(100 * this.weight_in_pounds * 0.45359237) / 100 ;
        },

    }
}
</script>

<style>

</style>