const state = {
    query: {
        search: "",
        page: 1,
        publications: [],
        total: 0,
    }

};

const getters = {

    getPublicationsSearch() {
        return state.query;
    },

};

const actions = {

    updatePublicationsSearch: function({ commit }, obj) {
        commit('setPublicationsSearch', obj);
    },

    resetPublicationsSearch: function({ commit }) {
        commit('setResetPublicationsSearch');
    },

};

const mutations = {

    setPublicationsSearch: function(state, obj) {
        state.query = obj;
    },

    setResetPublicationsSearch: function(state) {
        
        state.query = {
            search: null,
            page: 1,
            posts: [],
            total: null,
        }
        
    },

};


export default {
    state,
    getters,
    actions,
    mutations,
};
