<template>
    <v-app>
        <v-main 
            app 
            id="app"
            :style="{ background: backgroundThemeSwitcher}"
        >
            <!-- Provides the application the proper gutter -->
            <v-container fluid v-if="loading">
                Loading...
            </v-container>
            <v-container fluid v-if="!loading">
                <div>
                    <router-view />
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/i18n-setup";

export default {

    name: "app",

    data: () => ({
        loading: true,
        isMobile: null,
    }),

    mounted() {
        this.isMobile = this.$vuetify.breakpoint.xs;
        this.$app.setIsMobile(this.$vuetify.breakpoint.xs)
        this.autoLogin();
    },

    methods: {

        autoLogin: async function() {
            
            //i18n - languages
            console.log("[INIT]App: browser - detected lang:", navigator.language)
            loadLanguageAsync(navigator.language.toLowerCase());

            // authorization
            let token = this.$auth.retriveToken();
            let refresher = this.$auth.retriveRefresher();
            if (token == null || refresher == null) {
                this.failure();
                return;
            }
            try {
                let res = await this.$api.pre("post", "/auth/token", {token: token, refresher: refresher},)
                this.$auth.setToken(res.data.token);
                this.$auth.setRefresher(res.data.refresher);
                this.success();
            } catch (e) {
                this.$auth.clean();
                this.failure();
            }
            
        },

        // Routing Mobile or Desktop
        success: function() {

            console.log("autologin success")
            this.loading = false; 

            //* i18n : set the lang for the APP
            console.log("[INIT]App: i18n - set user lang:", this.$auth.retriveUserField("lang"))
            loadLanguageAsync(this.$auth.retriveUserField("language_ui"));

            this.$app.fetchObjects();
            
            // set user theme mode
            if (this.$auth.retriveUserField("theme") == "dark") {
                this.$vuetify.theme.dark = true;
                this.$vuetify.theme.light = false;
            } else {
                this.$vuetify.theme.dark = false;
                this.$vuetify.theme.light = true;
            }

            if (this.$route.path == "/"){
                this.$router.push("/main/searcher");
            } else {
                this.$router.push(this.$route.path);
            }
        },

        failure: function() {
            console.log("autologin failure")
            this.loading = false;
            this.$auth.clean();
            this.$router.push("/pmain/phome");
        },

    },

    computed: {
        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return this.$vuetify.theme.themes.dark.Background
            } else {
                return this.$vuetify.theme.themes.light.Background
            }
        }
    }
};
</script>

<style>
</style>
