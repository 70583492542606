<template>
    <div>
        <v-card v-if="label" flat class="mb-3" key="label-title" color="purple">
            <v-card-title v-if="isNew && label" class="justify-center text-h6 font-weight-bold">NEW LABEL - TAG REF: '{{label.tag_ref}}'</v-card-title>
            <v-card-title v-else class="justify-center font-weight-bold">LABEL: '{{label.ascii_name}}' - TAG REF: '{{label.tag_ref}}' </v-card-title>
        </v-card>

        <v-card v-if="label" outlined>
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="purple lighten-2"
                :active="loading">
            </v-progress-linear>

            <v-container fluid key="label-form">
                <v-row>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="label-form-left">
                            <v-card-text class="text-center warning--text">Dont forget to update indices !</v-card-text>
                            <v-card-text>
                                <!-- indices -->
                                <v-select
                                    outlined dense
                                    v-model="label.index"
                                    :items="indices"
                                    label="Select Index"
                                    :rules="[rules.required]"
                                ></v-select>

                                <!-- id -->
                                <v-text-field
                                    v-if="!isNew"
                                    rounded
                                    outlined
                                    dense
                                    v-model="label.id"
                                    label="elastic id: '_id' "
                                    disabled
                                ></v-text-field>

                                <span>
                                    <ul>
                                        <li>if adding label in cities => *_jj</li>
                                        <li>if adding label in crimorg => *_names if not GPT</li>
                                        <li>if adding label in crimorg => *_gpt if from GPT</li>
                                        <li>if adding label in crimorg => *_trigger if want to trigger the tag with a name, ...</li>
                                    </ul> 
                                </span>
                               
                                <!-- key -->
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="label.key"
                                    label="key"
                                    :rules="[rules.required,emptySpacesRule]"
                                    required
                                    hint="A key to differentiate between tag and others labels"
                                    persistent-hint
                                    class="mb-3"
                                ></v-text-field>
                            
                                <!-- ascii_name -->
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="label.ascii_name"
                                    label="ascii_name"
                                    :rules="[rules.required,emptySpacesRule]"
                                    required
                                    hint="Readable name, allows to be searched with es"
                                    persistent-hint
                                    class="mb-3"
                                    
                                ></v-text-field>

                                <!-- tag_ref -->
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="label.tag_ref"
                                    label="Tag ref"
                                    :rules="[rules.required,emptySpacesRule]"
                                    required
                                    hint="the tag ref to which this label is linked"
                                    persistent-hint
                                    class="mb-3"
                                ></v-text-field>
                            </v-card-text>
                            
                            <!-- CRUD Buttons -->
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small
                                    color="purple"
                                    v-if="isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="create"
                                    class="mr-2"
                                >
                                    <v-icon left>mdi-plus</v-icon> Create
                                </v-btn>
                                <v-btn small
                                    color="purple"
                                    v-if="!isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="save"
                                    class="mr-2"
                                >
                                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                                </v-btn>
                                <v-btn small
                                    v-if="!isNew"
                                    :loading="loading"
                                    :disabled="loading"
                                    color="error"
                                    @click="remove"
                                    class="mr-2"
                                >
                                    <v-icon small>mdi-delete</v-icon>
                                    Remove
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="label-form-left">
                            <v-card-text>
                                <v-card-text v-if="tokens.length == 29" class="success--text py-0">Tokens length = {{tokens.length}}</v-card-text>
                                <v-card-text v-if="tokens.length != 29" class="error--text py-0">Tokens length = {{tokens.length}}</v-card-text>
                                <v-card-text class="warning--text font-weight-bold py-0">Ngrams size = 5 max!</v-card-text>
                                <v-card-text v-if="duplicates.length > 0" class="error--text py-0">
                                    duplicates: {{ duplicates }}
                                    <v-btn @click="removeDuplicateTokens" rounded x-small class="font-weight-bold" color="error">Remove duplicates</v-btn>
                                </v-card-text>
                                <v-card-text v-if="singleCharacterTokens.length > 0" class="error--text py-0">
                                    single Character Tokens: {{ singleCharacterTokens }}
                                </v-card-text>
                                
                                
                                <v-textarea
                                    label="Tokens list (string separated by coma, either capitalized or lower,[- ']  will be removed)"
                                    v-model="label.tokens"
                                    auto-grow
                                    outlined
                                    :rules="[rules.required,noEndingComa,noCommaAndSpaceRule,noConsecutiveCommasRule]"
                                    class="mt-5"
                                ></v-textarea>

                                <!-- chat GPT string -->
                                <v-textarea
                                    label="Chat GPT iso: token"
                                    v-model="chatGPTstring"
                                    auto-grow
                                    outlined
                                ></v-textarea>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { Model } from "@/common/model";

// Label CLASS
class Label extends Model {
    id = null;
    key = null;
    ascii_name = null;
    tag_ref = null;
    tokens = ""; // is actually a string for later dto
    index = null;

    onParse() {
        if (this.tokens) {
            this.tokens = this.tokens.join(",");
        }

        // "kraken_labels_crimorg_1" => crimorg
        if (this.index) {
            if (this.index.includes("crimorg")) { this.index = "crimorg"}
            if (this.index.includes("market")) { this.index = "market"}
            if (this.index.includes("mean")) { this.index = "mean"}
            if (this.index.includes("country")) { this.index = "country"}
            if (this.index.includes("city")) { this.index = "city"}
            if (this.index.includes("law")) { this.index = "law"}
        }
    }

    onFormat() {
        if (this.tokens) {
            // get rid of trailing white spaces
            let ts = this.tokens.toLowerCase().trim();

            // get rid if a oma is left at the end of the tokens
            if (ts.endsWith(",")) {
                ts = ts.slice(0, -1);
            }
            
            // info: replaceAll not native in js: replaceAll("-"," ")
            ts = ts.replace(/-/g," ");
            ts = ts.replace(/'/g," ");
            ts = ts.replace(/’/g," ");
            //clean "token ,token,token"
            ts = ts.replace(/ ,/g,",");
            ts = ts.replace(/, /g,",");
            //clean "token,,token,token"
            ts = ts.replace(/,,/g,",");
            this.tokens = ts.split(",");
        }

        if (this.index) {
            if (this.index.includes("crimorg")) { this.index = "crimorg"}
            if (this.index.includes("market")) { this.index = "market"}
            if (this.index.includes("mean")) { this.index = "mean"}
            if (this.index.includes("country")) { this.index = "country"}
            if (this.index.includes("city")) { this.index = "city"}
            if (this.index.includes("law")) { this.index = "law"}
        }

        if (this.key && this.index != "city") {
            this.ascii_name = this.key;
        }
    }
}

export default {

    name: 'Label',

    data: () => ({
        loading: false,
        label: null,
        isNew: false,

        // api error
        error: null,
        rules: {
            required: v => !!v || 'Field required',
        },

        chatGPTstring: null,
        tokens: [],
        duplicates: [],
        singleCharacterTokens:  [],
        coloredDuplicatedtokens: "",

        indices: [
            "market",
            "mean",
            "crimorg",
            "law",
            "city",
        ],

    }),

    created() {

        let id = this.$route.params.id;
        if (id != "new") {
            this.fetchLabel(id);
            return;
        }
        this.isNew = true;
        this.label = Label.init();

        this.label.tag_ref = this.$route.params.tag_ref ;
        
        this.label.key = this.label.tag_ref;
        if (this.label.index != "city") {
            this.label.ascii_name = this.label.tag_ref;
        }
        
        this.label.index = this.$route.params.channel;
    },

    watch: {
        chatGPTstring: {
            deep: true,
            immediate: true,
            handler() {
                if (this.chatGPTstring && this.chatGPTstring.length > 0) {
                    // remove last coma if any
                    if(this.chatGPTstring.charAt(this.chatGPTstring.length-1) == ",") {
                        this.chatGPTstring = this.chatGPTstring.slice(0,-1)
                    }

                    this.chatGPTstring = this.chatGPTstring.replace("zh-tw","zh_tw");
                    this.chatGPTstring = this.chatGPTstring.replaceAll("\n","")
                    this.chatGPTstring = this.chatGPTstring.replaceAll('"',"")
                    this.chatGPTstring = this.chatGPTstring.replaceAll(", ",",")
                    
                    let blocks = this.chatGPTstring.split(",");
                    let tokens = []
                    for (let block of blocks) {
                        if (block.includes(":")) {
                            let token = block.split(":")[1].trim().toLowerCase();
                            token = token.replaceAll("-"," ");
                            tokens.push(token);
                           
                        }
                    }
                    this.label.tokens = tokens.join(',');
                    this.tokens = tokens;
                }
            },
        },

        label: {
            deep: true,
            immediate: true,
            handler() {
                if (this.label && this.label.tokens.length > 0) {
                    this.tokens = this.label.tokens.split(",");
                    // detect duplicates
                    this.duplicates = this.$tools.findDuplicates(this.tokens);
                    // detect tokens with single character
                    this.singleCharacterTokens = [];
                    for (let t of this.tokens) {
                        if (t.length == 1) {
                            this.singleCharacterTokens.push(t);
                        }
                    }
                }
            }
        }
    },


    methods: {

        fetchLabel: async function(id) {
            console.log("fetch label:",id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/tagger/label/" + id);
                this.label = Label.parse(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* LABEL CRUD
        create: async function() {
            this.loading = true;
            this.error = null;
            
            // checks
            if (!this.label.key || !this.label.tag_ref || !this.label.tokens || !this.label.index) {
                window.alert("some fields are empty")
                this.loading = false;
                this.error = "some fields are empty"
                return
            }

            // create
            try {
                await this.$api.post("/tagger/label", this.label.format());
                this.$router.go(-1);
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
            
        },

        save: async function() {
            
            console.log("save")
            this.loading = true;
            this.error = null;

            // checks
            if (!this.label.key || !this.label.tag_ref || !this.label.tokens || !this.label.index) {
                window.alert("some fields are empty")
                this.loading = false;
                this.error = "some fields are empty"
                return
            }

            try {
                var payload = this.label.format();
                await this.$api.patch("/tagger/label/update", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (this.error == null) {
                    this.$router.go(-1);
                }
            }
           
        },


        // since we need index and id to delete label
        // we use a patch method with payload
        remove: async function() {
            this.loading =true;
            this.error = null;
            if (!confirm("Remove Label \"" + this.label.ascii_name + "\"?")) {
                return;
            }
            
            try {
                var payload = this.label.format();
                await this.$api.patch("/tagger/label/delete", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (this.error == null) {
                    // need to wait for the cluster update before returning previous page
                    setTimeout( () => this.$router.go(-1), 1000);
                }
            }
        },
        
        removeDuplicateTokens() {
            this.tokens = this.$tools.removeDuplicates(this.tokens)
            this.label.tokens =this.tokens.join(",")
        },
    },


    computed: {

        noEndingComa() {
            return (value) => {
                // Check if the value ends with a comma
                if (value.endsWith(',')) {
                    return 'Input should not end with a comma';
                }
                return true; // Validation passed
            };
        },

        emptySpacesRule() {
            return (value) => {
                if (/\s/g.test(value)) {
                    return "Empty spaces are not allowed.";
                } else {
                    return true;
                }
             };
        },

        noConsecutiveCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,,/g) || []).length;
                if (commaCount > 0) {
                    return "Consecutive commas are not allowed.";
                } else {
                    return true;
                }
            };
        },

        noCommaAndSpaceRule() {
            return (value) => {
                const pattern = /,\s|,\s|,\s|\s,/;
                if (pattern.test(value)) {
                    return "Comma followed by an empty space or vice versa is not allowed.";
                } else {
                    return true;
                }
            };
        }
    }

}
</script>

<style>

</style>