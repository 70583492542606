<template>
    <div>
        <v-tabs v-model="tab" centered  background-color="transparent" key="manager-main-tabs">
        <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab @click="selectTab(0)" class="font-weight-bold">{{$i18n.t("Drawer.packages")}}</v-tab>
            <v-tab @click="selectTab(1)" class="font-weight-bold">{{$i18n.t("Drawer.packages_sets")}}</v-tab>
            <v-tab @click="selectTab(2)" class="font-weight-bold">{{$i18n.t("Drawer.trans_sets")}}</v-tab>
        </v-tabs>

        <v-card outlined key="manager-main-tabs-items">
            <v-tabs-items v-model="tab" class="tab-items" >
                <v-tab-item key="packages" class="">
                    <Packages></Packages>
                </v-tab-item>
                <v-tab-item key="package_sets" class="">
                    <PackageSets/>
                </v-tab-item>
                <v-tab-item key="post_trans_sets" class="">
                    <PostsTransSets/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import manager from "@/common/manager";
import Packages from './PackagesTab'
import PackageSets from './PackageSetsTab'
import PostsTransSets from './PostsTransSetsTab'
export default {

    name: "ManagerMain",

    data: () => ({
        tab: null,
    }),

    components: {
        Packages,
        PackageSets,
        PostsTransSets,
    },

    created() {
        // inti tab
        this.tab = manager.retriveTab();
    },

    methods: {
        selectTab(value) {
            manager.setTab(value)
        },
    },
}
</script>

<style>
.tab-items {
    background-color: transparent !important;
}
</style>