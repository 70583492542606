<template>
    <div>

        <v-card v-if="user" flat outlined class="mb-2" key="user-title">
            <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">New User</v-card-title>
            <v-card-title v-else class="justify-center accent--text text-h5 font-weight-bold">{{user.name}} - id#{{user.id}}</v-card-title>
        </v-card>

        <v-card flat outlined>
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>

            <v-container v-if="user" fluid key="user-form">
                <v-row>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="user-form-left">
                            <v-card-text>
                                
                                <!-- Email -->
                                <v-text-field
                                    v-model="user.email"
                                    label="Login *"
                                    :rules="[rules.required, rules.email]"
                                    required
                                ></v-text-field> 
                                
                                <!-- Name -->
                                <v-text-field
                                    v-model="user.name"
                                    :label='$i18n.t("name")'
                                    :rules="[rules.required, rules.min2]"
                                    required
                                ></v-text-field>

                                <!-- Org -->
                                <v-text-field
                                    v-model="user.organization"
                                    :label='$i18n.t("organization")'
                                    :rules="[rules.required, rules.min2]"
                                    required
                                ></v-text-field>

                                <!-- Language UI -->
                                <v-select
                                    label='Langue Interface utilisateur'
                                    v-model="user.language_ui"
                                    :items="languagesStore"
                                    item-text="iso"
                                    item-value="iso"
                                    class="mt-5"
                                ></v-select>

                                <!-- Packages Subscription -->
                                <v-combobox
                                    outlined dense multiple
                                    :items="packages_list"
                                    v-model="user.packages"
                                    label="Packages"
                                    item-text="name"
                                    :item-value="item => item.id"
                                    :rules="[rules.required]"
                                    clearable
                                    key="combo-packages-subscription"
                                ></v-combobox>
                            
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="user-form-right">
                            <v-card-text>
                                <!-- Roles -->
                                <v-select
                                    :label='$i18n.t("role")'
                                    v-model="user.role"
                                    :items="roles"
                                ></v-select>

                                <!-- Admin bool -->
                                <v-switch inset
                                    v-model="user.admin"
                                    label="Admin"
                                    color="success"
                                ></v-switch>

                                <!-- EDIT: Password -->
                                <v-text-field
                                    v-model="user.password"
                                    :label='$i18n.t("password") + "*"' 
                                    :type="showEye ? 'text' : 'password'"
                                    :rules="[rules.required, rules.min8, rules.oneDigit, rules.oneCapital]"
                                    :required="isNew"
                                    :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showEye = !showEye"
                                    :hint="!isNew ? 'Leave blank if you don\'t want to change it' : ''"
                                ></v-text-field>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card v-if="user" flat color="transparent" key="packages-selection">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small
                    color="success"
                    v-if="isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="create"
                    class="mr-2"
                >
                    <v-icon left>mdi-plus</v-icon> Create
                </v-btn>
                <v-btn small
                    color="success"
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="save"
                    class="white--text mr-2"
                >
                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                </v-btn>
                <v-btn small
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    color="error"
                    @click="remove"
                    class="mr-2"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Remove
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import utils from "@/common/utils";
import { Model } from "@/common/model";
import { mapGetters } from 'vuex';

// User CLASS
class User extends Model {
    id = null;
    name = null;
    email = null;
    role = null;
    password = null;
    theme = null;
    language_ui = "fr";
    packages = null;
    packages_payload = [];
    admin = false;

    onParse() {
        if (this.translation_languages) {
            this.translation_languages = this.translation_languages.split(",")
        }
    }

    onFormat() {
        if (this.id) {
            this.password = this.password || null;
        } else {
            this.password = this.password || (
                this.admin ? null : Math.random().toString(36).slice(-8)
            );
        }

        if (this.translation_languages) {
            this.translation_languages = this.translation_languages.join(",")
        }
    }
}

export default {

    name: 'User',

    data: () => ({
        
        loading: false,
        user: null,
        isNew: false,
        snackbar: false,
        showEye: false,
        packages_list: [],

        rules: {
            required: v => !!v || 'Field required',
            min2: v => v && v.length >= 2 || 'Min 2 characters',
            min8: v => v && v.length >= 8 || 'Min 8 characters',
            oneDigit: v => v && /\d/.test(v) || 'At least one digit',
            oneCapital: v => v && /[A-Z]{1}/.test(v) || 'At least one capital letter',
            email: v => utils.emailValidation(v) || 'Invalid email address',
        },

        // api error
        error: null,

        // Project
        roles: [
            "jjmaster" ,
            "trainer_subscriber",
            "trainer",
            "subscriber",
        ],

    }),

    created() {

        this.fetchPackages();
        let id = this.$route.params.id;
        if (id != "new") {
            this.fetch(id);
            return;
        }
        this.isNew = true;
        this.user = User.init();

        this.roleItems = this.$roles.Roles;
    },

    methods: {

        fetch: async function(id) {
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/users/" + id);
                this.user =  User.parse(res.data);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },


        //* Packages
        fetchPackages: async function() {
            console.log("fetch packages")
            let packages = [];
            try {
                let res = await this.$api.get("/packages");
                for (let p of res.data) {
                    packages.push(p);
                }
                this.packages_list = packages;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                console.log("app.js/objects: err fetching packages", data.message);
            }
        },

    
        //* USER CRUD
        create: async function() {
            console.log("create user",this.user.id)
            this.loading = true;
            try {
                await this.$api.post("/users",  this.addPayload(this.user).format());
            } catch(e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false
            }
        },

        save: async function() {
            console.log("save user",this.user.id)
            this.loading = true;
            try {
                var payload = this.addPayload(this.user).format();
                if (!payload.password) {
                    delete payload.password;
                }
                console.log("payload",payload.translation_languages)
                await this.$api.patch("/users/" + this.user.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        remove: async function() {
            if (!confirm("Remove user \"" + this.user.name + "\"?")) {
                return;
            }
            await this.$api.delete("/users/" + this.user.id);
            this.$router.go(-1);
        },

        addPayload(user) {
            // sets payload: only id set
            if (user.packages.length > 0) {
                let packages_payload = [];
                for (let p of user.packages) {
                    packages_payload.push(p.id);
                }
                user.packages_payload = packages_payload;
            }
            return user
        },

    },


    computed: {
        ...mapGetters({
            languagesStore: 'getLanguages',
        }),
    },

}
</script>

<style>

</style>