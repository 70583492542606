<template>
    <div>
        <v-card tile flat outlined color="" key="eventer-main">

            <!-- images catalogue -->
            <!-- <Catalogue v-if="cl_img_urls"
                :cl_img_urls="cl_img_urls"
                :cl_index="cl_index"
                @eventCatalogueSelectedImages="eventCatalogueSelectedImages"
            /> -->

            <SplitView v-if="cluster" key="publisher-splitview" direction="horizontal"  :style="{ height: '90vh', overflow: 'hidden' }">
                <!--  -->
                <template #A>
                    <SplitView direction="horizontal">
                        
                        <!-- POSTS -->
                        <template #A>
                            <Posts />
                           
                            <br>
                            <br>
                            display cluster Loot=> click select stream
                            <br>
                            <br>
                            display cluster tags => sent to event ?
                            <br>
                            <br>
                            stream cocaine
                            <br>
                            <br>
                            Select images ?
                            
                            <br>
                            <br>
                            Stream Loot value from cluster / modif available => linked to event
                            <br>
                            <br>
                            Iso 3 from posts => select + modify => linked to event

                        </template>

                        <!-- GPT -->
                        <template #B>
                            <GPT />
                            <br>
                            <br>
                            POSTS  SELECT GPT => linked to gpt
                            <br>
                            <br>
                            Is Past ? field with text output...readable
                            <br>
                            <br>
                            Date posts are from ... today is ...
                            <br>
                            <br>
                            Location...
                            <br>
                            <br>
                            Map for location + adjust => send to event
                            <br>
                            <br>

                        </template>

                    </SplitView>
                </template>

                <!--  -->
                <template #B>
                  <SplitView direction="horizontal">
                        
                        <!-- SINGLE EVENT -->
                        <template #A>
                            <Event 
                                :event="event"
                                :stream="stream"
                                :stream_dict="stream_dict"
                                :color="color"
                                class="ma-1"
                            />
                            <br>
                            <br>
                            Field iso 3 (linke)
                            <br>
                            <br>
                            Weight stream value
                            <br>
                            <br>
                            Thumbnails image select
                            <br>
                            <br>
                            Location Map + value Lat/Long
                            <br>
                            <br>
                            Alert exists already in table events

                        </template>
                        
                        <!-- EVENTS -->
                        <template #B>
                            <Table :color="color" />
                        </template>

                    </SplitView>
                </template>


            </SplitView>

        </v-card>
    </div>
</template>

<script>
//import Catalogue from '../Publisher/ImagesCatalogue.vue'
import SplitView from 'vue-split-view/dist/vue2'
import Posts from './SlotPosts.vue'
import GPT from './SlotGpt.vue'
import Event from './SlotEvent.vue'
import Table from './SlotEventsTable.vue'

export default {

    name: 'EventerMain',

    components: {
        //Catalogue,
        SplitView,
        Posts,
        GPT,
        Event,
        Table,
    },

    props: ['deck_cluster','cl_index'],

    data: () => ({

        loading: false,
        error: null,
        color: "red",

        cluster: null,
        cl_img_urls:[],

        // images
        catalogue_selected_images_urls: [],

        stream: {
            name: "cocaine",
            gen: "0",
        },

        stream_dict: {
            n1: "cocaine weight (kgs)",
            d1: "event date",
            t1: "stream",
            k1: "gen",
            b1: "close/open event",
        },

        event: {

            created_at: null,
            updated_at: null,

            n1: 0,
            k1: null,
            d1: null,
            b1: false,
            t1: null,

            n2: 0,
            k2: null,
            d2: null,
            b2: false,
            t2: null,

            // posts 
            posts_ids:[],
            img_urls: [],
            
            // location
            location_iso3: "fra",
            loaction_geo: [],

        },

    }),

    watch: {
        deck_cluster: {
            deep: true,
            immediate: true,
            handler() {
                this.cluster = this.deck_cluster;
                this.cl_img_urls = this.buildClusterImgUrls();
            }
        }
    },


    methods: {
        eventCatalogueSelectedImages(selected_images) {
            this.catalogue_selected_images_urls = selected_images;
        },


        buildClusterImgUrls() {
            let cl_img_urls = [];
            if (this.cluster && this.cluster.posts.length > 0) {
                let regex = /<img[^>]*src="([^"]*)"/g;
                for (let p of this.cluster.posts) {
                    let match;
                    while ((match = regex.exec(p.content))) {
                        cl_img_urls.push(match[1]);
                    }
    
                    // from feeder
                    if (p.img_url.length > 0) {
                        cl_img_urls.push(p.img_url)
                    }
    
                    // remove duplicates
                    cl_img_urls = [...new Set(cl_img_urls)];
                }
            }
            return cl_img_urls
        },
    },
}
</script>

<style>

</style>