<template>
    <div>
        <v-container fluid>
            <v-row justify="center">
                <v-col
                    cols="12"
                    lg="12"
                    xl="12"
                >
                    <v-card flat color="" class="mr-2 d-flex overflow-x-hidden" key="publisher-dialog">
                        <!-- Close Button-->
                        <v-card flat class="d-flex align-center" color="red" @click="eventCloseEventerDialog" key="return-arrow">
                            <v-card flat color="transparent">
                                <v-icon large color="Dialog">mdi-arrow-left-thick</v-icon>
                            </v-card>
                        </v-card>

                        <v-card flat key="eventer-dialog-card" width="100%">
                            <Eventer 
                                :deck_cluster="cluster"
                                :cl_index="cl_index"
                                @eventCloseEventerDialog="eventCloseEventerDialog"
                            />
                        </v-card>
                    </v-card>

                  

                </v-col>

                
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Eventer from '../Eventer/EventerMain.vue'
export default {

    name: 'EventerDialog',

    components: {
        Eventer,
    },

    props: ['cluster','cl_index'],

    methods: {
        eventCloseEventerDialog() {
            this.$emit("eventCloseEventerDialog");
        },

    },
}
</script>

<style>

</style>