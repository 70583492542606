<template>  
    <div>
        <v-app-bar 
            app 
            :height=' !isMobile ? "120px" : ""'
            clipped-left 
            elevation="2"
        >
            <template v-slot:img="{ props }">
                <v-img v-bind="props">
                    <div class="fill-height gradient"></div>
                </v-img>
            </template>

            <!-- mobile -->
            <v-app-bar-nav-icon v-if="isMobile" @click.stop="mobileDrawer = !mobileDrawer" class="px-0" color="Dialog"></v-app-bar-nav-icon>
            <v-toolbar-title v-if="isMobile" class="font-weight-bold Dialog--text pl-0 text-center" style="cursor: pointer" @click="goHome">{{$settings.site}}</v-toolbar-title>
            
            
            <!-- desktop -->
            <!--<v-avatar v-if="!isMobile" size="85" class="mr-2" style="cursor: pointer" @click="goHome">-->
                <!--<img src="../assets/kraken_logo_B_2.png"/>           -->
            <!--</v-avatar>-->
            <v-toolbar-title v-if="!isMobile" class="font-weight-bold Dialog--text text-h4 pl-7 text-center" style="cursor: pointer" @click="goHome">{{$settings.site}}</v-toolbar-title>
            <v-spacer></v-spacer>
            
            
            <!-- USER MENU: offset for dialog menu  -->
            <Menu v-if="$rbac.is_user_logged_in()" :menuOffset="60"/>
            
        </v-app-bar>

        <v-navigation-drawer
            v-model="mobileDrawer"
            app
            clipped
            width="220"
            color="Background"
            >
                <NavList/>
                <v-divider></v-divider>
                <!-- mobile footer -->
                <v-card flat color="transparent">
                    <v-card-text class="pb-0">
                        <router-link to="/main/privacy" class="grey--text text-decoration-none">{{$i18n.t("policyPrivacy")}}</router-link> 
                        <router-link to="/main/cookies" class="grey--text text-decoration-none"> · {{$i18n.t("policyCookies")}}</router-link>
                    </v-card-text>
                    <v-card-text class="grey--text pt-0">©{{ new Date().getFullYear() }} {{$settings.site}}</v-card-text>
                </v-card>
        </v-navigation-drawer>
    </div>
</template>

<script>
import Menu from '../../shell/components/UserMenu'
import NavList from './NavList'

export default {
    name: 'Bar',

    components: {
        Menu,
        NavList,
    },

    created() {
        this.isMobile = this.$app.retriveIsMobile();
    },

    data: () => ({
        mobileDrawer: false,
        isMobile: false,
    }),

    methods: {
        goLogin() {
            this.$router.push("/pmain/login");
        },

        goHome() {
            this.$router.push("/pmain/phome");
        },
    },

}

</script>

<style lang="scss" scoped>
.gradient {
    background-image: linear-gradient(40deg, var(--v-accent-base) 30%, var(--v-Dialog-base) 80%);
}
</style>
