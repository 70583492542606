import _ from "lodash";

export class Model {
    #init;

    static parse(obj) {
        let inst = _.merge(new this(), obj);
        if (inst.onParse != undefined) {
            inst.onParse();
        }
        inst.commit();
        return inst;
    }

    static init() {
        let inst = new this();
        inst.commit();
        return inst;
    }

    reset() {
        for (let key of Object.keys(this)) {
            this[key] = _.cloneDeep(this.#init[key]);
        }
    }

    commit() {
        this.#init = _.cloneDeep(this);
    }

    format() {
        let data = _.cloneDeep(this);
        if (data.onFormat != undefined) {
            data.onFormat();
        }
        return data;
    }
}

export class ListOf {
    #init = false;
    items = [];
    #model;

    constructor(model) {
        this.#model = model;
    }

    init(list) {
        let items = [];
        for (let item of list) {
            var mod = new this.#model();
            mod = _.merge(mod, item);
            items.push(mod);
        }
        this.items = items;
        this.#init = true;
    }

    add(item) {
        var mod = new this.#model();
        mod = _.merge(mod, item);
        this.items.push(mod);
    }

    remove(key, value) {
        for (let [index, item] of this.items.entries()) {
            if (item[key] === value) {
                this.items.splice(index, 1);
                break;
            }
        }
    }

    replace(key, value, newItem) {
        for (let [index, item] of this.items.entries()) {
            if (item[key] === value) {
                this.items[index] = newItem;
                break;
            }
        }
    }

    orderBy(key) {
        this.items = _.orderBy(this.items, key);
    }

    get isInit() {
        return this.#init;
    }
}
