<template>
    <div>
        
        <v-card tile flat outlined color="" key="publisher-main">

            <!-- images catalogue -->
            <Catalogue v-if="cl_img_urls"
                :cl_img_urls="cl_img_urls"
                :cl_index="cl_index"
                @eventCatalogueSelectedImages="eventCatalogueSelectedImages"
            />
            
            <Images 
                :catalogue_selected_images_urls="catalogue_selected_images_urls"
                @eventPublicationSelectedImages="eventPublicationSelectedImages"
            />


            <SplitView v-if="cluster" key="publisher-splitview" direction="horizontal" :style="{ height: '90vh', overflow: 'hidden' }">
                
                <!-- Chats -->
                <template #A>
                   <Chats :cluster="cluster" @eventSelectedChat="eventSelectedChat" />
                </template>

                <!-- Threads -->
                <template #B>
                    <Thread 
                        :posts="cluster.posts" 
                        :selected_chat_text="selected_chat_text" 
                        :selected_images_urls="publication_selected_images_urls"
                        @eventPublicationImages="eventPublicationImages"
                        @eventClosePublisherDialog="eventClosePublisherDialog"
                    />
                </template>
            </SplitView>
        </v-card>
    </div>
</template>

<script>
import Catalogue from './ImagesCatalogue.vue'
import Images from './ImagesPublication.vue'
import SplitView from 'vue-split-view/dist/vue2'
import Chats from './PublisherChats.vue'
import Thread from './PublisherThreads.vue'

export default {

    name: 'Publisher',
    
    props: ['deck_cluster','cl_index'],

    components: {
        Catalogue,
        Images,
        SplitView,
        Chats,
        Thread,
    },

    data: () => ({

        loading: false,
        error: null,

        cluster: null,
        cl_img_urls:[],

        // images
        catalogue_selected_images_urls: [],
        publication_selected_images_urls: [],

        // posts
        selected_posts_index:[0],
        selected_chat_text: "",

    }),

    created() {
    },

    watch: {
        deck_cluster: {
            deep: true,
            immediate: true,
            handler() {
                this.cluster = this.deck_cluster;
                this.cl_img_urls = this.buildClusterImgUrls();
            }
        }
    },

    methods: {

        eventCatalogueSelectedImages(selected_images) {
            this.catalogue_selected_images_urls = selected_images;
        },

        eventPublicationSelectedImages(selected_images) {
            this.publication_selected_images_urls = selected_images;
        },

        eventSelectedChat(chat) {
            this.selected_chat_text = chat;
        },

        eventPublicationImages(img_urls) {
            console.log("eventPublicationImages:")
            this.publication_selected_images_urls = img_urls;
            this.catalogue_selected_images_urls = img_urls;
            // push img url added manually
            for (let iu of img_urls) {
                if (!this.cl_img_urls.includes(iu)) {
                    this.cl_img_urls.push(iu);
                }
            }
        },

        eventClosePublisherDialog() {
            this.$emit("eventClosePublisherDialog");
            console.log("eventClosePublisherDialog tmain");
        },

        buildClusterImgUrls() {
            let cl_img_urls = [];
            if (this.cluster && this.cluster.posts.length > 0) {
                let regex = /<img[^>]*src="([^"]*)"/g;
                for (let p of this.cluster.posts) {
                    let match;
                    while ((match = regex.exec(p.content))) {
                        cl_img_urls.push(match[1]);
                    }
    
                    // from feeder
                    if (p.img_url  && p.img_url.length > 0) {
                        cl_img_urls.push(p.img_url)
                    }
    
                    // remove duplicates
                    cl_img_urls = [...new Set(cl_img_urls)];
                }
            }
            return cl_img_urls
        },

    },

    computed: {

        
    }
    
}
</script>

<style>

</style>