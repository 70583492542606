<template>
    <div>
        <v-sheet outlined color="lime">
            <v-card tile flat>
                <v-virtual-scroll
                    :items="cluster.posts"
                    :height="getItemsHeight"
                    :item-height="item_height"
                >
                    <template v-slot:default="{ item, index }">
                        <v-list-item>
                            <v-list-item-content>
                            
                                <v-list-item-title class="font-weight-bold">
                                    {{index + 1 }}/{{cluster.posts.length}} · {{item.source_name}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{noImageContentLength(item)}}
                                    · {{$app.retriveCountryNameFromIso(item.country.toLowerCase())}}
                                    · {{formatPostDate(item)}}
                                    
                                </v-list-item-subtitle>
                            
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-checkbox v-model="selection[index]" @change="switchSelection" color="lime"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-icon @click="goSingle(index)" class="ml-3" color="lime">mdi-alpha-s-circle</v-icon>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-icon @click="goArticle(index)" class="ml-3" color="lime">mdi-eye</v-icon>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-icon @click="copyPost(index)" class="" color="lime">mdi-content-copy</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card>
        </v-sheet>

        <!-- Dialog Single -->
        <v-dialog v-if="single" v-model="dialog" width="80%">
            <v-sheet rounded outlined color="lime">
                <v-card flat outlined class="" color="">
                
                    <v-card-actions>
                        
                        <v-btn small rounded @click="goArticle(single_index)" elevation="0"  class="font-weight-bold mr-1 px-2 pb-1" color="lime">
                            <country-flag :country='single.country' :rounded="true" class=""/><span class="Dialog--text pt-1 pl-1">{{single.source_name}}</span>
                        </v-btn>
                       
                        <v-card-subtitle class="">
                        {{$app.retriveCountryNameFromIso(single.country.toLowerCase())}}
                        · {{formatPostDate(single)}}
                        · {{single.id}}
                        </v-card-subtitle>

                        <v-spacer></v-spacer>
                        <v-icon @click="copyPost(single_index)" class="mt-1 mr-2" color="lime">mdi-content-copy</v-icon>
                        
                        <v-btn small text color="lime">{{single.content.length}} char.</v-btn>
                        
                        <TranslationWidget 
                            :post="single" 
                            stream="single"
                            class="mx-3"
                            @eventTranslation="eventTranslation" 
                        ></TranslationWidget>

                        <v-btn
                            color="lime"
                            text
                            icon
                            @click="dialog = false"
                        >
                            <v-icon>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-divider></v-divider>
                    
                    <!-- SINGLE WITH TRANSS-->
                    <SingleContentTransTabs 
                        v-if="single.translations && single.translations[0].content.length > 0" 
                        :single="single"
                        :display_title="true"
                        :no_image="true"
                        colorView="lime"
                        class="mt-5"
                    ></SingleContentTransTabs>
                    
                    <div v-else>

                        <!-- title -->
                        <v-card-title style="cursor: pointer"  class="wordbreak text-decoration-underline font-weight-bold py-1 pb-0 lime--text">
                            {{single.title}}
                        </v-card-title>
    
                        <!-- no image content -->
                        <v-card-text class="text-body-1 pTagText--text mt-5"><span v-html="single.content" ></span></v-card-text>
                        
                    </div>
                </v-card>
            </v-sheet>
        </v-dialog>


        <!-- snack bar: Post text copied -->
        <v-snackbar centered v-model="snackbar_copied" timeout="800" color="success">
            <span class="font-weight-bold Dialog--text">Post Text Copied</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    small
                    class="Dialog--text"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import moment from "moment";
import TranslationWidget from '../../Translations/TranslationWidget.vue'
import SingleContentTransTabs from '../../Profiles/components/SingleContentTransTab.vue'

export default {

    name: 'Posts',

    components: {
        TranslationWidget,
        SingleContentTransTabs,
    },

    props:['cluster'],

    data: () => ({

        dialog: false,
        single: null,
        single_index: null,

        // checkboxes
        selection: [],

        // snackbar
        snackbar_copied: false,

        item_height: 60,
    }),

    created() {
        // creation an array of boolean at the right length for selection
        for (let i=0; i < this.cluster.posts.length; i++) {
            if (i==0) {
                this.selection.push(true);
            } else {
                this.selection.push(false);
            }
        }

        this.eventSelectionToNoHtmlPosts();
    },


    methods: {
      
        noImageContent(post) {
            return post.content.replace(/<img\b[^>]*>/gi, "")
        },

        noImageContentLength(post) {
            return post.content.replace(/<img\b[^>]*>/gi, "").length
        },

        formatPostDate(post) {
            return moment(post.date).format("DD/MM/YY")
        },

        switchSelection() {
            // The v-model on the v-checkbox is reactive and automatically 
            // updates the value when the checkbox is checked or unchecked. 
            // This means manually calling switchSelection should be unnecessary.
            this.eventSelectionToNoHtmlPosts()
        },

        goSingle(index) {
            this.dialog = true;
            this.single = this.cluster.posts[index]
            this.single_index = index;
        },

        goArticle(index) {
            window.open(this.cluster.posts[index].url, '_blank');
        },

        eventSelectionToNoHtmlPosts() {
            let posts =[]
            for (let [index,b] of this.selection.entries()) {
                if (b) {
                    if (this.cluster.posts[index]) {
                        posts.push( 
                            {
                                id: this.cluster.posts[index].id,
                                title: this.cluster.posts[index].title,
                                description: this.cluster.posts[index].description,
                                content: this.cluster.posts[index].content.replace(/<[^>]*>/gi, ''),
                            }
                        )
                    }
                }
                        
            }
            
            this.$emit("eventPostsSelection", posts)
        },

        // copyPostText for GPT4
        copyPost(index) {
            let postContentNoHtml = this.cluster.posts[index].content.replace(/<[^>]*>/gi, '');
            let text = this.cluster.posts[index].title + " " + this.cluster.posts[index].description + " " + postContentNoHtml;
            this.copyText(text);
            this.snackbar_copied = true
        },

        // copy 
        copyText(text) {
            if (text.length > 0) {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(text)
                        .then(() => {
                            console.log('Text copied to clipboard');
                            this.snackbar_copied = true;  // Assuming this sets a snackbar notification to true
                        })
                        .catch(err => {
                            console.error('Failed to copy text: ', err);
                        });
                } else {
                    console.warn('Clipboard API is not supported in this browser');
                }
            }
        },


        // translations
        eventTranslation(output_language, content, description, title){
            console.log("eventTranslation", this.single_index, output_language, content.length, description.length,title)
            if (this.cluster.posts[this.single_index].translations.length > 0) {
                for (let tr of this.cluster.posts[this.single_index].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title =title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

    },

    computed: {
        
        buildPosts() {
            return this.cluster.posts.slice(0, this.max_posts_count-1)
        },

        getItemsHeight() {
            if (this.cluster.posts.length < 3 ) {
                return this.item_height * this.cluster.posts.length
            } else {
                return this.item_height * 3
            }
        }

    }

}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>