<template>
    <div>
        
        <v-card v-if="stream == 'post'" flat class="d-flex flex-column align-center justify-start pb-2" width="65" color="transparent">
            <!-- vote buttons -->
            <v-icon v-if="tab !='subscriber' && tab !='searcher'" @click="vote(1)" class="mb-2" color="success">mdi-check-circle</v-icon>
            <v-icon v-if="tab !='crimorg' && tab !='searcher' && tab !='law'" @click="vote(0)"  class="" color="warning">mdi-delete-circle</v-icon>
            
            <!-- vote buttons searcher -->
            <v-icon v-if="tab =='searcher' && post.true_class == 0" @click="vote(1)" class="mb-2" color="success">mdi-check-circle</v-icon>
            <v-icon v-if="tab =='searcher' && post.true_class == 1" @click="vote(0)"  class="" color="warning">mdi-delete-circle</v-icon>
        
            <span class="ml-1 mt-2 font-weight-bold text-caption accent--text">{{softmaxPercentage}}</span>
            
            <!-- error msg -->
            <span v-if="error" class="text-caption error--text">{{ error }}</span>
        </v-card>

        
        <v-card v-if="stream == 'single'"  flat class="" color="transparent">
            <v-card-actions>
                <!-- vote buttons -->
                <v-icon v-if="tab !='subscriber' && tab !='searcher'" @click="vote(1)" class="" color="success">mdi-check-circle</v-icon>
                <v-icon v-if="tab !='crimorg' && tab !='searcher' && tab !='law'" @click="vote(0)"  class="" color="warning">mdi-delete-circle</v-icon>
            
                <!-- vote buttons searcher -->
                <v-icon v-if="tab =='searcher' && post.true_class == 0" @click="vote(1)" class="" color="success">mdi-check-circle</v-icon>
                <v-icon v-if="tab =='searcher' && post.true_class == 1" @click="vote(0)"  class="" color="warning">mdi-delete-circle</v-icon>
                
                <!-- softmax -->
                <span class="ml-1 text-caption accent--text">{{softmaxPercentage}}</span>
                <v-spacer></v-spacer>
                
                <!-- error msg -->
                <span v-if="error" class="ml-5 mt-2 error--text">{{ error }}</span>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        

    </div>
</template>

<script>
export default {

    name: 'VoteWidget',

    props:['post','index','stream','tab'],

    created() {
    },

    data: () => ({
        loading: false,
        error: null,
        buttonWidth: 55,
    }),

    watch: {
        post: {
            deep: true,
            immediate: true,
            handler() {},
        }
    },

    methods: {
        vote: async function(cl) {
            this.loading = true
            this.$emit('eventVote', cl, this.index, this.stream);
            try {
                await this.$api.get("/profile/trainer/vote/" + this.post.id +  "?class=" + cl + "&lang=" + this.post.language);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },
    },
    
    computed: {
        softmaxPercentage() {
            if (this.post.softmax >= 0) {
                return Math.round(this.post.softmax * 100) + "%";
            } else {
                return "no clfr"
            }
        },
    }
}
</script>

<style>
</style>