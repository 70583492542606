<template>
    <div>
        <v-card outlined>
            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>
            
            <v-card-title>EDIT POST</v-card-title>
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
            
            <v-card-text>
                <!-- POST ID -->
                <v-text-field
                    v-model="search"
                    label="SEARCH TERMS (AND)"
                    required
                ></v-text-field> 

            </v-card-text>

            <v-card-actions>
                <v-btn @click="fetchPost" class="accent--text">GET POST</v-btn>
            </v-card-actions>
        </v-card>

         <v-sheet 
            outlined rounded 
            v-for="(post, index) in posts" :key="'post_'+post.id" 
            class="mb-2 mx-3" :color="standbyColor">
                <v-card flat class="d-flex">
                    <Post 
                        :post="post" :index="index" 
                        :key='"p" + post.id'
                    ></Post>
                </v-card>
            </v-sheet>
    </div>
</template>

<script>
import Post from '../Profiles/components/Post'

export default {

    name: 'EditPost',

    data: ()=> ({
        loading: false,
        error: null,
        search: null,
        posts: [],
    }),

    components: {
        Post,
    },

    methods: {
        fetchPost: async function () {
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.post("/editpost", { words: this.search});
                this.posts = res.data
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },
    },

}
</script>

<style>

</style>