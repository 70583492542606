<template>
    <div>
        <v-card flat outlined>

            <v-card-text class="error--text">ALERT EXISTS ALREADY</v-card-text>

            <v-card-text> {{ stream_dict.t1 }}: {{ stream.name }} - {{ stream_dict.k1 }}: {{ stream.gen }}</v-card-text>

            <v-card-text>
                <!-- value 1 -->
                <v-text-field v-model.number="event.n1" :label="stream_dict.n1"></v-text-field>
                <!-- Event date 1-->
                <v-text-field v-model="event.d1" :label="stream_dict.d1"></v-text-field>
                <!-- Location ISO3 -->
                    
            </v-card-text>

            <v-card-text>
                Location iso3: {{ event.location_iso3 }}
                <country-flag v-if="event.location_iso3.length > 0" :country='event.location_iso3' :rounded="true" class=""/>
            </v-card-text>

            <v-divider></v-divider>

            Slot event {{ event }}
            <br>
            stream_dict : {{ stream_dict }}
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'SlotEvent',

    props:['event', 'stream', 'stream_dict', 'color']



}
</script>

<style>

</style>