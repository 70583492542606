export default {

   // min tab
   tab: 0,

   setTab(value) {
      this.tab = value;
   },

   retriveTab() {
      return this.tab;
   },

   //* BASIC
   basic: {
      language: "all",
      country: "all",
      dialog: false,
      single: null,
      index: 0,
      scroll: 0,
      page: 1,
  },


   setBasicLanguage(lang) {
      this.basic.language = lang;
   },

   setBasicCountry(country) {
      this.basic.country = country;
   },

   setBasicDialog(bool) {
      this.basic.dialog = bool;
   },

   setBasicSingle(obj) {
      this.basic.single = obj;
   },
   
   setBasicIndex(i) {
      this.basic.index = i;
   },

   setBasicScroll(val) {
      this.basic.scroll = val;
   },

   retriveBasicLanguage() {
      return this.basic.language;
   },

   retriveBasicCountry() {
      return this.basic.country;
   },

   retriveBasicSingle() {
      return this.basic.single;
   },

   retriveBasicIndex() {
      return this.basic.index;
   },

   retriveBasicScroll() {
      return this.basic.scroll;
   },

   retriveBasicDialog() {
      return this.basic.dialog;
   },

   //* BASIC
   advanced: {
      language: "all",
      country: "all",
      dialog: false,
      single: null,
      index: 0,
      scroll: 0,
      page: 1,
  },


   setAdvancedLanguage(lang) {
      this.advanced.language = lang;
   },

   setAdvancedCountry(country) {
      this.advanced.country = country;
   },

   setAdvancedDialog(bool) {
      this.advanced.dialog = bool;
   },

   setAdvancedSingle(obj) {
      this.advanced.single = obj;
   },
   
   setAdvancedIndex(i) {
      this.advanced.index = i;
   },

   setAdvancedScroll(val) {
      this.advanced.scroll = val;
   },

   retriveAdvancedLanguage() {
      return this.advanced.language;
   },

   retriveAdvancedCountry() {
      return this.advanced.country;
   },

   retriveAdvancedSingle() {
      return this.advanced.single;
   },

   retriveAdvancedIndex() {
      return this.advanced.index;
   },

   retriveAdvancedScroll() {
      return this.advanced.scroll;
   },

   retriveAdvancedDialog() {
      return this.advanced.dialog;
   },
}
