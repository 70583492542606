<template>
    <div>
        <v-tabs v-model="tab" centered background-color="transparent" key="tags-main-tabs">
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab v-for="(cat,index) of categories" class="font-weight-bold " @click="selectTab(index)" :key="index">
                {{cat.name}} [{{tagsByCategory[cat.channel].length}}]
            </v-tab>
        </v-tabs>
        
        <v-card flat outlined key="tags-main-tabs-items">
            <v-tabs-items v-model="tab" class="tab-items">
                <v-tab-item v-for="cat of categories" :key="cat.name">
                    <Tags :tags="tagsByCategory[cat.channel]" :channel="cat.channel" :name="cat.name"/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import tags from "@/common/tags";
import Tags from './TagsTab'
import { mapActions } from 'vuex';

export default {

    name: 'TagsMain',

    components: {
        Tags,
    },

    data: () => ({
        tab: null,
        categories : [
            { name: "organizations", channel: "crimorg" },
            { name: "means", channel: "mean"},
            { name: "markets", channel: "market" },
            { name: "Law Agencies",  channel: "law" },
            { name: "countries", channel: "country" },
        ],
        tagsByCategory: {
            mean: [],
            crimorg: [],
            market: [],
            law: [],
            country: [],
        }
    }),

    created() {
        // init tab: /common/.js
        this.tab = tags.retriveTab();
        this.fetchTags().then(() => {window.scrollTo(0,tags.retriveScroll(window.scrollY));});
    },

    methods: {

        ...mapActions(['updateTagsByCategory','updateTags']),

        selectTab(value) {
            tags.setTab(value)
        },

        fetchTags: async function() {
            this.loading = true;
            this.error = null;
            this.tags = [];

            try {
                let res = await this.$api.get("/tags");
                this.tags = res.data;
                this.buildCategoriesTags(res.data)
                this.total = this.tags.length;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                // update store
                this.updateTags(this.tags);
                this.updateTagsByCategory(this.tagsByCategory);
                this.loading = false;
            }
        },

        buildCategoriesTags(tags) {
            for (let t of tags) {
                this.tagsByCategory[t.channel].push(t)
            }
        }
    },

    computed: {
        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return "active-items-dark"
            } else {
                return "active-items-light"
            }
        }
    }
}
</script>

<style>

</style>