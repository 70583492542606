<template>
    <div>
      
        <v-tabs v-model="tab" centered background-color="transparent" key="searcher-main-tabs">
        <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab @click="selectTab(0)" class="font-weight-bold">Advanced</v-tab>
            <v-tab @click="selectTab(1)" class="font-weight-bold">Basic</v-tab>
        </v-tabs>

         <v-card flat outlined key="searcher-main-tabs-items">
            <v-tabs-items v-model="tab" class="tab-items">
                <v-tab-item key="advanced-search" class="">
                    <Advanced/>
                </v-tab-item>
                <v-tab-item key="basic-search" class="">
                    <Basic/>
                </v-tab-item>
            </v-tabs-items>
        </v-card>

    </div>
  
</template>

<script>
import Basic from './SearcherBasicTab'
import Advanced from './SearcherAdvancedTab'
import searcher from "@/common/searcher";

export default {
    
    name: 'SearcherMain',

    data: () => ({
        tab: 0,
    }),

    components: {
        Basic,
        Advanced,
    },

    created() {
        // init tab: /common/.js
        this.tab = searcher.retriveTab();
    },


    methods: {
        
        selectTab(value) {
            searcher.setTab(value)
        },

    },

    computed: {
      
    }

}
</script>

<style>

</style>