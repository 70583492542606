<template>
    <div>
        <v-card v-if="pkg" flat outlined class="mb-2">
            <v-card-title v-if="isNew" class="justify-center accent--text text-h5 font-weight-bold">
                New Package
            </v-card-title>
            <v-card-title v-else class="justify-center accent--text text-h5 font-weight-bold">
                Package: {{ pkg.name }} | #{{ pkg.id }}
            </v-card-title>
        </v-card>

        <v-card flat outlined>
            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="accent"
                :active="loading">
            </v-progress-linear>

            <v-container v-if="pkg" fluid key="pkg-form">
                <v-row>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="pkg-form-left">
                            <v-card-text>
                                <!-- name -->
                                <v-text-field
                                rounded
                                outlined
                                dense
                                v-model="pkg.name"
                                :label="$i18n.t('name')"
                                :rules="[rules.required]"
                                hide-details=""
                                ></v-text-field>

                                <!-- Select Sets for Package -->
                                <v-select
                                v-model="sets"
                                :items="sets_list"
                                :item-text="item => item.country + ' - ' + item.language "
                                :item-value="item => item.id"
                                :menu-props="{ maxHeight: '400' }"
                                label="Sets disponibles"
                                multiple
                                ></v-select>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card flat color="transparent" key="pkg-form-right">
                            <v-card-text>
                                <!-- description -->
                                <v-text-field
                                rounded
                                outlined
                                dense
                                v-model="pkg.description"
                                :label="$i18n.t('description')"
                                :rules="[]"
                                hide-details=""
                                ></v-text-field>
                            </v-card-text>

                            <v-card-text><v-icon class="mr-2" color="warning">mdi-alert</v-icon>Don't forget to update user if changes, user.packages_countries needs to be rebuild !</v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-card flat color="transparent" key="set-form-actions">
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    color="success"
                    v-if="isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="create"
                    class="mr-2 mt-5"
                >
                    <v-icon left>mdi-plus</v-icon> Create
                </v-btn>
                <v-btn
                    small
                    color="success"
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    @click="save"
                    class="white--text mr-2 mt-5"
                >
                    <v-icon small>mdi-arrow-up-circle</v-icon>Save
                </v-btn>
                <v-btn
                    small
                    v-if="!isNew"
                    :loading="loading"
                    :disabled="loading"
                    color="error"
                    @click="remove"
                    class="mr-2 mt-5"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Remove
                </v-btn>
            </v-card-actions>

            
        </v-card>
    </div>
</template>

<script>
import { Model } from "@/common/model";

class Package extends Model {
    id = null;
    name = null;
    description = null;
    sets_payload = [];
}

export default {

    name: 'Package',

    data: () => ({
        pkg: null,
        sets: [],
        loading: false,
        isNew: false,
        snackbar: false,
        sets_list: null,
        rules: {
            required: (v) => !!v || "Field required",
        },

        // api error
        error: null,

    }),

    created() {
        this.fetchSets();
        let id = this.$route.params.id;
        if (id != "new") {
            this.fetch(id);
            return;
        }
        this.isNew = true;
        this.pkg = Package.init();
    },

    methods: {

        fetch: async function (id) {
            console.log("fetch package:", id);
            this.loading = true;
            this.error = null;
            try {
                let res = await this.$api.get("/package/" + id);
                this.pkg = res.data;
                if (res.data.sets) {
                    for (let s of res.data.sets) {
                        this.sets.push(s.id);
                    }
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        fetchSets: async function() {
            console.log("fetching sets")
            this.loading = true;
            try {
                let res = await this.$api.get("/packages_sets");
                let items = [];
                for (let item of res.data) {
                    items.push(item)
                }
                // sort countries alphabetically
                items.sort((a,b) => a.country.localeCompare(b.country));
                this.sets_list = items;
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* PACKAGE CRUD
        create: async function () {
            console.log("create");
            this.loading = true;
            try {
                await this.$api.post("/packages", this.addPayload(this.pkg).format());
                this.$router.go(-1);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        save: async function () {
            console.log("save");
            this.loading = true;
            try {
                var payload = this.addPayload(this.pkg);
                await this.$api.patch("/packages/" + this.pkg.id, payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.snackbar = true;
                this.loading = false;
            }
        },

        remove: async function () {
            if (!confirm('Remove package "' + this.pkg.name + '"?')) {
                return;
            }
            await this.$api.delete("/packages/" + this.pkg.id);
            this.$router.go(-1);
        },

        addPayload(pkg) {
            // sets payload: only id set
            if (this.sets.length > 0) {
                let sets_payload = [];
                for (let set of this.sets) {
                    sets_payload.push(set);
                }
                pkg.sets_payload = sets_payload;
            }
            return pkg
        },
    },

    
}
</script>

<style>

</style>