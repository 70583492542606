<template>
    <div>
        <v-tabs v-model="tab" centered background-color="transparent" key="ml-main-tabs">
            <v-tabs-slider color="accent"></v-tabs-slider>
            <v-tab @click="selectTab(0)" class="font-weight-bold">PAGER TAGS/LOOT</v-tab>
            <v-tab @click="selectTab(1)" class="font-weight-bold">CORPUS</v-tab>
            <v-tab @click="selectTab(2)" class="font-weight-bold">TAGS POST</v-tab>
            <v-tab @click="selectTab(3)" class="font-weight-bold">LOOT</v-tab>
            <v-tab @click="selectTab(4)" class="font-weight-bold">EDIT POST</v-tab>
        </v-tabs>

      
        <v-card key="ml-main-tabs-items" >
            <v-tabs-items v-model="tab" class="tab-items">
                <v-tab-item key="pager" class="">
                    <Pager />
                </v-tab-item>
                <v-tab-item key="corpus-post" class="">
                    <Corpus />
                </v-tab-item>
                    <v-tab-item key="tags-post" class="">
                    <TagsPost />
                </v-tab-item>
                <v-tab-item key="loot-post" class="">
                    <LootPost />
                </v-tab-item>
                <v-tab-item key="edit-post" class="">
                    <EditPost />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        
    </div>
</template>

<script>
import tooler from "@/common/tooler";
import EditPost from './EditPostTab'
import Corpus from './CorpusPostTab'
import TagsPost from './TagsPostTab'
import LootPost from './LootPostTab'
import Pager from './PagerTab'

export default {

    name: "ToolerMain",

    components: {
        Corpus,
        EditPost,
        TagsPost,
        LootPost,
        Pager,
    },

    data: () => ({
        tab: 4,
    }),

    created() {
        // init tab: /common/.js
        this.tab = tooler.retriveTab();
    },


    methods: {
        selectTab(value) {
            tooler.setTab(value)
        },
    }
}
</script>

<style>
.tab-items {
    background-color: transparent !important;
}
</style>