

// 👇️ default export
export default {

    roles: {
        admin: { name: "admin", tokenCode: "jjmaster", level: 10 },
        trainer_subscriber: { name: "trainer_subscriber", tokenCode: "trainer_subscriber", level: 6 },
        trainer: { name: "trainer", tokenCode: "trainer", level: 5 },
        subscriber: { name: "subscriber", tokenCode: "subscriber", level: 3 },
    },

    retrive() {
        return this.roles
    },
}