<template>
    <div>
        <Header 
            :languageMain="language"
            @eventLanguage="eventLanguage" 
            :countryMain="country"
            @eventCountry="eventCountry"
            color="accent"
            stream="advanced_search"
            class="mb-2"
        />

        <SearchHeader 
            :total="total"
            :page="page"
            @eventLaunchSearch="eventLaunchSearch" 
            @eventResetSearch="eventResetSearch"
            @eventResetPage="eventResetPage"
        />

        <!-- loading -->
        <v-progress-linear
            indeterminate
            color="accent"
            :active="loading">
        </v-progress-linear>

        <!-- error msg -->
        <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>
        
        <!-- no post msg -->
        <v-card v-if="error == 'empty'" flat color="transparent">
            <v-card-text class="text-center">{{$i18n.t("noMorePost")}}</v-card-text>
        </v-card>

        <!-- Pagination -->
        <v-card v-if="total > options.itemsPerPage" class="mx-3" outlined flat>
            <v-card-actions>
                <v-spacer></v-spacer>
                <span>{{ total }} posts</span>
                <v-spacer></v-spacer>
                <v-pagination v-model="page" @input="inputPage" :length="paginationLength" total-visible="10"
                    color="accent darken-3" circle>
                </v-pagination>
                <v-spacer></v-spacer>
                <v-card flat max-width="150" class="py-0">
                    <v-card-text>
                        <v-text-field
                            @click:append-outer="goPage"
                            dense
                            v-model.number="number_page"
                            type="number"
                            outlined
                            label="Page"
                            hide-details=""
                            append-outer-icon="mdi-eye"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-card-actions>
        </v-card>
       
        <v-sheet 
            outlined rounded 
            v-for="(post, index) in posts" :key="'post_'+post.id" 
            class="my-2 mx-3" :color="postColor(post)"
        >
            <v-card flat class="d-flex">
                <!-- Widgets -->
                <v-card class="mt-5" color="transparent">
                    <PublisherWidget
                        v-if="post.source_ref != 'FR046'"
                        :post="post"
                        @eventGoFramer="eventGoFramer"
                    ></PublisherWidget>
                    <VoteWidget v-if="isUserProfileTrainer"
                        @eventVote="eventVote"
                        :post="post" 
                        :index="index" 
                        stream="post" 
                        tab="searcher"
                    ></VoteWidget>
                </v-card>

                <!-- Post -->
                <Post 
                    :post="post" 
                    :index="index" 
                    :isUserProfileTrainer="isUserProfileTrainer"
                    tab="search"
                    @eventGoSingle="eventOpenSingleDialog"
                    @eventGoTags="eventGoTags"
                    @eventGoLoot="eventGoLoot"
                    :key='"p" + post.id'
                ></Post>
            </v-card>
        </v-sheet>
      
        <!-- Pagination -->
        <div v-if="total > options.itemsPerPage" class="text-center my-3">
            <v-pagination
            v-model="page"
            @input="inputPage"
            :length="paginationLength"
            total-visible="7"
            color="accent darken-3"
            circle>
            </v-pagination> 
        </div>

        
        <!-- DIALOG SINGLE -->
        <v-dialog 
            v-model="dialogSingle"
            fullscreen
            key="advanced-searcher-dialog-single"
        >  
            <!-- v-card needed for triggering fullscreen-->    
            <v-card>  
                <Single v-if="dialogSingle"
                    :single="dialogSinglePost" 
                    :index="dialogSingleIndex"
                    :isUserProfileTrainer="isUserProfileTrainer"
                    colorView="accent"
                    @eventCloseSingleDialog="eventCloseSingleDialog"
                    @eventTranslation="eventTranslation"
                    @eventGoTags="eventGoTags"
                    @eventGoLoot="eventGoLoot"
                    @eventVote="eventVote"
                    tab="searcher"
                ></Single>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import searcher from "@/common/searcher";
import SearchHeader from './SearchHeader'
import Post from './SearcherPost'
import Single from './SearcherDialogSingle'
import VoteWidget from '../../Vote/VoteWidget.vue'
import PublisherWidget from '../../Publisher/PublisherPostWidget'
import Header from '../components/HeaderLangCountry'

import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Advanced',

    components: {
        Header,
        SearchHeader,
        Post,
        Single,
        VoteWidget,
        PublisherWidget,
    },

    data: () => ({
        
        loading: false,
        posts: [],
        total: null,
        error: null,
        switchCO: true,
        search: null,

        // header
        language: null,
        country: null,

        // dialog single
        dialogSingle: false,
        dialogSinglePost: null,
        dialogSingleIndex: null,

        //trainer widget
        isUserProfileTrainer: false,

        //* PAGING
        page: 1,
        number_page: 1,
        paginationLength: null,
        options: {
            itemsPerPage: 25,
        },
    }),

    created() {

        // init when back from single
        this.language = searcher.retriveAdvancedLanguage();
        this.country = searcher.retriveAdvancedCountry();
        this.dialogSingle = searcher.retriveAdvancedDialog();
        this.dialogSingleIndex = searcher.retriveAdvancedIndex();
        this.dialogSinglePost = searcher.retriveAdvancedSingle()
        this.scrolling = searcher.retriveAdvancedScroll();

        // trainer widget : fetch full posts meta
        if (this.$rbac.get_user_role() == "admin" || this.$rbac.get_user_role().includes("trainer")) {
            this.isUserProfileTrainer = true;
        } 
        if (this.search.search || ( this.search.tags.length > 0 && this.search.tags) ) {
            this.launchAdvancedSearch()
            .then(() => { 
                this.dialogSinglePost = this.posts[this.dialogSingleIndex]
            })
            .then( () => {
                if (!this.dialogSingle) {
                    window.scrollTo(0, this.scrolling);
                }
            });
        }


    },

    watch: {
        searchStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.searchStore) {
                    
                    // init when back from single
                    this.language = searcher.retriveAdvancedLanguage();
                    this.country = searcher.retriveAdvancedCountry();
                    
                    this.search = this.searchStore;
                    this.page = this.searchStore.page;
                    this.switchCO = this.searchStore.only_co;
                    this.posts = this.searchStore.posts;
                    this.total = this.searchStore.total;
                    this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);

                    if (this.search.search) {
                        this.launchAdvancedSearch();
                    }
                }
            }
        },

    },

    methods: {

        ...mapActions(['updateAdvancedSearch','resetAdvancedSearch']),

        launchAdvancedSearch: async function() {

            console.log("Launch Advanced search")

            this.loading = true;
            this.error = null;
            this.total = null;
            this.paginationLength = null;
            this.posts = [];
            this.search.tags_ref_list = [];
            if (this.search.tags && this.search.tags.length > 0 ) {
                for (let t of this.search.tags) {
                    this.search.tags_ref_list.push(t.ref);
                }
            }
                console.log(this.country)
            
            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.page - 1) +
                this.languageApiFilter() + 
                this.countryApiFilter() 
            );

            try {
                let res = await this.$api.post("/profile/searcher/advanced" + filter, this.search);
                this.posts = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error =  data.message;
            } finally {

                // update store
                this.search.page = this.page
                this.search.posts = this.posts;
                this.search.total = this.total;
                this.updateAdvancedSearch(this.search);
                this.loading = false
            }

        },

        //* HEADER

        // Header Events
        eventLanguage(lg) {
            searcher.setAdvancedLanguage(lg);
            this.language = lg;
            this.posts = [];
        },

        eventCountry(country) {
            searcher.setAdvancedCountry(country);
            this.country = country;
            this.posts = [];
        },

        eventReload() {
            this.posts = [];
            this.launchAdvancedSearch();
        },

        // filters
        countryApiFilter() {
            if (this.country != "all") {
                return "&country=" + this.country
            }
            return  ""
        },

        languageApiFilter() {
            if (this.language != "all") {
                return "&language=" + this.language
            } 
            return ""
        },

        //* SEARCH
        eventResetPage() {
            console.log("event reset page")
            this.page = 1
            this.launchAdvancedSearch();
        },

        eventLaunchSearch(search) {
            this.search = search;
            this.launchAdvancedSearch();
        },
        
        eventResetSearch() {
            console.log("eventResetSearch")
            this.posts = [];
            this.page = 1;
            this.resetAdvancedSearch();
        },

        //* Events

        // Vote
        eventVote(cl, index, stream) {
            console.log(cl, index, this.posts[index].id);
            if (cl == 0) {
                this.dialogSingle = false;
                if (this.switchCO) {
                    this.posts.splice(index,1);
                } else {
                    this.posts[index].true_class = 0;
                }
            } else {
                this.posts[index].true_class = 1;
            }

            if (stream == 'single') {
                this.eventCloseSingleDialog();
            }
        },

        //* Translations
        eventTranslation(index, output_language,content,description,title) {
            console.log("eventTranslation", index, output_language, content.length, description.length,title)
            if (this.posts[index].translations.length > 0) {
                for (let tr of this.posts[index].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title =title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        // Go tools Tags
        eventGoTags(index) {
            console.log("goTags")
            searcher.setAdvancedScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_tags_tab/" + this.posts[index].id})
        },

       // Go tools Loot
        eventGoLoot(index) {
            searcher.setAdvancedScroll(window.scrollY);
            this.$router.push({path: "/main/tools_post_loot_tab/" + this.posts[index].id})
        },

        //* FRAMER
        eventGoFramer(id) {
            console.log("eventGoFramer",id)
            searcher.setAdvancedScroll(window.scrollY);
            this.$router.push({path: "/main/framer/" + id})
        },

        //* SINGLE
        eventOpenSingleDialog(index) {
            searcher.setAdvancedDialog(true);
            searcher.setAdvancedSingle(this.posts[index]);
            searcher.setAdvancedIndex(index);
            searcher.setAdvancedScroll(window.scrollY);
            this.scrolling = window.scrollY;
            this.dialogSingle = true;
            this.dialogSinglePost = this.posts[index]
            this.dialogSingleIndex = index;
        },

        eventCloseSingleDialog(){
            searcher.setAdvancedDialog(false);
            searcher.setAdvancedSingle(null);
            searcher.setAdvancedIndex(0);
            window.scrollTo(0,this.scrolling);
            this.dialogSingle = false;
            this.dialogSingleIndex = 0;
            this.dialogSinglePost = null;
        },


        //* NAVIGATION
        inputPage(pg) {
            this.page = pg;
            this.launchAdvancedSearch();
        },

        goPage() {
            console.log("go page", this.number_page)
            this.posts = [];
            this.inputPage(this.number_page)
        },


        //* STYLING
        postColor(post) {
            let c=""
            if (post.true_class == 1) {
                c = "accent"
            }
            return c
        }
    },

    computed: {
        ...mapGetters({
            searchStore: 'getAdvancedSearch',
        }),
    }

}
</script>

<style>

</style>