<template>
    <div>
       
        <v-card class="ma-1" outlined flat>
            
            <!-- loading -->
            <v-progress-linear 
                indeterminate 
                :color="color"
                :active="loading"
            ></v-progress-linear>
            
            <v-card-actions>

                <!-- Search -->
                <v-text-field
                    dense
                    v-model="search"
                    @keydown.enter.prevent="fetchPublications"
                    outlined
                    hide-details
                    clearable
                    @click:clear="resetPublicationsSearch"
                    class=""
                    :color="color"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-spacer><v-btn small fab @click="fetchPublications"><v-icon :color="color">mdi-reload</v-icon></v-btn></v-spacer>
                <!-- Pagination -->
                <v-pagination v-if="total > options.itemsPerPage"
                    v-model="page" 
                    @input="inputPage" 
                    :length="paginationLength" 
                    total-visible="6"
                    :color="color" circle>
                </v-pagination>
                <v-spacer></v-spacer>
                <!-- total -->
                <span v-if="total > options.itemsPerPage">{{ total }} publications</span>
                <v-spacer></v-spacer>
                <v-card v-if="total > options.itemsPerPage" flat max-width="150" class="py-0">
                    <v-card-text>
                        <v-text-field
                            @click:append-outer="goPage"
                            dense
                            v-model.number="number_page"
                            type="number"
                            outlined
                            label="Page"
                            hide-details=""
                            append-outer-icon="mdi-eye"
                            :color="color"
                        ></v-text-field>
                    </v-card-text>
                </v-card>
            </v-card-actions>
        </v-card>

        <v-sheet outlined rounded v-for="(publication, index) in publications" :key="'pub_' + publication.id" class="my-2 mx-3" :color="colorStatus(publication)">
            <Publication 
                :publication="publication"
                :index="index"
                :sheet_color="colorStatus(publication)"
                @eventDeletePublication="eventDeletePublication"
                @eventDeleteTarget="eventDeleteTarget"
            />
        </v-sheet>

        <!-- error msg -->
        <span v-if="error" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

        <!-- Pagination -->
        <div v-if="total > options.itemsPerPage" class="text-center my-3">
            <v-pagination
            v-model="page"
            @input="inputPage"
            :length="paginationLength"
            total-visible="7"
            :color="color"
            circle>
            </v-pagination> 
        </div>

        <!-- snack bar -->
        <v-snackbar centered v-model="snackbar" timeout="500" color="success">
        <span class="font-weight-bold Dialog--text">Saved !</span>
        <template v-slot:action="{ attrs }">
            <v-btn
            small
            class="Dialog--text"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
                Done !
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import Publication from './PublicationPost.vue'

import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Publications',

    components: {
        Publication,
    },

    data: () => ({
        loading: false,
        error: null,
        key: 0,
        snackbar: false,

        publications: [],
        search: "",

        //* PAGING
        total: null,
        page: 1,
        number_page: 1,
        paginationLength: null,
        options: {
            itemsPerPage: 25,
        },

        color:"red accent-1",
        scrolling: null,
    }),

    created() {
        this.fetchPublications();
        //this.startReloadTimer();
    },

    watch: {
        searchStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.searchStore) {
                    this.search = this.searchStore.search;
                    this.page = this.searchStore.page;
                    this.publications = this.searchStore.publications;
                    this.total =this.searchStore.total;
                    this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
                }
            }
        },
    },

    methods: {

        ...mapActions(['updatePublicationsSearch','resetPublicationsSearch']),

        fetchPublications: async function() {

            console.log("fetch publications")

            let query = {
                search: this.search,
                page: this.page, // needed for the store not the api
            }

            // modify query
            
            this.loading = true;
            this.error = null;
            this.total = null,
            this.paginationLength= null,
            this.publications = [];

            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.page - 1)
            );

            let payload = query;

            try {
                let res = await this.$api.post("/publications" + filter, payload);
                this.publications = res.data
                this.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error =  data.message;
            } finally {
                
                // update store
                query.publications = this.publications;
                query.total = this.total;
                this.updatePublicationsSearch(query);
                this.loading = false
            }

        },

        //* Reload timer
        startReloadTimer() {
            setInterval(
            () => {
                this.scrolling = window.scrollY;
                this.fetchPublications();
            },
            1 * 60 * 1000); // 1 minute in milliseconds
        },

        // color sheet
        colorStatus(p) {
            let successArr = p.success === "" ? [] : p.success.split(",");
            let targetsArr = p.targets === "" ? [] : p.targets.split(",");
            let d = targetsArr.length - successArr.length
            let c = ""
            switch (p.status) {
                case "published":
                    return "blue"
        
                case "scheduled":
                    return "green lighten-2"
                case "draft":
                   return "grey"
                   
                case "error":
                    switch (d) {
                        case 1:
                            return "warning"
                        case 2: 
                            return "warning darken-4"
                    }
                    return "error"
                  
                case "purged":
                    return "blue-grey"
                case "purge error":
                    return "purple accent-1"
            }
            return c
        },


        //* NAVIGATION
        inputPage(pg) {
            this.page = pg;
            this.fetchPublications();
        },

        goPage() {
            console.log("go page", this.number_page)
            this.posts = [];
            this.inputPage(this.number_page)
        },

        // Events

        eventDeletePublication(index) {
            console.log("delete publication event:", index)
            this.deletePublication(index)
        },

        eventDeleteTarget(index, target) {
            console.log("deleting target", target, "- index=", index)
            this.deleteTarget(index, target);
        },


        // CRUD
        deletePublication: async function(index) {
            console.log("deleting publication:", this.publications[index].post_id)

            if (!confirm("Remove Publication and associated targets  \"" + this.publications[index].post_id + "\"?")) {
                return;
            }

            this.loading =true;
            this.error = null;

            try {
                await this.$api.delete("/publisher/publication/" + this.publications[index].post_id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.snackbar = true;
                }
                this.fetchPublications();
            }
        },



        deleteTarget: async function(index, target) {
            let payload = {
                "post_id": this.publications[index].post_id,
                "target": target,
            };

            //console.log("payload=", payload);

            if (!confirm("Remove Publication target \"" + target + "\"?")) {
                return;
            }

            this.loading =true;
            this.error = null;

            try {
                await this.$api.post("/publications/delete/target", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.snackbar = true;
                }
                // wait for the pubication to update in es
                setTimeout(() => {
                    this.fetchPublications();
                }, 1000);
                
            }

        },


            
    },


    computed: {
        ...mapGetters({
            searchStore: 'getPublicationsSearch',
        }),

       
    }
}
</script>

<style>

</style>